import { parse, toSeconds } from 'iso8601-duration';
import { LudoEvents } from '@nrk/ludo-core';
import { ScrubberContext } from '../index';
import { IndexPoint } from '../../../../nrk/mediaItem/MediaItem';

export default ({ player, progressBarElement }: ScrubberContext) => {
  const indexPointsContainerElement = progressBarElement.querySelector('.ludo-scrubber__index-points-container')!;

  function createIndexPointElement(indexPoint: IndexPoint, duration: number) {
    const indexElement = document.createElement('div');
    indexElement.className = 'ludo-progress-bar__index-point';

    const seconds = toSeconds(parse(indexPoint.startPoint));

    indexElement.style.left = `${seconds / duration * 100}%`;
    indexElement.setAttribute('title', indexPoint.title);

    indexElement.addEventListener('click', (e) => {
      e.stopPropagation();
      player.seekTo(seconds);
    });

    return indexElement;
  }

  function removeIndexPoints() {
    const parent = indexPointsContainerElement;
    const points = parent.querySelectorAll<HTMLElement>('.ludo-progress-bar__index-point');
    [].forEach.call(points, (point: HTMLElement) => parent.removeChild(point));
  }

  function plotIndexPoints() {
    const current = player.current();
    const indexPoints = current && 'indexPoints' in current && current.indexPoints || [];
    const duration = player.duration();

    indexPoints.forEach((indexPoint) => {
      const element = createIndexPointElement(indexPoint, duration);

      indexPointsContainerElement.appendChild(element);
    });
  }

  player.on(LudoEvents.LOADED, () => {
    removeIndexPoints();

    player.off(LudoEvents.PLAYBACKSTARTED, plotIndexPoints);
    player.once(LudoEvents.PLAYBACKSTARTED, plotIndexPoints);
  });

  player.on(LudoEvents.ITEM_CHANGED, removeIndexPoints);

};
