import { APIClient, Params, ResponseProcessor } from './types';
import { getLocationProtocol, buildURL } from './utils';
import { UnexpectedStatusError, isResponseError } from './errors';
import { AppInsightsTracker, Method } from '../ApplicationInsightsTracker';

interface Options {
  protocol?: 'http:' | 'https:';
  params?: Params;
  headers?: Params;
  maxAttempts?: number;
  retryDelay?: number;
  fetchOverride?: typeof fetch;
}

interface Attempt<T> {
  data?: T;
  retry?: boolean;
  error?: Error;
}

const DEFAULT_MAX_ATTEMPTS = 3;
const DEFAULT_RETRY_DELAY = 1000;
const TRACK_SUCCESS = false;

export class RetryClient implements APIClient {
  private tracker: AppInsightsTracker;
  private protocol: string;
  private domain: string;
  private maxAttempts: number;
  private retryDelay: number;
  private defaultParams: Params;
  private defaultHeaders: Params;
  private fetchOverride?: typeof fetch;

  constructor(domain: string, tracker: AppInsightsTracker, options: Options = {}) {
    this.tracker = tracker;
    this.domain = domain;
    this.protocol = options.protocol || getLocationProtocol();
    this.defaultParams = options.params || {};
    this.defaultHeaders = options.headers || {};
    this.maxAttempts = options.maxAttempts || DEFAULT_MAX_ATTEMPTS;
    this.retryDelay = options.retryDelay || DEFAULT_RETRY_DELAY;
    this.fetchOverride = options.fetchOverride;
  }

  fetch<T = unknown>(path: string, params: Params, responseProcessor: ResponseProcessor<T>): Promise<T> {
    const url = buildURL(this.protocol, this.domain, path, { ...this.defaultParams, ...params });
    const initialStartTime = Date.now();

    return this.retryRunner<T>(url, responseProcessor, 1, initialStartTime)
      .then((attempt) => {
        if (attempt.error) {
          return Promise.reject(attempt.error);
        }
        return Promise.resolve(attempt.data!);
      });
  }

  private retryRunner<T>(url: string, responseProcessor: ResponseProcessor<T>, attemptNumber: number,
                         initialStartTime: number): Promise<Attempt<T>> {

    return this.makeAttempt<T>(url, responseProcessor, attemptNumber, initialStartTime)
      .then((attempt) => {
        if (attempt.retry) {
          return new Promise<Attempt<T>>((resolve, reject) => {
            setTimeout(() => {
              this.retryRunner<T>(url, responseProcessor, attemptNumber + 1, initialStartTime)
                .then(resolve)
                .catch(reject);
            }, this.retryDelay);
          });
        }

        return attempt;
      });
  }

  private makeAttempt<T>(url: string, responseProcessor: ResponseProcessor<T>, attemptNumber: number,
                         initialStartTime: number): Promise<Attempt<T>> {

    const startTime = Date.now();
    let duration: number;

    return this.fetchWithCORS(url, this.defaultHeaders)
      .then((response) => {
        duration = Date.now() - startTime;

        if (response.status >= 500) {
          throw new UnexpectedStatusError(response, { retry: true });
        }

        return responseProcessor(response)
          .then((data) => {
            // Track successful attempt.
            const totalDuration = Date.now() - initialStartTime;
            this.trackAttempt(url, duration, true, response.status, attemptNumber, true, totalDuration,
              response.url && response.url !== url ? response.url : undefined);

            return { data };
          });
      })
      .catch((error) => {
        // Track failed attempt.
        if (typeof duration !== 'number') {
          duration = Date.now() - startTime;
        }
        const totalDuration = Date.now() - initialStartTime;
        let retry;

        if (isResponseError(error)) {
          retry = error.retry && attemptNumber < this.maxAttempts;
          this.trackAttempt(url, duration, false, error.statusCode || 0, attemptNumber, !retry, totalDuration,
            error.url && error.url !== url ? error.url : undefined, error.toString());
        } else {
          retry = attemptNumber < this.maxAttempts;
          this.trackAttempt(url, duration, false, 0, attemptNumber, !retry, totalDuration, undefined,
            error.toString());
        }
        return { error, retry };
      });
  }

  private fetchWithCORS(url: string, headers: Params = {}) {
    return (this.fetchOverride || fetch)(url, { mode: 'cors', headers });
  }

  private trackAttempt(url: string, duration: number, success: boolean, statusCode: number, attemptNumber: number,
                       finalAttempt: boolean, totalDuration: number, redirectedTo?: string, error?: string) {
    if (success && !TRACK_SUCCESS) {
      return;
    }

    const properties = {
      attempt: JSON.stringify({ 'number': attemptNumber, final: finalAttempt, totalDuration, url, redirectedTo, error })
    };
    const measurements = {
      totalDuration
    };
    this.tracker.trackDependency(Method.GET, url, duration, success, statusCode, properties, measurements);
  }
}
