import bows from 'bows';
import CastEvents from '../nrkCastSender/CastEvents';
import CastStateEvents from '../LudoCastPlayerAdapter/CastStateEvents';
import playButtonFactory from './playButton';
import muteButtonFactory from './muteButton';
import mediaInfoFactory from './mediaInfo';
import arrayIncludes from 'array-includes';
import { removeChild } from '../../ui/dom';

const containerClassName = 'ludo-persistent-controls';

export default (props: any) => {
  const {
    castSender,
    programBaseUrl,
    liveBaseUrl,
    getProgramUrl,
    castState = null,
    logger = bows('cast:persistent'),
    verboseLogging = false
  } = props;

  const mountElement = document.body;
  let hasUi = false;
  let castButton: HTMLElement;
  let playButton: ReturnType<typeof playButtonFactory>;
  let muteButton: ReturnType<typeof playButtonFactory>;
  let mediaInfo: any;
  let playButtonElem: HTMLElement;
  let muteButtonElem: HTMLElement;
  let mediaInfoElem: HTMLElement;
  let persistentControlsContainerElem: HTMLElement;

  function setUpUi() {
    if (hasUi) {
      return;
    }
    hasUi = true;

    require('./index.styl');

    // Mount
    persistentControlsContainerElem = document.createElement('article');
    persistentControlsContainerElem.className = containerClassName;

    const headingElem = document.createElement('h1');
    headingElem.className = 'ludo-persistent--visually-hidden';
    headingElem.textContent = 'Google Cast avspiller';

    playButton = playButtonFactory({ logger, document });
    muteButton = muteButtonFactory({ logger, document });
    mediaInfo = mediaInfoFactory({ logger, document });

    playButtonElem = playButton.create(castSender);
    muteButtonElem = muteButton.create(castSender);
    mediaInfoElem = mediaInfo.create(castSender, {
      programBaseUrl,
      liveBaseUrl,
      getProgramUrl
    });
    // @ts-ignore
    castButton = document.createElement('button');
    castButton.className = 'ludo-persistent-controls--cast-button';
    const castLauncher = document.createElement('google-cast-launcher');
    castButton.appendChild(castLauncher);

    persistentControlsContainerElem.appendChild(headingElem);
    persistentControlsContainerElem.appendChild(mediaInfoElem);
    persistentControlsContainerElem.appendChild(playButtonElem);
    persistentControlsContainerElem.appendChild(muteButtonElem);
    persistentControlsContainerElem.appendChild(castButton);

    mountElement.insertBefore(persistentControlsContainerElem, mountElement.firstChild);
    persistentControlsContainerElem.classList.add('ludo-persistent-controls__visible');

    logger('UI added');
  }

  function tearDownUi() {
    if (!hasUi) {
      return;
    }
    hasUi = false;

    persistentControlsContainerElem.classList.remove('ludo-persistent-controls__visible');

    persistentControlsContainerElem.addEventListener('transitionend', () => {
      playButton.destroy();
      muteButton.destroy();
      mediaInfo.destroy();

      removeChild(persistentControlsContainerElem);
      logger('UI removed');
    });
  }

  function onAppConnectedHandler() {
    logger('Connected');

    setUpUi();
  }

  function onApiAvailableHandler() {
    logger('API available.');

    castSender.connect();
  }

  function onOtherMediaLoaded() {
    logger('Other media loaded.');

    setUpUi();
  }

  function onCurrentMediaLoaded() {
    logger('Current media loaded.');

    tearDownUi();
  }

  function onAppDisconnectHandler() {
    logger('Disconnected');

    tearDownUi();
  }

  function onIdleHandler() {
    logger('Idle');

    tearDownUi();
  }
  function onMediaUnloadedHandler() {
    logger('Media unloaded');

    tearDownUi();
  }
  function onMediaInfoHandler(mediaInfo: any) {
    if (!mediaInfo) {
      tearDownUi();
      logger('Media info empty');
    }
  }

  function doVerboseLogging() {
    const excludeEvents = [CastEvents.CURRENT_TIME];
    Object.keys(CastEvents)
      .map((event) => event as keyof object)
      .filter((event) => !arrayIncludes<string>(excludeEvents, CastEvents[event]))
      .forEach((event) => {
        castSender.on(CastEvents[event], (...args: any[]) => {
          logger(event, args);
        });
      });
  }

  const unmount = () => {
    tearDownUi();

    if (castState) {
      castState.off(CastStateEvents.OTHER_MEDIA_LOADED, onOtherMediaLoaded);
      castState.off(CastStateEvents.CURRENT_MEDIA_LOADED, onCurrentMediaLoaded);
      castSender.off(CastEvents.APP_DISCONNECTED, onAppDisconnectHandler);
    } else {
      castSender.off(CastEvents.API_AVAILABLE, onApiAvailableHandler);
      castSender.off(CastEvents.APP_CONNECTED, onAppConnectedHandler);
      castSender.off(CastEvents.APP_DISCONNECTED, onAppDisconnectHandler);
      castSender.off(CastEvents.IDLE, onIdleHandler);
      castSender.off(CastEvents.MEDIA_UNLOADED, onMediaUnloadedHandler);
      castSender.off(CastEvents.MEDIA_INFO, onMediaInfoHandler);
    }

    logger('Terminated');
  };

  if (castState) {
    castState.on(CastStateEvents.OTHER_MEDIA_LOADED, onOtherMediaLoaded);
    castState.on(CastStateEvents.CURRENT_MEDIA_LOADED, onCurrentMediaLoaded);
    castSender.on(CastEvents.APP_DISCONNECTED, onAppDisconnectHandler);
  } else {
    castSender.on(CastEvents.API_AVAILABLE, onApiAvailableHandler);
    castSender.on(CastEvents.APP_DISCONNECTED, onAppDisconnectHandler);
    castSender.on(CastEvents.APP_CONNECTED, onAppConnectedHandler);
    castSender.on(CastEvents.IDLE, onIdleHandler);
    castSender.on(CastEvents.MEDIA_UNLOADED, onMediaUnloadedHandler);
    castSender.on(CastEvents.MEDIA_INFO, onMediaInfoHandler);
  }

  if (verboseLogging) {
    doVerboseLogging();
  }

  logger('Initialized');

  return {
    castSender,
    unmount
  };
};
