import SCRUBBER_EVENTS from './scrubberEvents';
import { LudoEvents } from '@nrk/ludo-core';
import { ScrubberContext } from './index';

export default ({ scrubberEvents, leftTimeElement, rightTimeElement, currentTimeElement, progressBarElement, player }: ScrubberContext) => {
  let isLive: boolean;

  player.on(LudoEvents.PREPARED, () => {
    isLive = player.current()!.isLive;
  });

  player.on(LudoEvents.SEEKED, update);

  function update() {
    const leftTimeWidth = leftTimeElement.offsetWidth;
    const rightTimeWidth = rightTimeElement.offsetWidth;
    const currentTimeElWidth = currentTimeElement.offsetWidth;
    const leftPosition = currentTimeElement.offsetLeft;
    const width = progressBarElement.offsetWidth;

    const collisionBetweenCurrentAndLeftElements = leftPosition < leftTimeWidth;
    const collisionBetweenCurrentAndRightElements = (leftPosition + currentTimeElWidth) > (width - rightTimeWidth);

    if (isLive) {
      leftTimeElement.style.visibility = collisionBetweenCurrentAndLeftElements ? 'hidden' : 'visible';
      rightTimeElement.style.visibility = collisionBetweenCurrentAndRightElements ? 'hidden' : 'visible';
    } else {
      currentTimeElement.style.visibility = collisionBetweenCurrentAndRightElements ? 'hidden' : 'visible';
    }
  }

  scrubberEvents.on(SCRUBBER_EVENTS.SLIDING_TIME_POSITIONED, update);
  scrubberEvents.on(SCRUBBER_EVENTS.PLAYED_TIME_UPDATED, update);
};
