import { getFontSize } from '../dom';

/**
 * Class holding the dimensions and useful units of the player. pw, ph, pmin
 * and pmax are equivalent to vw, vh, vmin and vmax, but for the player instead
 * of the viewport. All values are in pixels, with decimals.
 */
export class PlayerDimensions {
  private _width: number;
  private _height: number;

  constructor(width: number, height: number) {
    this._width = width;
    this._height = height;
  }

  get width() {
    return this._width;
  }

  get height() {
    return this._height;
  }

  get pw() {
    return this.width / 100;
  }

  get ph() {
    return this.height / 100;
  }

  get pmin() {
    return Math.min(this.pw, this.ph);
  }

  get pmax() {
    return Math.max(this.pw, this.ph);
  }

  /**
   * The base font size is relative to the width of the largest possible 16:9
   * rectangle inside the the player. At 800px width, the font size is 1rem (in
   * pixels).
   *
   * The reference size could be 1rem at any width, but 800px seems to provide
   * a reasonable base size to work with.
   *
   * Using the root font size enables scaling of the text inside Ludo by
   * adjusting the root font size. This is for accessibility.
   *
   * Control question about the 16:9 rectangle fit: Should a square video and a
   * 16:9 video with the same height have subtitles of the same size? What
   * about a 2.35:1 video of the same height?
   */
  get baseFontSize() {
    const maxAspectRatio = 1 / 0.5625;
    const referenceSize = getRootFontSize() / 800;
    const baseWidth = Math.min(this.width, this.height * maxAspectRatio);
    return baseWidth * referenceSize;
  }

  /**
   * Utility function for calculating a final font size based on the "burned-in"
   * base font size. The output is a rem size, which allows the user to change
   * the font size by changing the document root font size (for accessibility).
   * The resulting font size will also have the "burned-in" feeling if the base
   * argument is 0.
   */
  getRemFontSize({ scale = 1, addPx = 0, minPx = 0, maxPx = Infinity } = {}): string {
    const absoluteSize = scale * this.baseFontSize + addPx; // f=ax+b
    const boundSize = Math.max(minPx, Math.min(maxPx, absoluteSize));
    const relativeSize = boundSize / getRootFontSize();
    return `${relativeSize}rem`;
  }
}

/**
 * The font size of :root in pixels. It's ideal to be relative to this, so that
 * users who wish to scale the text by adjusting the root font size is able to
 * do so.
 */
export const getRootFontSize = (() => {
  let rootFontSize: number | undefined;

  return () => {
    if (!rootFontSize) {
      rootFontSize = getFontSize(document.documentElement);
    }
    return rootFontSize;
  };
})();
