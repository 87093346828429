import { nrkMediaTilgjengelighetKommer } from '@nrk/core-icons';
import { MediaItem } from '../../../nrk/mediaItem/MediaItem';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType, mediaItem: MediaItem) => {
  require('./index.styl');

  const targetElement = ui.get('targetElement');
  const countdownOverlayElem = document.createElement('div');
  countdownOverlayElem.className = 'ludo-countdown-overlay';

  const countdownTextCol = document.createElement('div');
  countdownTextCol.className = 'ludo-countdown-overlay__info';
  countdownTextCol.insertAdjacentHTML('beforeend', nrkMediaTilgjengelighetKommer);

  const countdownTextElem = document.createElement('span');
  countdownTextElem.className = 'ludo-countdown-overlay__text';

  const { availableFrom } = mediaItem;

  if (availableFrom instanceof Date) {
    countdownTextElem.textContent = mediaItem.blockedMessage || 'Direktesendingen starter senere.';
  }

  countdownTextCol.appendChild(countdownTextElem);

  // Clone poster
  let poster: HTMLImageElement;
  const existingPoster = targetElement.querySelector<HTMLImageElement>('.ludo-poster__image');
  if (existingPoster) {
    poster = existingPoster.cloneNode() as HTMLImageElement;
    poster.className = 'ludo-countdown-overlay__poster';

    if (!poster.src && !poster.srcset && mediaItem.poster) {
      poster.src = mediaItem.poster;
    }

    countdownOverlayElem.appendChild(poster);
  }

  countdownOverlayElem.appendChild(countdownTextCol);

  targetElement.insertBefore(countdownOverlayElem, targetElement.firstChild);

  function remove() {
    const el = countdownOverlayElem;
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }

  return {
    remove
  };
};
