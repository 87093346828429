import { Cue, CueConfig } from './Cue';

/* hh:mm:ss.sss */
function complexTimeToSeconds(timeString: string) {
  return timeString.split(':').map(parseFloat).reduce((prev, current, index) => {
    return prev + current * [3600, 60, 1][index];
  }, 0);
}

function getSegmentConfiguration(configTuples: string[]): CueConfig {
  const config: CueConfig = {};

  configTuples.forEach((item) => {
    const keyVal = item.split(/:/);
    const key = keyVal[0] as keyof CueConfig;

    config[key] = keyVal[1];
  });

  return config;
}

function getBlankCue(): Cue {
  return {
    id: '',
    lines: [],
    start: -1,
    end: -1
  };
}

export function parseWebVTT(data: string) {
  // remove first three lines of meta data
  const lines = data.trim().split('\n').slice(2);
  // Initialise with empty caption
  const captions: Cue[] = [getBlankCue()];
  let segment: Cue = getBlankCue();
  let inSegment: boolean;

  lines.forEach((line) => {
    if (/^\d+$/.test(line)) {
      segment.id = line;
    } else if (line.trim().length === 0 && inSegment) {
      captions.push(segment);
      segment = getBlankCue();
      inSegment = false;
    } else if (/-->/.test(line)) {
      // Format is of style 00:03:28.560 --> 00:03:31.160 align:end
      const time = line.split(/\s/);
      segment.start = complexTimeToSeconds(time[0]);
      segment.end = complexTimeToSeconds(time[2]);
      segment.config = time.length > 3 ? getSegmentConfiguration(time.slice(3)) : {};
      inSegment = true;
    } else if (inSegment) {
      segment.lines.push(line);
    }
  });

  captions.push(segment);
  return captions;
}
