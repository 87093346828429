export interface ResponseError {
  readonly name: string;
  message: string;
  retry: boolean;
  url?: string;
  statusCode?: number;
  toString: () => string;
}

export function isResponseError(error: any): error is ResponseError {
  return error.name === 'DataError' || error.name === 'UnexpectedStatusError';
}

export class DataError implements ResponseError {
  name = 'DataError';
  message: string;
  retry = false;
  url?: string;
  statusCode: number;

  constructor(message: string, response: Response) {
    this.message = message;
    this.url = response.url;
    this.statusCode = response.status;
  }

  toString() {
    return `${this.name}: ${this.message}`;
  }
}

export class UnexpectedStatusError implements ResponseError {
  name = 'UnexpectedStatusError';
  message: string;
  retry: boolean;
  url?: string;
  statusCode: number;

  constructor(response: Response, { retry = false } = {}) {
    this.message = `Unexpected status code: ${response.status}`;
    this.retry = retry;
    this.url = response.url;
    this.statusCode = response.status;
  }

  toString() {
    return this.message;
  }
}
