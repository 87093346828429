export default {
  ALLOWHIDECONTROLS: 'allowhidecontrols',
  BLOCKHIDECONTROLS: 'blockhidecontrols',
  BREAKPOINT: 'breakpoint',
  CONTROLBARHIDDEN: 'controlbarhidden',
  CONTROLBARVISIBLE: 'controlbarvisible',
  CONTROLLEROFF: 'controlleroff',
  CONTROLLERON: 'controlleron',
  DISABLECONTROLS: 'disablecontrols',
  ENABLECONTROLS: 'enablecontrols',
  IDLE: 'idle',
  OPTIONCHANGED: 'optionchanged',
  PLAYER_SIZE_CHANGED: 'playersizechanged',
  SEEKTIMEENDED: 'seektimeended',
  SEEKTIMEUPDATE: 'seektimeupdate',
  SHOWCONTROLS: 'showcontrols',
  HIDECONTROLS: 'hidecontrols',
  TOGGLEHELPOVERLAY: 'togglehelpoverlay',
  TOGGLEMETRICS: 'togglemetrics',
  TRANSLATION: 'translation'
};
