import { SimpleListener } from './SimpleListener';

interface SkipSectionDefinition {
  start: number;
  end: number;
  timeout: number;
  maxCount: number;
}

export type SkipSectionStates = 'on' | 'off' | 'timedout';

export class SkipSection extends SimpleListener<(display: SkipSectionStates) => void> {
  private _definition: SkipSectionDefinition;
  private _state: SkipSectionStates = 'off';
  private _previousCurrentTime: number = 0;
  private _hideTimeoutId: number | any;
  private _displayedCount = 0;

  constructor(definition: SkipSectionDefinition) {
    super();
    this._definition = definition;
  }

  destroy() {
    this.setState('off');
    clearTimeout(this._hideTimeoutId);
    super.destroy();
  }

  currentTime(currentTime: number) {
    const previousCurrentTime = this._previousCurrentTime;
    this._previousCurrentTime = currentTime;

    const { start, end } = this._definition;

    if (currentTime < start || currentTime > end) {
      this.setState('off');
      clearTimeout(this._hideTimeoutId);
      return;
    }

    if (this._displayedCount >= this._definition.maxCount) {
      return;
    }

    const enteredIntro = previousCurrentTime <= start
      && currentTime > start
      && currentTime < end;

    if (enteredIntro) {
      this.setState('on');
      clearTimeout(this._hideTimeoutId);
      this._hideTimeoutId = setTimeout(() => {
        if (this._state === 'on') {
          this.setState('timedout');
        }
      }, this._definition.timeout);
    }
  }

  private setState(newState: SkipSectionStates) {
    if (newState !== this._state) {
      this.emit(newState);
      this._state = newState;
      if (newState === 'on') {
        this._displayedCount++;
      }
    }
  }
}
