import { SubtitlePreferences, SubtitlePreference } from './general';

/*
 * Safely parse the stored value to valid SubtitlePreferences
 */
export function parseStoredSubtitlePreferences(storedTextValue: string | null): SubtitlePreferences {
  let storedValue: any;
  if (typeof storedTextValue !== 'string') {
    return {};
  }
  try {
    storedValue = JSON.parse(storedTextValue);
  } catch (e) {
    return {};
  }
  if (typeof storedValue !== 'object') {
    return {};
  }
  return Object.keys(storedValue)
    .map((key): [string, SubtitlePreference | undefined] => [key, parsePreference(storedValue[key])])
    .filter(([, value]) => value)
    .reduce((o, [key, value]) => {
      o[key] = value!;
      return o;
    }, {} as SubtitlePreferences);
}

function parsePreference(value: any) {
  if (typeof value !== 'object') {
    return;
  }
  const parsed: SubtitlePreference = {};
  if (value.hasOwnProperty('enabled') && typeof value.enabled === 'boolean') {
    parsed.enabled = value.enabled;
  }
  if (value.hasOwnProperty('precedes') && Array.isArray(value.precedes)) {
    parsed.precedes = value.precedes;
  }
  return parsed;
}
