import { PlaybackSession } from './PlaybackSession';
import { ExtendedLudo } from '../interfaces';
import { LudoEvents } from '@nrk/ludo-core';
import { NrkEvents } from '../../nrk';

export function applyPlaybackSessionHandler(player: ExtendedLudo) {
  let currentPlaybackSession: PlaybackSession | undefined;

  player.getPlaybackSession = () => currentPlaybackSession;

  player.on(NrkEvents.PLAYBACK_ENDED, () => {
    if (currentPlaybackSession) {
      currentPlaybackSession.sessionEnded();
    }
  });

  player.on(LudoEvents.PREPARED, () => {
    if (currentPlaybackSession) {
      currentPlaybackSession.sessionEnded();
    }
    currentPlaybackSession = new PlaybackSession(player, player.current()!);
  });
}
