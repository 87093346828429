export const isChromecastSupported = () => {
  if (typeof window.chrome !== 'object') {
    return false;
  }

  const { userAgent = '', vendor = '' } = window.navigator;

  if (vendor.indexOf('Google Inc') === -1) {
    return false;
  }

  if (userAgent.indexOf('CriOS') >= 0) {
    return false;
  }

  const chromeVersion = userAgent.match(/Chrome\/([0-9]+)/);
  const version = chromeVersion ? parseInt(chromeVersion[1], 10) : 0;

  return version !== 0;
};
