import { Params } from './types';

// In iframes loaded with srcdoc, protocol is set to 'about:'
//
// 2022-11-14/Torgeir - PSAPI doesn't accept http.
export function getLocationProtocol() {
  return 'https:';
  // return window.location.protocol === 'http:' ? 'http:' : 'https:';
}

export function buildURL(protocol: string, domain: string, path: string, params: Params = {}) {
  const q = Object.keys(params).filter((key) => params[key]).map(
    (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
  const queryString = q.length ? `?${q.join('&')}` : '';
  return `${protocol}//${domain}${path}${queryString}`;
}
