import CastEvents from '../nrkCastSender/CastEvents';
import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../ludo/interfaces';

function getCastButton() {
  // @ts-ignore
  const castButton = document.createElement('google-cast-launcher');
  castButton.className = 'ludo-bar__button ludo-bar__button--cast';

  return castButton;
}

export default (options: any) => (player: ExtendedLudo) => {
  const { castSender, ui } = options;

  require('./buttons.styl');

  let isAvailable = false;
  let castButton: HTMLElement;
  const castWarpper = ui.element.querySelector('.ludo-bar__button_wrapper__cast')!;

  function render() {
    if (!castButton && !isAvailable) {
      return;
    }

    if (!castButton) {
      castButton = getCastButton();
      castWarpper.appendChild(castButton);
      castWarpper.removeAttribute('hidden');
    }
  }

  castSender.on(CastEvents.API_AVAILABLE, () => {
    isAvailable = true;
    render();
  });

  castSender.on(CastEvents.API_UNAVAILABLE, () => {
    isAvailable = false;
    render();
  });

  player.once(LudoEvents.PLAYBACKSTARTED, () => {
    render();
  });
};
