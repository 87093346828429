interface AccessibleLabel {
  liveIndicatorVisible?: boolean;
  title: string;
  ageText?: string;
  readableDuration?: string;
}

export function getAccessibleLabel(state: AccessibleLabel) {
  const lines = [];
  if (state.liveIndicatorVisible) {
    lines.push('Direkte nå');
  }
  if (state.title && state.title.length) {
    lines.push(`Spill av ${state.title}`);
  }
  if (state.ageText) {
    lines.push(state.ageText);
  }
  if (state.readableDuration) {
    lines.push(`Varigheten er ${state.readableDuration}`);
  }
  return lines.length ? `${lines.join('. ')}.` : '';
}
