import { IS_MOBILE } from '@nrk/ludo-core';
import muteButton from './components/muteButton';
import { volumeControl } from './components/volumeControl';
import uiOptions from '../../ui/options';
import playerMethods from '../../ui/playerMethods';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  require('./volume.styl');

  const volumeControlsContainer = ui.element.querySelector<HTMLElement>('.ludo-bar__volume')!;

  // Make sure scrubber doesn't misbehave when volume control is being used:
  volumeControlsContainer.addEventListener('click', (event) => event.stopPropagation());
  volumeControlsContainer.addEventListener('mouseenter', () => ui.set(uiOptions.SCRUBBER_IGNORE_MOUSE_MOVE, true));
  volumeControlsContainer.addEventListener('mouseleave', () => ui.set(uiOptions.SCRUBBER_IGNORE_MOUSE_MOVE, false));

  return (player: ExtendedLudo) => {

    const { setVolume, adjustVolume } = playerMethods(player);

    volumeControlsContainer.addEventListener('wheel', (event) => {
      const sign = event.deltaY < 0 ? 1 : -1;

      if (event.deltaY === 0) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      adjustVolume(sign * 0.05);
    });

    const root = volumeControlsContainer;
    const button = muteButton(player, root);
    if (!IS_MOBILE) {
      volumeControl(player, setVolume, root, () => button.focus());
    }
  };
};
