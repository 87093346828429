export default Object.freeze({
  CURRENT_MEDIA_LOADED: 'current-media-loaded',
  OTHER_MEDIA_LOADED: 'other-media-loaded',
  MEDIA_LOADED: 'media-loaded',
  CONNECTED: 'connected',
  CURRENT_MEDIA_DISCONNECTED: 'current-media-disconnected',
  OTHER_MEDIA_DISCONNECTED: 'other-media-disconnected',
  MEDIA_DISCONNECTED: 'media-disconnected',
  LOADING: 'loading',
  CURRENT_MEDIA_UNLOADED: 'current-media-unloaded',
  CURRENT_MEDIA_DETACHED: 'current-media-detached',
  MEDIA_UNLOADED_CONNECTED: 'media-unloaded-connected'
});
