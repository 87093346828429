import onIdle from './onIdle';
import Controller from './controller';
import touchBehaviour from './touchBehaviour';
import desktopBehaviour from './desktopBehaviour';
import uiEvents from '../../ui/events';
import uiOptions from '../../ui/options';

import { IS_IOS, LudoEvents, LudoOptions, SUPPORTS_TOUCH } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

const IDLE_TIMEOUT = 5000;
const ACTIVE_CLASSNAME = 'ludo-controller--active';

export default (ui: LudoUIType) => {

  require('./bar.styl');

  const controller = Controller(ui);
  const { controlBar } = controller;
  const controllerElement = controller.element;

  return (player: ExtendedLudo) => {

    let idleCallbackSet = false;

    function onFullscreenIdle() {
      document.body.style.cursor = 'none';
      controller.toggleOff();
    }

    ui.on(uiEvents.CONTROLLEROFF, () => {
      idleCallbackSet = false;
    });

    ui.on(uiEvents.CONTROLLERON, (toggleOffOnIdle) => {
      if (idleCallbackSet) {
        return;
      }
      if (toggleOffOnIdle) {
        ui.once(uiEvents.IDLE, () => {
          if (player.isPaused()) {
            return;
          }

          controller.toggleOff();
        });
        idleCallbackSet = true;
      }
    });

    player.once(LudoEvents.PLAYBACKSTARTED, () => {

      const delayIdle = onIdle(() => ui.emit(uiEvents.IDLE), IDLE_TIMEOUT);

      if (SUPPORTS_TOUCH) {
        touchBehaviour(ui, controller);
      }
      if (!IS_IOS) {
        desktopBehaviour(ui, controller, controlBar, delayIdle, player);
      }

      if (IS_IOS) {
        // Completely hide controlbar (not just visually) on iOs devices when in
        // fullscreen mode. Reason: VoiceOver may still be able to access
        // visually hidden elements in fullscreen mode (perhaps because of a
        // bug in Safari), and blind users may end up getting both the native
        // and the Ludo controls, which is confusing.
        const videoElement = player.get(LudoOptions.VIDEO_ELEMENT);

        videoElement.addEventListener('webkitbeginfullscreen', () => {
          controllerElement.classList.remove(ACTIVE_CLASSNAME);
        }, false);
        videoElement.addEventListener('webkitendfullscreen', () => {
          controllerElement.classList.add(ACTIVE_CLASSNAME);
        }, false);
      }
    });

    player.on(LudoEvents.ENDED, () => controller.toggleOn());

    player.on(LudoEvents.LOADING, () => {
      if (!ui.get(uiOptions.PINNED_CONTROL_OVERLAY)) {
        controllerElement.classList.remove(ACTIVE_CLASSNAME);
      }
    });

    function isElementInViewport(el: HTMLElement) {
      const rect = el.getBoundingClientRect();

      return rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement!.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement!.clientWidth);
    }

    function fullscreenToggled() {
      const { element } = ui;
      element.focus();
      if (!isElementInViewport(element) && element.scrollIntoView) {
        element.scrollIntoView();
      }
      controlBar.allowHide();
      controller.toggleOff(1000);
    }

    player.on(LudoEvents.FULLSCREENON, () => {
      ui.on(uiEvents.IDLE, onFullscreenIdle);
      fullscreenToggled();
    });

    player.on(LudoEvents.FULLSCREENOFF, () => {
      ui.off(uiEvents.IDLE, onFullscreenIdle);
      fullscreenToggled();
    });

    player.on(LudoEvents.OPTIONCHANGED, (option, enabled) => {
      if (option === LudoOptions.CONTROLS) {
        enabled ? controller.enable() : controller.disable();
      }
    });

    player.on(LudoEvents.PLAYBACKSTARTED, () => {
      controllerElement.classList.add(ACTIVE_CLASSNAME);

      if (!player.get(LudoOptions.CONTROLS)) {
        // Do not show controls if they are disabled
        controller.disable();
        return;
      }

      controller.enable();
      controller.toggleOff();
      controller.toggleOff(2000);
    });

    ui.on(uiEvents.DISABLECONTROLS, () => {
      controller.toggleOff();
      controller.disable();
    });

    ui.on(uiEvents.ENABLECONTROLS, () => {
      controller.enable();
    });

    ui.on(uiEvents.SHOWCONTROLS, () => {
      controller.toggleOn();
    });

    ui.on(uiEvents.HIDECONTROLS, () => {
      controller.toggleOff();
    });

    ui.on(uiEvents.OPTIONCHANGED, (option, value) => {
      if (option === uiOptions.PINNED_CONTROL_OVERLAY) {
        value ? controller.pinned() : controller.unpinned();
      }
    });

    ui.get(uiOptions.PINNED_CONTROL_OVERLAY) ? controller.pinned() : controller.unpinned();
  };
};
