import { AccessibilityOption, getCaptions, StreamState } from './dataMappers';
import { setupListeners as importedSetupListeners } from './overlayMenuEvents';
import { LudoUIType } from '../../ui/LudoUI';
import { hasMultipleSubtitlesSupport } from '../subtitles/subtitles';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { nrkClose } from '@nrk/core-icons';

const ICON_ON = '<svg class="stream-options--icon" viewBox="0 0 15 15"><path d="M7.5 14a6.5 6.5 0 1 1 0-13 6.5 6.5 0 0 1 0 13zm-.977-3.317l4.055-4.396c.46-.501-.332-1.246-.792-.845l-3.313 3.37s-1.022-.805-1.399-1.128c-.376-.324-1.012.424-.676.821l2.125 2.178z" fill="currentColor"/></svg>';
const ICON_OFF = '<svg class="stream-options--icon" viewBox="0 0 15 15"><path d="M7.5 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11zm0-1a6.5 6.5 0 1 1 0 13 6.5 6.5 0 0 1 0-13z" fill="currentColor"/></svg>';

const minStreams = 1;
const minCaptions = 0;

// MENU OVERLAY
interface OverlayMenuContainer {
  setupListeners?: typeof importedSetupListeners;
}

export default (ui: LudoUIType, player: ExtendedLudo, container: OverlayMenuContainer = {}) => {
  const { setupListeners = importedSetupListeners } = container;

  const element = document.createElement('div');
  element.className = 'stream-options';

  const render = bindRenderMethod();
  const eventHandlers = setupListeners(element);

  return {
    element,
    onStreamSelected: eventHandlers.onStreamSelected,
    onSubtitleSelected: eventHandlers.onSubtitleSelected,
    renderOverlayMenu: ({ streams = [], manifestName, subtitles = [], isSubtitlesOn }: StreamState) => {
      const activeElement = document.activeElement;
      const activeElementId = activeElement && element.contains(activeElement) ? activeElement.id : '';
      const supportMultipleSubtitles = hasMultipleSubtitlesSupport(player);
      const captions = getCaptions(subtitles, supportMultipleSubtitles);

      element.innerHTML = render({
        streams: mapStreams(streams, manifestName),
        captions
      });
      // try reset focus to previously active element in menu
      if (activeElementId) {
        (document.getElementById(activeElementId) || { focus() { } }).focus();
      }
    },
    /** Sets height of overlay menu to cover entire ludo area */
    onLudoResized: (ludoStageElement?: HTMLElement) => {
      if (ludoStageElement) {
        element.style.height = `${ludoStageElement.offsetHeight}px`;
      }
    }
  };
};

function bindRenderMethod() {
  const renderItem = (item: AccessibilityOption) => {
    const { id, label, checked = false, value } = item;
    const renderName = label.replace(/default/i, 'Av');

    return `<li>
    <button
      class="stream-options--button"
      type="button"
      id="variant_${id}"
      data-id="${id}"
      data-value="${value}"
      aria-pressed="${checked === true}">
        ${checked ? ICON_ON : ICON_OFF}
        &nbsp;${renderName}
      </button>
  </li>`;
  };

  const renderItems = (listItems: AccessibilityOption[], emptyLength: number) => {
    return listItems.length > emptyLength ?
      `<ul>${listItems.map(renderItem).join('')}</ul>` :
      `<p>Ikke tilgjengelig</p>`;
  };

  const render = ({ streams, captions }: RenderParams) => {
    return `<div class="stream-options--scroll-wrapper">
    <div class="stream-options--content">
      <div class="stream-options--section" name="subtitles">
          <h2>Undertekster</h2>
          ${renderItems(captions, minCaptions)}
      </div>
      <div class="stream-options--section${streams.length === minStreams ? ' no-options' : ''}" name="streams">
          <h2>Tilgjengelighet</h2>
          ${renderItems(streams, minStreams)}
      </div>
    </div>
  </div>
  <button type="button" class="stream-options--close" aria-label="Lukk">
    ${nrkClose}
  </button>`;
  };

  return ({ streams, captions }: RenderParams) => render({ streams, captions });
}

function mapStreams(streams: AccessibilityOption[], manifestName: string) {
  return streams.map((s) => {
    s.checked = s.value === manifestName;
    return s;
  });
}
interface RenderParams {
  captions: AccessibilityOption[];
  streams: AccessibilityOption[];
}
