import { fetchSubtitles } from './fetchSubtitles';
import { ExtendedLudo } from '../../../../ludo/interfaces';
import { LudoEvents } from '@nrk/ludo-core';
import EventEmitter from 'eventemitter3';
import { CueHandler } from './CueHandler';
import { Cue } from './Cue';
import debug from 'bows';

const log = debug('ludo:cue');

enum CueObserverState {
  IDLE,
  STARTING,
  OBSERVING
}

export enum CueObserverEvents {
  CUE_CHANGED = 'cue-changed'
}

export class CueObserver extends EventEmitter {
  private player: ExtendedLudo;
  private cueHandler: CueHandler;
  private state: CueObserverState = CueObserverState.IDLE;
  private currentFetch?: ReturnType<typeof fetchCancelableSubtitles>;
  private previousCue: Cue | undefined;
  private timer?: number;

  constructor(player: ExtendedLudo) {
    super();

    this.cueHandler = new CueHandler([]);

    this.player = player;
    player.on(LudoEvents.PLAYING, () => this.observe());
    player.on(LudoEvents.SEEKING, () => this.observe());
    player.on(LudoEvents.SEEKED, () => this.observe());
    player.on(LudoEvents.PAUSE, () => this.observe());
    player.on(LudoEvents.BUFFEREND, () => this.observe());
    player.on(LudoEvents.BUFFERSTART, () => this.observe());
    player.on(LudoEvents.RATECHANGE, () => this.observe());
  }

  start(src: string) {
    this.state = CueObserverState.STARTING;

    if (this.currentFetch) {
      this.currentFetch.abort();
    }

    this.currentFetch = fetchCancelableSubtitles(src);
    // tslint:disable-next-line:no-floating-promises
    this.currentFetch.fetch.then((cues) => {
      this.cueHandler = new CueHandler(cues);
      this.state = CueObserverState.OBSERVING;
      this.observe();
    });
  }

  destroy() {
    this.state = CueObserverState.IDLE;
  }

  stop() {
    this.state = CueObserverState.IDLE;
  }

  private observe() {
    if (this.state !== CueObserverState.OBSERVING) {
      log('Not observing');
      this.detectCueChange();
      this.previousCue = undefined;
      return;
    }
    const currentTime = this.player.currentTime();
    this.cueHandler.setTime(currentTime);
    const currentCue = this.cueHandler.current();
    this.detectCueChange(currentCue);
    this.previousCue = currentCue;
    if (this.player.isPaused()) {
      return;
    }

    if (currentCue) {
      const timeUntilEnd = currentCue.end - currentTime;
      if (timeUntilEnd >= 0) {
        this.delayObserve(timeUntilEnd);
        return;
      }
    }
    const upcomingCue = this.cueHandler.getNextCue(currentTime);
    if (upcomingCue) {
      const timeUntilStart = upcomingCue.start - currentTime;
      this.delayObserve(timeUntilStart);
    }
  }

  private delayObserve(secUntilNextCheck: number) {
    if (this.timer) {
      window.clearTimeout(this.timer);
    }
    if (secUntilNextCheck < 0) {
      return;
    }
    const playbackRate = this.player.playbackRate() || 1;
    this.timer = window.setTimeout(() => {
      this.observe();
    }, (secUntilNextCheck * 1000 / playbackRate) + 10);
  }

  private detectCueChange(currentCue?: Cue) {
    const previousCue = this.previousCue;
    const hasChanged = this.previousCue !== currentCue;
    if (hasChanged) {
      this.emit(CueObserverEvents.CUE_CHANGED, currentCue, previousCue);
    }
    return hasChanged;
  }
}

function fetchCancelableSubtitles(src: string) {
  let aborted = false;

  const abort = () => {
    aborted = true;
  };

  const fetch = fetchSubtitles(src).then((cues) => {
    if (aborted) {
      throw new Error('Fetch was aborted');
    }
    return cues;
  });

  return {
    fetch,
    abort
  };
}
