import CastEvents from '../nrkCastSender/CastEvents';
import CastStateEvents from '../LudoCastPlayerAdapter/CastStateEvents';
import logger from '../logger';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../ludo/interfaces';
import { LudoUIType } from '../../ui/ui/LudoUI';

const ACTIVE_CLASSNAME = 'ludo-cast-overlay--active';

const OVERLAY_STATES = {
  IDLE: 0,
  LOADING: 1,
  LOADED: 2,
  DISCONNECTED: 3,
  DETACHED: 4,
  CONNECTED: 5
};

const statusTexts = {
  [OVERLAY_STATES.IDLE]: '',
  [OVERLAY_STATES.LOADING]: 'Kobler til',
  [OVERLAY_STATES.LOADED]: 'Sender til',
  [OVERLAY_STATES.DISCONNECTED]: 'Kobler fra',
  [OVERLAY_STATES.DETACHED]: 'Kobler fra',
  [OVERLAY_STATES.CONNECTED]: ''
};

interface OverlayProps {
  castSender: any;
  ui: LudoUIType;
  castState: any;
  log?: any;
  document?: HTMLDocument;
}

export default (props: OverlayProps) => (player: ExtendedLudo) => {
  const {
    castSender,
    ui,
    castState,
    log = logger('cast:overlay')
  } = props;

  let deviceName = '';
  let posterUrl: string | null = null;
  let overlayState = OVERLAY_STATES.IDLE;
  let overlay: HTMLElement;
  let statusElement: HTMLElement;
  let imageElement: HTMLElement;
  let svg: HTMLElement;

  const controller = ui.element.querySelector<HTMLElement>('.ludo-controller')!;

  function render() {
    log('render', Object.keys(OVERLAY_STATES)[overlayState]);

    if (overlayState === OVERLAY_STATES.IDLE) {
      if (overlay) {
        overlay.classList.remove(ACTIVE_CLASSNAME);
      }
      return;
    }

    if (!overlay) {
      require('./overlay.styl');

      overlay = document.createElement('div');
      overlay.className = 'ludo-cast-overlay';

      imageElement = document.createElement('img');
      imageElement.className = 'ludo-cast-overlay__image';
      overlay.appendChild(imageElement);

      statusElement = document.createElement('span');
      statusElement.className = 'ludo-cast-overlay__status';
      overlay.appendChild(statusElement);

      svg = document.createElement('a');
      svg.style.display = 'none';
      svg.className = 'ludo-poster__svg';
      svg.innerHTML = `<svg class="ludo-poster__play" viewBox="0 0 112 112">
        <title>play icon with progress</title>
        <circle class="ludo-poster__play-fill" r="54.5" cx="56" cy="56" fill="transparent"></circle>
        <circle class="ludo-poster__play-progress" r="53" cx="56" cy="56" fill="transparent" stroke-dasharray="333" stroke-dashoffset="333" transform="rotate(-90 56 56)"></circle>
        <svg viewBox="0 0 15 15" x="32" y="32" width="50" height="50"><path fill="currentColor" d="M4 2.58c0-.9.69-1.35 1.46-.9l6.9 4.87c.86.54.86 1.45 0 1.99l-6.9 4.78c-.86.45-1.46 0-1.46-.9V2.58z"/></svg>
      </svg>`;

      svg.addEventListener('click', () => player.play());
      overlay.appendChild(svg);

      const stageElement = player.get(LudoOptions.STAGE_ELEMENT);
      stageElement.insertBefore(overlay, controller);
    }

    if (posterUrl) {
      imageElement.setAttribute('src', posterUrl);
    }

    const statusText = statusTexts[overlayState] ? `${ statusTexts[overlayState] } ${ deviceName }` : '';
    statusElement.textContent = statusText;
    overlay.title = statusText;
    overlay.classList.add(ACTIVE_CLASSNAME);

    svg.style.display = overlayState === OVERLAY_STATES.CONNECTED ? '' : 'none';
  }

  player.on(LudoEvents.PREPARE, () => {
    const current = player.current();
    if (current && current.poster) {
      posterUrl = current.poster;
    }
    render();
  });

  castSender.on(CastEvents.LOADING, () => {
    overlayState = OVERLAY_STATES.LOADING;
    render();
  });

  castState.on(CastStateEvents.CURRENT_MEDIA_LOADED, () => {
    overlayState = OVERLAY_STATES.LOADED;
    render();
  });

  castState.on(CastStateEvents.CURRENT_MEDIA_DISCONNECTED, () => {
    overlayState = OVERLAY_STATES.DISCONNECTED;
    render();
  });

  castState.on(CastStateEvents.CURRENT_MEDIA_DETACHED, () => {
    overlayState = OVERLAY_STATES.DETACHED;
    render();
  });

  castState.on(CastStateEvents.CURRENT_MEDIA_UNLOADED, () => {
    overlayState = OVERLAY_STATES.IDLE;
    render();
  });

  castState.on(CastStateEvents.MEDIA_UNLOADED_CONNECTED, () => {
    overlayState = OVERLAY_STATES.CONNECTED;
    render();
  });

  castSender.on(CastEvents.DEVICE_NAME, (name: string) => {
    deviceName = name;
    render();
  });
};
