import { getKey, SubtitlePreferences, SubtitleRef, SubtitlePreference } from './general';

function getPreferred(existingPreference: SubtitlePreference | undefined, allOtherRelevantKeys: string[]): SubtitlePreference {
  const precedes = ((existingPreference && existingPreference.precedes) || [])
    .concat(allOtherRelevantKeys)
    .filter((value, index, self) => self.indexOf(value) === index);

  return {
    enabled: true,
    precedes
  };
}

function removePreferredKeyFromOthers(preferences: SubtitlePreferences, allOtherRelevantKeys: string[], preferredKey: string) {
  allOtherRelevantKeys.forEach((key) => {
    const preference = preferences[key];
    if (!preference || !preference.precedes) {
      return;
    }
    const precedes = preference.precedes.filter((k) => k !== preferredKey);
    preferences[key] = {
      ...preference,
      precedes
    };
  });
}

export function applyPreferredSubtitle(preferences: SubtitlePreferences, preferredSubtitle: SubtitleRef, availableSubtitles: SubtitleRef[] = []) {
  preferences = { ...preferences };
  const preferredKey = getKey(preferredSubtitle);
  const existingKeys = Object.keys(preferences);
  const allOtherRelevantKeys = availableSubtitles
    .map((sub) => getKey(sub))
    .filter((k) => k !== preferredKey)
    .filter((k) => existingKeys.indexOf(k) !== -1);

  preferences[preferredKey] = getPreferred(preferences[preferredKey], allOtherRelevantKeys);
  removePreferredKeyFromOthers(preferences, allOtherRelevantKeys, preferredKey);

  return preferences;
}
