import { ScrubberContext } from '../index';
import { format } from '../../../duration';
import SCRUBBER_EVENTS from '../scrubberEvents';

/*
 * This module handles an input range element which is positioned behind the
 * scrubber. It is not reachable using the mouse. The role of this element is to
 * provide an accessible way of scrubbing with screen readers. It is updated to
 * reflect the current time of the player, and it does also update the current
 * time when it is being used.
 *
 * (Keyboard events should be captured by ui/components/navKeys and should
 * override default behavior of the input range element to make scrubbing using
 * keyboard consistent throughout Ludo. I.e., keyboard events should not hit
 * this element)
 */
export default ({ player, rangeElement, scrubberEvents }: ScrubberContext) => {
  let prevValue: number = player.currentTime() || 0;

  function updateRange() {
    const current = player.current();
    if (!current) {
      return;
    }

    const currentTime = player.currentTime();
    const duration = player.duration();

    updateRangeValueText();

    rangeElement.max = Math.floor(duration) + '';
    rangeElement.min = '0';

    rangeElement.valueAsNumber = currentTime;
    prevValue = rangeElement.valueAsNumber; // re-read to get correct roundoff
  }

  function handleRangeChange() {
    const changedValue = rangeElement.valueAsNumber;
    const duration = player.duration();
    let seekValue = 0;

    if (changedValue > prevValue) {
      seekValue = player.currentTime() + 10;
      seekValue = seekValue > duration ? duration : seekValue;
    } else if (changedValue <= prevValue) {
      seekValue = player.currentTime() - 10;
      seekValue = seekValue < 0 ? 0 : seekValue;
    }

    player.seekTo(seekValue);

    rangeElement.valueAsNumber = seekValue;
    prevValue = rangeElement.valueAsNumber; // re-read to get correct roundoff

    updateRangeValueText();
  }

  function updateRangeValueText() {
    const current = player.current();
    const isLive = !!current && current.isLive;
    const currentTime = player.currentTime();
    let timeText;

    if (isLive) {
      timeText = format(player.convertTimeToLiveTime(currentTime));
      timeText = timeText.replace(/\:/g, ' ');

      // TODO: use isPlayingLive from liveButton
      if (currentTime > player.duration() - 5) {
        timeText = 'Du ser direkte, ' + timeText;
      }
    } else {
      const duration = player.duration();
      const percentage = (currentTime / duration) * 100;
      timeText = format(currentTime) + ' av ' + format(duration) + ' (' + percentage.toFixed(1) + '%)';
    }

    rangeElement.setAttribute('aria-valuetext', timeText);
  }

  scrubberEvents.on(SCRUBBER_EVENTS.PLAYED_TIME_UPDATED, updateRange);
  rangeElement.addEventListener('change', handleRangeChange);
};
