import { nrkMediaRwd15sec } from '@nrk/core-icons';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { LudoEvents } from '@nrk/ludo-core';

export default (ui: LudoUIType) => {
  const button = ui.element.querySelector<HTMLButtonElement>('button[name="rwd15"]')!;
  button.innerHTML = nrkMediaRwd15sec;
  button.removeAttribute('hidden');

  return (player: ExtendedLudo) => {
    const disableWhenPositionIsAtStartOfBuffer = () => {
      button.disabled = player.currentTime() <= 1;
    };

    disableWhenPositionIsAtStartOfBuffer();
    player.on(LudoEvents.SEEKED, disableWhenPositionIsAtStartOfBuffer);
    player.on(LudoEvents.BUFFERSTART, disableWhenPositionIsAtStartOfBuffer);
    player.on(LudoEvents.PLAYING, () => (button.disabled = false));
    ui.on('click.rwd15', () => player.seekTo(player.currentTime() - 15));
  };
};
