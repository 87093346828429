import { Cue } from './Cue';

export class CueHandler {

  private currCaptionsIndex = 0;
  private cues: Cue[];

  constructor(cues: Cue[]) {
    this.cues = [...cues];
  }

  setTime(currentTime: number) {
    const currentCue = this.current();

    if (currentCue && currentTime >= currentCue.start && currentTime <= currentCue.end) {
      return;
    }

    this.currCaptionsIndex = findIndex(this.cues, (cue) => currentTime >= cue.start && currentTime <= cue.end);
  }

  current(): Cue | undefined {
    return this.cues[this.currCaptionsIndex];
  }

  getNextCue(currentTime: number): Cue | undefined {
    const idx = findIndex(this.cues, ((cue) => currentTime < cue.start));
    return this.cues[idx];
  }

  destroy() {
    this.cues = [];
  }
}

function findIndex<T>(items: T[], condition: (curr: T) => boolean) {
  let index = -1;
  for (let i = 0; i < items.length; ++i) {
    if (condition(items[i])) {
      index = i;
      break;
    }
  }
  return index;
}
