import SCRUBBER_EVENTS from './scrubberEvents';
import { LudoEvents } from '@nrk/ludo-core';
import UI_EVENTS from '../../ui/events';
import { ScrubberContext } from './index';

// Emits PIXEL_PROGRESS for every change in currentTime that corresponds to a pixel.
export default ({ player, ui, scrubberEvents, progressBarElement }: ScrubberContext) => {

  let intervalId: number;

  function change() {
    remove();

    if (player.isPaused()) {
      return;
    }

    const delay = player.duration() * 1000 / progressBarElement.offsetWidth || 0;

    intervalId = window.setInterval(() => scrubberEvents.emit(SCRUBBER_EVENTS.PIXEL_PROGRESS), Math.max(delay, 10));
  }

  function remove() {
    if (intervalId) {
      window.clearInterval(intervalId);
    }
  }

  player.on(LudoEvents.DURATIONCHANGE, change);
  player.on(LudoEvents.PLAYING, change);
  player.on(LudoEvents.ENDED, change);
  player.on(LudoEvents.PAUSE, change);
  player.on(LudoEvents.ITEM_CHANGED, change);
  ui.on(UI_EVENTS.PLAYER_SIZE_CHANGED, change);
  ui.on(UI_EVENTS.CONTROLBARVISIBLE, change);
  ui.on(UI_EVENTS.CONTROLBARHIDDEN, remove);
};
