import { LudoBufferTypes, MediaIdentifier } from '@nrk/ludo-core';
import { MediaItem } from '../MediaItem';
import logger from 'bows';

const log = logger('ludo:ldr:direct');

export async function directLoader(player: unknown, item: MediaIdentifier): Promise<MediaItem[] | null> {
  if (item.type !== 'url') {
    return null;
  }

  log('Constructing a media item for:', item);

  const isAudio = item.mimeType.split('/')[0] === 'audio';
  const bufferType = item.bufferType || LudoBufferTypes.FIXED;
  const isLive = bufferType !== LudoBufferTypes.FIXED;

  const mediaItem: MediaItem = {

    // Static:
    id: 'direct',
    duration: 0,
    indexPoints: [],
    isChannel: false,
    manifestName: 'default',
    programRightsHasNotStarted: false,
    streams: [],
    subtitles: [],
    thumbnails: [],
    isPlayable: true,
    posters: [],

    // Dynamic:
    sources: [
      {
        src: item.src,
        type: item.mimeType
      }
    ],
    isLive,
    isOngoing: isLive,
    isAudio,
    startTime: item.startTime,
    title: item.title,
    mimeTypes: [item.mimeType],
    isVideo: !isAudio,
    bufferType,
    bufferDuration: item.bufferDuration || 0
  };

  return [mediaItem];
}
