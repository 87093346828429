export const removeChild = (el: ChildNode) => {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};

export function getFontSize(element: HTMLElement) {
  const style = getComputedStyle(element);
  if (style.fontSize && /\d+(\.\d+)?px/.test(style.fontSize)) {
    return parseFloat(style.fontSize);
  }
  return 0;
}

// Since IE11 does not support the force attribute in classList.toggle
export function classToggle(element: HTMLElement, name: string, force?: boolean) {
  const add = force === void 0 ? !element.classList.contains(name) : force;
  element.classList[add ? 'add' : 'remove'](name);
}

// Ponyfill for Element.prototype.closest
// inspired by https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
export function elementClosest(el: Element, selector: string) {
  const proto = window.Element.prototype; // window.Element is not allowed in typescript, but needed for jsdom testing
  // @ts-expect-error - msMatchesSelector isn't typed up.
  const matches = proto.matches || proto.msMatchesSelector || proto.webkitMatchesSelector || (() => false);
  if ('closest' in proto) {
    return el.closest(selector);
  }
  for (; el; el = <Element>el.parentElement) {
    if (matches.call(el, selector)) {
      return el;
    }
  }
  return null;
}
