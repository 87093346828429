import { LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import arrayFind from 'array-find';
import SCRUBBER_EVENTS from '../scrubberEvents';
import eventToggler from '../../../ui/eventToggler';
import { ScrubberContext } from '../index';
import { EPGScrubberItem } from '../epgBuilder';

type EPGProgramOrBlank = import('../epgBuilder').EPGProgramOrBlank;

export default ({ player, scrubberEvents }: ScrubberContext) => {

  let cachedEpg: EPGScrubberItem[] = [];

  function focusedProgramChanged(program?: EPGProgramOrBlank) {
    if (program && program.type === 'emptyProgram') {
      scrubberEvents.emit(SCRUBBER_EVENTS.PROGRAM_LEAVE);
      scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_REMOVE);
      return;
    }

    const images = (program && program.image && program.image.webImages) || [];
    const src = (images[0] || {}).imageUrl;

    if (src) {
      scrubberEvents.emit(SCRUBBER_EVENTS.PROGRAM_ENTER, program);
      scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_LOADED, { src });
      return;
    }
    scrubberEvents.emit(SCRUBBER_EVENTS.PROGRAM_LEAVE);
    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_REMOVE);
  }

  function zoneEnter({ id = '', positionPx = 0 } = {}) {
    const program = arrayFind(cachedEpg, (p: any) => id.indexOf(p.programId) === 0);

    focusedProgramChanged(program);

    if (!program) {
      return;
    }

    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_POSITION_CHANGED, {
      row: 0,
      column: 0,
      left: positionPx
    });

  }

  function zoneLeave() {
    focusedProgramChanged();
  }

  function epgUpdated(epg: EPGScrubberItem[]) {
    cachedEpg = epg;
  }

  const scrubberToggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.ZONE_ENTER]: zoneEnter,
    [SCRUBBER_EVENTS.ZONE_LEAVE]: zoneLeave,
    [SCRUBBER_EVENTS.EPG_UPDATED]: epgUpdated
  });

  function bind() {
    scrubberToggler.on();
  }

  function unbind() {
    scrubberToggler.off();
    zoneLeave();
  }

  player.on(LudoEvents.LOADED, () => {
    const thumbnails = player.get('thumbnails');
    const shouldShowThumbnails = thumbnails === true || /dvr/i.test(thumbnails);

    if (shouldShowThumbnails && player.mediaType() === LudoMediaTypes.DVR) {
      bind();
      return;
    }
    unbind();
  });
};
