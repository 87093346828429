import CastEvents from '../nrkCastSender/CastEvents';
import { nrkMediaPlay, nrkMediaPause } from '@nrk/core-icons';

export default ({ logger }: any) => {
  let castSender: any;
  let button: HTMLButtonElement;

  function clickHandler() {
    const isPaused = castSender.remoteState.paused;
    isPaused ? castSender.play() : castSender.pause();
  }

  function update() {
    const isPaused = castSender.remoteState.paused;

    button.setAttribute('aria-label', isPaused ? 'Spill av' : 'Pause');
    button.innerHTML = isPaused ? nrkMediaPlay : nrkMediaPause;
    logger(isPaused ? 'Paused' : 'Playing');
  }

  return {
    create: (client: any) => {
      castSender = client;

      button = document.createElement('button');
      button.className = 'ludo-persistent-controls--play-button';
      button.type = 'button';

      button.addEventListener('click', clickHandler);
      castSender.on(CastEvents.PLAYING, update);
      castSender.on(CastEvents.PAUSED, update);

      update();

      return button;
    },
    destroy: () => {
      button.removeEventListener('click', clickHandler);
      castSender.removeListener(CastEvents.PLAYING, update);
      castSender.off(CastEvents.PAUSED, update);
    }
  };
};
