export enum Options {
  BREAKPOINT = 'breakpoint',
  FULLSCREEN_ENABLED = 'fullscreen-enabled',
  PINNED_CONTROL_OVERLAY = 'pinned-control-overlay',
  SUBTITLES_DISPLAYED = 'subtitles-displayed',
  SCRUBBER_IGNORE_MOUSE_MOVE = 'scrubber-ignore-mouse-move',
  TARGET_ELEMENT = 'targetElement',
  PLAYER_DIMENSIONS = 'player-dimensions'
}

export default Options;
