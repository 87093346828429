import { elementClosest } from '../../dom';
import { dispatchEvent } from '../../dom/events';

const topicStreams = 'onAccessibilityChanged_streams';
const topicSubtitles = 'onAccessibilityChanged_subtitles';
const KEYS = {
  ENTER: 13,
  SPACE: 32
};

/**
 * Listen for clicks and keyboard events (navigation, selection)
 * Returns object of event handlers that needs callbacks
 */
export const setupListeners = (element: HTMLElement) => {
  element.addEventListener('click', (event) => {
    const target = <HTMLElement>event.target;
    if (!target) {
      return;
    }

    // close on close btn click
    if (target.classList.contains('stream-options--close')) {
      const barBtn = document.querySelector('.ludo-bar__button--subtitles');
      if (barBtn && barBtn instanceof HTMLElement)  {
        barBtn.click();
      }
      return;
    }
    // close menu on backdrop click
    if (target.classList.contains('stream-options--scroll-wrapper')) {
      const prev = <HTMLElement>element.previousElementSibling;
      if (prev) {
        prev.click();
      }
      return;
    }
    if (target.nodeName !== 'BUTTON' || target.getAttribute('aria-pressed') === 'true') {
      return;
    }
    event.stopPropagation();
    fireEvent(element, target);
  });

  element.addEventListener('keydown', (event) => {
    const { code, keyCode } = event;
    const target = (<HTMLElement>event.target);

    if (!target || target.nodeName !== 'BUTTON' || target.getAttribute('aria-pressed') === 'true') {
      return;
    }

    if (keyCode === KEYS.SPACE || keyCode === KEYS.ENTER || /space|enter/i.test(code)) {
      event.stopPropagation();
      fireEvent(element, target);
    }
  });

  return {
    onSubtitleSelected: (handler: (event: CustomEvent<SubtitlesSelectedDetails>) => void) => {
      element.addEventListener(topicSubtitles, handler);
      return () => element.removeEventListener(topicSubtitles, handler);
    },
    onStreamSelected: (handler: (event: CustomEvent<StreamSelectedDetails>) => void) => {
      element.addEventListener(topicStreams, handler);
      return () => element.removeEventListener(topicStreams, handler);
    }
  };
};

/** Trigger event for selected subtitles or streams */
function fireEvent(element: HTMLElement, target: HTMLElement) {
  const section = <HTMLElement>elementClosest(target, '.stream-options--section');
  const type = section.getAttribute('name') as 'subtitles' | 'streams';

  if (type === 'streams') {
    const manifestName = target.getAttribute('data-value') || '';
    const elementId = target.id;
    const streamDetails: StreamSelectedDetails = { elementId, manifestName };
    dispatchEvent(
      element,
      topicStreams,
      streamDetails
    );
  }
  if (type === 'subtitles') {
    const subtitleId = target.getAttribute('data-id');
    const enabled = !/off/i.test(target.getAttribute('data-id') || '');

    dispatchEvent(
      element,
      topicSubtitles,
      { enabled, subtitleId }
    );
  }
}

interface StreamSelectedDetails {
  elementId: string;
  manifestName: string;
}
interface SubtitlesSelectedDetails {
  enabled: boolean;
  subtitleId: string;
}
// add overload for select events to avoid type errors
declare global {
  interface HTMLElementEventMap {
    onAccessibilityChanged_streams: CustomEvent<StreamSelectedDetails>;
    onAccessibilityChanged_subtitles: CustomEvent<SubtitlesSelectedDetails>;
  }
}
