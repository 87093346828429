import { nrkMediaVolumeMuted, nrkMediaVolume1, nrkMediaVolume2, nrkMediaVolume3 } from '@nrk/core-icons';
import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../../ludo/interfaces';

const VOLUME_LEVEL_BREAKPOINTS = [0, 2 / 5, 4 / 5];

export default (player: ExtendedLudo, root: HTMLElement) => {
  const button = root.querySelector<HTMLButtonElement>('.ludo-bar__button--volume')!;

  function setState() {
    const isMuted = player.isMuted();
    const volume = player.volume();
    const icons = [nrkMediaVolumeMuted, nrkMediaVolume1, nrkMediaVolume2, nrkMediaVolume3];
    let volumeLevel = 1;

    VOLUME_LEVEL_BREAKPOINTS.forEach((breakpoint, i) => {
      if (volume > breakpoint) {
        volumeLevel = i + 1;
      }
    });

    button.setAttribute('aria-label', isMuted ? 'Lyd på' : 'Lyd av');
    button.innerHTML = icons[isMuted ? 0 : volumeLevel];
  }

  player.on(LudoEvents.VOLUMECHANGE, setState);
  player.on(LudoEvents.MUTED, setState);
  player.on(LudoEvents.UNMUTED, setState);
  setState();

  button.addEventListener('click', () => {
    const isMuted = player.isMuted();
    if (isMuted) {
      player.once(LudoEvents.UNMUTED, () => {
        if (player.volume() === 0) {
          player.setVolume(1);
        }
      });
    }

    // tslint:disable-next-line:no-floating-promises
    player[isMuted ? 'unmute' : 'mute']();
  });

  return {
    focus: () => button.focus()
  };
};
