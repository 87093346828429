import { SUPPORTS_TOUCH } from '@nrk/ludo-core';

const DISPATCH_IGNORE = 'prevent_recursive_dispatch_maximum_callstack';

export const addMouseClickEventListener = (element: HTMLElement, callback: (event: Event, ...args: any[]) => any) => {

  if (!SUPPORTS_TOUCH) {
    element.addEventListener('click', callback);
    return;
  }

  let isTouchClick = false;

  const touchEndEvent = () => {
    isTouchClick = true;
    setTimeout(() => {
      isTouchClick = false;
    }, 600);
  };

  const clickEvent = (event: Event, ...args: any[]) => {
    if (!isTouchClick) {
      callback.apply(element, [event, ...args]);
    }
  };

  element.addEventListener('click', clickEvent);
  element.addEventListener('touchend', touchEndEvent);
};

/**
 * dispatchEvent - with infinite loop prevention
 * @param {Element} element The target object
 * @param {String} name The source object(s)
 * @param {Object} detail Detail object (bubbles and cancelable is set to true)
 * @return {Boolean} Whether the event was canceled
 */
export const dispatchEvent = (element: HTMLElement, name: string, detail: any) => {
  const ignore = `${DISPATCH_IGNORE}${name}`;
  let event: CustomEvent;
  const elementAdditions: { [k: string]: boolean | null } = element as any;

  if (elementAdditions[ignore]) {
    // We are already processing this event, so skip sending a new one
    return true;
  }
  elementAdditions[ignore] = true; // Add name to dispatching ignore

  if (typeof window.CustomEvent === 'function') {
    event = new window.CustomEvent(name, { bubbles: true, cancelable: true, detail });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, detail);
  }
  // IE reports incorrect event.defaultPrevented
  // but correct return value on element.dispatchEvent
  const result = element.dispatchEvent(event);
  elementAdditions[ignore] = null; // Remove name from dispatching ignore

  return result; // Follow W3C standard for return value
};
