let reduce: boolean = false;
let mediaQuery: MediaQueryList | undefined;

export const userPrefersReducedMotion = () => {
  if (!mediaQuery) {
    mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    reduce = mediaQuery.matches;

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', () => {
        reduce = mediaQuery!.matches;
      });
    }
  }

  return reduce === true;
};
