import { parse, toSeconds } from 'iso8601-duration';
import { LudoEvents } from '@nrk/ludo-core';
import SCRUBBER_EVENTS from './scrubberEvents';
import { ScrubberContext } from './index';

export default ({ player, scrubberEvents }: ScrubberContext) => {

  player.on(LudoEvents.LOADED, () => {
    const current = player.current();
    const indexPoints = current && 'indexPoints' in current && current.indexPoints;

    if (indexPoints) {
      const scrubberPoints = indexPoints.map((indexPoint) => {
          const { startPoint, title } = indexPoint;
          const position = toSeconds(parse(startPoint));

          return {
            position,
            title
          };
        })
        .reduce((o, k, i) => {
          o[i] = k;
          return o;
        }, {} as ScrubberPoints);

      scrubberEvents.emit(SCRUBBER_EVENTS.POINTS_UPDATED, {
        type: 'indexPoints',
        points: scrubberPoints
      });

    }
  });

  player.on(LudoEvents.ITEM_CHANGED, () => {});

};

export interface ScrubberPoints {
  [index: string]: {
    position: number;
    title: string;
    leftPc?: number;
    leftPx?: number;
  };
}
