import { LudoEvents } from '@nrk/ludo-core';
import { classToggle } from '../../dom';
import uiEvents from '../../ui/events';
import { format } from '../../duration';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

const MINIMIZED_CLASS_NAME = 'ludo-audio__info__subtitle--minimized';

export default (ui: LudoUIType) => {
  const element = document.createElement('div');
  element.className = 'ludo-audio__info';

  const title = document.createElement('h3');
  title.className = 'ludo-audio__info__title';
  element.appendChild(title);

  const subtitle = document.createElement('div');
  subtitle.className = 'ludo-audio__info__subtitle';
  subtitle.style.display = 'none';

  const text = document.createElement('span');
  text.className = 'ludo-audio__info__text';
  subtitle.appendChild(text);

  const moreLink = document.createElement('a');
  moreLink.className = 'ludo-audio__info__more';
  moreLink.textContent = 'mer';
  moreLink.setAttribute('tabindex', '0');
  moreLink.setAttribute('aria-hidden', 'aria-hidden');
  subtitle.appendChild(moreLink);

  element.appendChild(subtitle);

  const durationDiv = document.createElement('div');
  durationDiv.className = 'ludo-audio__info__duration';
  durationDiv.setAttribute('aria-hidden', 'aria-hidden');
  element.appendChild(durationDiv);

  function isMinimized() {
    return subtitle.classList.contains(MINIMIZED_CLASS_NAME);
  }

  function toggleFullDescription() {
    classToggle(subtitle, MINIMIZED_CLASS_NAME);
    const text = isMinimized() ? 'mer' : 'mindre';
    moreLink.textContent = text;
    moreLink.setAttribute('title', `les ${text}`);
    toggleMoreLink();
  }

  function toggleMoreLink() {
    const hasOverflow = text.scrollWidth > text.clientWidth;
    moreLink.style.display = hasOverflow || !isMinimized() ? '' : 'none';
  }

  function bind(player: ExtendedLudo) {
    player.on(LudoEvents.PREPARED, () => {
      const current = player.current()!;
      title.textContent = current.title || '';
      text.textContent = current.subtitle || '';
      const durationText = current.isLive ? '' : format(current.duration);
      durationDiv.textContent = durationText;
      durationDiv.setAttribute('title', `Spilletid ${durationText}`);
      subtitle.style.display = text.textContent ? '' : 'none';
      toggleMoreLink();
    });

    moreLink.addEventListener('click', () => toggleFullDescription());

    moreLink.addEventListener('keydown', (e) => {
      if ([13, 32].indexOf(e.which) !== -1) {
        toggleFullDescription();
      }
    });

    toggleFullDescription();

    ui.on(uiEvents.PLAYER_SIZE_CHANGED, toggleMoreLink);
  }

  return {
    element,
    bind
  };
};
