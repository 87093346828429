import { parse } from 'iso8601-duration';

const pad = (number: number) => {
  const abs = Math.floor(Math.abs(number));
  if (abs < 10) {
    return `0${abs}`;
  }
  return String(abs);
};

const parseArray = (arr: any[]) => {
  return (arr.length === 3 && !arr.some((x) => isNaN(x))) ? arr : [];
};

const toArray = (obj: { hours?: number; minutes?: number, seconds?: number }) => {
  const keys: ['hours', 'minutes', 'seconds'] = ['hours', 'minutes', 'seconds'];
  const timeParts = keys.map((k) => obj.hasOwnProperty(k) && obj[k]);
  return parseArray(timeParts);
};

const resolveTimeArgument = (args: FormatArguments): number[] => {
  if (args.length !== 1) {
    return parseArray(args);
  }
  const first = args[0];
  if (first instanceof Date) {
    return [
      first.getHours(),
      first.getMinutes(),
      first.getSeconds()
    ];
  }
  if (Array.isArray(first)) {
    return parseArray(first);
  }
  if (typeof first === 'string') {
    return toArray(parse(first));
  }
  if (typeof first === 'object') {
    return toArray(first);
  }
  if (!isNaN(first)) {
    const s = Math.abs(first);
    const sign = first < 0 ? -1 : 1;
    const hours = sign * Math.floor(s / 3600);
    const minutes = sign * Math.floor(s / 60 % 60);
    const seconds = sign * Math.floor(s % 60);
    return parseArray([hours, minutes, seconds]);
  }
  return [];
};

type FormatArguments = [Date | number | string | number[] | { hours?: number; minutes?: number, seconds?: number }]
  | number[]
  | [number[]];

export const format = (...args: FormatArguments) => {
  const isDate = args[0] instanceof Date;
  const resolved = resolveTimeArgument(args);
  const reduced: number[] = isDate ? resolved : resolved.reduce((arr: number[], val: number, index: number) => {
    if (index > 0 || arr.length || val !== 0) {
      arr.push(val);
    }
    return arr;
  }, []);
  const mapped = reduced.map(pad);
  const sign = reduced.some((item) => item < 0) ? '-' : '';
  const joined = mapped.join(':');

  return sign + joined;
};

const TIME_LABELS: [string, string][] = [['time', 'r'], ['minutt', 'er'], ['sekund', 'er']];

export function getReadableDurationText(duration: number) {
  const values = resolveTimeArgument([duration]);
  const parts = values.reduce((arr: string[], value, index) => {
    if (value && arr.length < 2) {
      const label = TIME_LABELS[index][0] + (value > 0 ? TIME_LABELS[index][1] : '');
      arr.push(`${value} ${label}`);
    }
    return arr;
  }, []);

  return parts.join(' og ');
}
