import { Site } from '../utilities/Site';

export enum InstrumentationKeys {
  TEST = 'd4e0f7d6-9fdb-42e9-b8f8-91ff288d01af',
  // STAGE = 'f4cf2c52-4917-43bd-b257-d82b0a681e18',
  // PREPROD = '10ccf025-3b42-4d82-b84d-901f21262a70',
  PROD = 'ff70c9d2-d463-4e98-acb2-b1862bbddde1'
}

export function getInstrumentationKeyForSite(site: Site) {
  if (site.isProduction) {
    return InstrumentationKeys.PROD;
  }
  return InstrumentationKeys.TEST;
}
