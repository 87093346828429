import { IS_MOBILE, LudoEvents } from '@nrk/ludo-core';
import { nrkMediaPause } from '@nrk/core-icons';
import playIcon from '../playIcon';
import uiOptions from '../../ui/options';
import uiEvents from '../../ui/events';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { PlayerDimensions } from '../../ui/playerDimensions';

export default (ui: LudoUIType) => {
  require('./centerPlayButton.styl');
  const button = document.createElement('button');
  button.type = 'button';
  button.style.display = 'none';
  button.className = 'ludo-bar__button ludo-playicon';
  button.innerHTML = nrkMediaPause;

  ui.insert(button, {
    parent: '.ludo-controlbar',
    position: ':first'
  });

  const bindPlayIcon = playIcon(ui, button, 'ludo-playicon');

  function setPlayButtonSize(dimensions?: PlayerDimensions) {
    if (!dimensions) {
      return;
    }
    /* Same as poster play button size. */
    const size = 10 * dimensions.pmin + 30;
    button.style.width = `${size}px`;
    button.style.height = `${size}px`;
  }

  if (IS_MOBILE) {
    setPlayButtonSize(ui.get(uiOptions.PLAYER_DIMENSIONS));
    ui.on(uiEvents.PLAYER_SIZE_CHANGED, setPlayButtonSize);
  }

  return (player: ExtendedLudo) => {

    player.once(LudoEvents.LOADED, () => {
      bindPlayIcon(player);

      ui.on('disablecontrols', () => {
        button.style.display = 'none';
      });

      ui.on('enablecontrols', () => {
        button.style.display = '';
      });

      button.addEventListener('click', () => {
        // e.stopPropagation(); /* TODO: Why? */
        if (player.isPaused()) {
          // tslint:disable-next-line:no-floating-promises
          player.play();

          ui.emit(uiEvents.ALLOWHIDECONTROLS);
          ui.emit(uiEvents.HIDECONTROLS);
        } else {
          player.pause();
        }
      });

      // TODO: why?
      [
        'touchstart',
        'touchend',
        'touchmove',
        'mousestart',
        'mouseend',
        'mousemove'
      ].forEach((event) => button.addEventListener(event, (e) => e.stopPropagation()));
    });

    player.on(LudoEvents.LOADED, () => {
      button.style.display = '';
    });
  };
};
