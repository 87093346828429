import { APIClient } from '../../APIClient';
import { LudoPlayer, MediaIdentifier, LudoPlayerLoader, LudoMediaItem } from '@nrk/ludo-core';
import { PodcastEpisodeLoader } from '../../APIClient/psapi/PodcastEpisodeLoader';
import PodcastMediaItem from './PodcastMediaItem';
import logger from 'bows';

const log = logger('ludo:ldr:podcst');

/**
 * This loader only handles podcast identifiers with numerical episode
 * references (or empty => 0), as the playback API for podcasts doesn't.
 */
export function podcastLoader(apiClient: APIClient): LudoPlayerLoader {
  return async (player: LudoPlayer, item: MediaIdentifier): Promise<LudoMediaItem[] | null> => {
    if (item.type !== 'podcast' || typeof item.episode === 'string') {
      return null;
    }

    const { podcast, episode, startTime } = item;
    const loader = new PodcastEpisodeLoader(apiClient, podcast, episode);

    log(`Attempting to load podcast: ${podcast}/${episode}`);

    const result = await loader.load();
    return [new PodcastMediaItem(result.data, startTime)];
  };
}
