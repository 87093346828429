export default {
  API_INITIALIZE: 'api-initialize',
  API_AVAILABLE: 'api-available',
  API_UNAVAILABLE: 'api-unavailable',
  APP_CONNECTED: 'app-connected',
  APP_DISCONNECTED: 'app-disconnected',
  APP_LOADED: 'app-loaded',
  APP_SESSION_CONNECTED: 'app-session-connected',
  APP_SESSION_DISCONNECTED: 'app-session-disconnected',
  DEVICE_NAME: 'device-name',
  SESSION_STATE: 'session-state',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',

  // playback events
  IDLE: 'idle',
  PAUSED: 'paused',
  PLAYING: 'playing',
  MUTED: 'muted',
  UNMUTED: 'unmuted',
  VOLUMECHANGE: 'volumechange',
  DURATION: 'duration',
  CURRENT_TIME: 'currentTime',
  MEDIA_INFO: 'mediaInfo',
  CUSTOM_DATA: 'customData',
  MEDIA_LOADED: 'mediaLoaded',
  MEDIA_UNLOADED: 'mediaUnLoaded',
  BUFFERING: 'buffering',
  SPINNERON: 'spinneron',
  SPINNEROFF: 'spinneroff',
  SUBTITLESOFF: 'subtitlesoff',
  SUBTITLESON: 'subtitleson',
  SUBTITLESDISABLED: 'subtitlesdisabled',
  TIMEUPDATE: 'timeupdate'
};
