import { SkipSectionStates } from '../../../ludo/skip/SkipSection';
import { nrkMediaNext, nrkClose } from '@nrk/core-icons';
import { SkipSectionType } from '../../../ludo/skip/detectSkipSections';

const CLASSNAME = 'ludo-skipoverlay__container';
const BTN_CLASSNAME = 'ludo-skipoverlay__button';

type SkipButtonAttributes = Pick<HTMLButtonElement, 'name' | 'innerText'>;
type ElementAttributes = SkipButtonAttributes & { 'aria-hidden': string, class: string, type: string };

export class SkipButton {
  readonly element: HTMLDivElement;
  private button: HTMLButtonElement;
  private type: SkipSectionType;

  constructor(attributes: SkipButtonAttributes, type: SkipSectionType) {
    this.type = type;
    // setup action button
    this.button = createElement('button', {
      class: BTN_CLASSNAME,
      type: 'button',
      name: attributes.name
    });
    // assign via setter that adds correct icon
    this.buttonText = attributes.innerText;

    // create container and append children
    this.element = compose(
      createElement('div', { class: CLASSNAME, 'aria-hidden': 'true' }),
      [
        createCancelButton(type),
        this.button
      ].filter(Boolean)
    );
  }

  focus() {
    this.button.focus();
  }

  isFocused() {
    return this.button === document.activeElement;
  }

  toggle(state: SkipSectionStates) {
    ['on', 'off', 'timedout']
      .filter((name) => name !== state)
      .forEach((name) => {
        this.element.classList.remove(`${CLASSNAME}--${name}`);
      });
    this.element.classList.add(`${CLASSNAME}--${state}`);
    this.button.classList.toggle(`${BTN_CLASSNAME}--active`, state === 'on');
    if (state === 'off') {
      this.element.setAttribute('aria-hidden', 'true');
    } else {
      this.element.removeAttribute('aria-hidden');
    }
  }

  isVisible() {
    return this.element.classList.contains(`${CLASSNAME}--on`);
  }

  set enabled(value: boolean) {
    this.element.style.display = value ? '' : 'none';
  }

  set buttonText(value: string) {
    if (this.type === 'credits') {
      this.button.title = 'Neste episode begynner om noen sekunder. Trykk for å gå til neste episode';
      this.button.innerHTML = `<span class="ludo-skipoverlay__countdown" aria-hidden="true">${value}  </span>` + nrkMediaNext;
    } else {
      this.button.title = 'Trykk for å hoppe over intro';
      this.button.innerHTML = `<span aria-hidden=true>${value}</span>`;
    }
  }
}

function createCancelButton(type: SkipSectionType) {
  if (type !== 'credits') {
    return '';
  }
  const cancelBtn = compose(
    createElement('button', {
      class: BTN_CLASSNAME,
      name: 'cancel',
      type: 'button'
    }),
    [srOnlySpan('Avbryt nedtelling'), nrkClose]
  );
  return cancelBtn;
}

function srOnlySpan(text: string) {
  return `<span class="ludo--visually-hidden">${text}</span>`;
}

function compose<T extends HTMLElement>(parent: T, children: (HTMLElement | string)[]) {
  children.forEach((node) => {
    if (typeof node === 'string') {
      parent.insertAdjacentHTML('beforeend', node);
    } else {
      parent.appendChild(node);
    }
  });
  return parent;
}

function createElement<Name extends keyof HTMLElementTagNameMap>(tagName: Name, attributes: Partial<ElementAttributes>) {
  const el = document.createElement(tagName);
  Object.keys(attributes).forEach((key) => {
    const value = attributes[key as keyof ElementAttributes];
    if (typeof value === 'string') { el.setAttribute(key, value); }
  });
  return el;
}
