import { nrkMediaSubtitles, nrkMediaSubtitlesActive } from '@nrk/core-icons';
import { classToggle } from '../../dom';
import { StreamState } from './dataMappers';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  // create button and overlay
  const button = document.createElement('button');
  button.type = 'button';
  button.className = 'ludo-bar__button ludo-bar__button--subtitles';
  button.innerHTML = nrkMediaSubtitles;

  return {
    element: button,
    setButtonState: ({subtitles = [], isSubtitlesOn, streams = [], manifestName}: StreamState) => {
      const hasAccessibilityVersions = streams.length > 1;
      const hasSubtitles = subtitles.length > 0;
      const anyAccessibilityEnabled = (hasSubtitles && isSubtitlesOn) || !streams.some((s) => s.value === manifestName && /default/i.test(s.value));
      const selectedStream = streams.filter((s) => s.value === manifestName)[0];
      const show = hasSubtitles || hasAccessibilityVersions;
      let streamLabel = hasAccessibilityVersions ? (selectedStream ? `${selectedStream.label} valgt.` : '') : '';
      streamLabel = (streamLabel === 'Av valgt.') ? 'Standard video valgt.' : streamLabel;

      // set status as title
      button.title = [
        hasSubtitles ? (`Undertekster ${isSubtitlesOn ? 'på' : 'av'}.`) : '',
        hasAccessibilityVersions ? streamLabel : ''
      ].join(' ').trim();

      // hide button when there are no subtitles or availability versions
      button[show ? 'removeAttribute' : 'setAttribute']('hidden', '');
      button.innerHTML = anyAccessibilityEnabled ? nrkMediaSubtitlesActive : nrkMediaSubtitles;

      classToggle(button, 'ludo-bar__button--subtitles-on', anyAccessibilityEnabled);
      classToggle(button, 'ludo-bar__button--subtitles-off', !anyAccessibilityEnabled);
    }
  };
};
