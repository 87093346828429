import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { buildElement } from './builder';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  require('./debugOverlay.styl');
  const overlay = buildElement(ui);
  const data: any = {};
  let video: HTMLVideoElement;
  let liveState: any = null;

  return (player: ExtendedLudo) => {
    player.on(LudoEvents.ADAPTER, (adapter) => {
      data['player.adapter'] = adapter.adapterName;
    });

    player.on(LudoEvents.PREPARED, () => {
      video = player.get(LudoOptions.VIDEO_ELEMENT);
      const current = player.current()!;
      data['mediaItem.duration'] = current.duration;
      data['mediaItem.bufferDuration'] = current.bufferDuration;
      data['mediaItem.bufferType'] = current.bufferType;
    });

    player.on(LudoEvents.TIMEUPDATE, (time) => {
      data.TIMEUPDATE = time;
    });

    player.on(LudoEvents.QUALITY_SWITCHED, (quality) => {
      data['quality.bitrate'] = quality.bitrate;
      data['quality.level'] = quality.level;
    });

    player.on(LudoEvents.DURATIONCHANGE, (duration) => {
      data.DURATIONCHANGE = duration;
    });

    player.on(LudoEvents.OPTIONCHANGED, (option, val) => {
      if (option === 'live-state') {
        liveState = val;
      }
    });

    const update = () => {
      data['player.duration'] = player.duration();
      data['player.currentTime'] = player.currentTime();

      let seekable = player.seekable();
      if (seekable.length) {
        data['player.seekable'] = `${seekable.start(0)} to ${seekable.end(0)}`;
      }

      if (video) {
        data['video.duration'] = video.duration;
        data['video.currentTime'] = video.currentTime;

        seekable = video.seekable;
        if (seekable.length) {
          data['video.seekable'] = `${seekable.start(0)} to ${seekable.end(0)}`;
        }
      }

      if (liveState) {
        const liveValues = {
          hlsBufferStartTime: liveState.hlsBufferStartTime && new Date(liveState.hlsBufferStartTime),
          initialDuration: liveState.initialDuration,
          liveDelay: liveState.liveDelay,
          streamLatency: liveState.streamLatency,
          currentTimeOffset: liveState.currentTimeOffset
        };

        Object.keys(liveValues)
          .map((key) => key as keyof typeof liveValues)
          .filter((key) => liveValues[key])
          .forEach((key) => {
            data[`live.${key}`] = liveValues[key];
          });
      }

      overlay.content(data);
    };

    if (!overlay.isVisible()) {
      overlay.show();
      update();
      setInterval(update, 1000);
    }
  };
};
