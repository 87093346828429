import { storage } from '@nrk/ludo-core';
import PersistentStoreKeys from '../../../ludo/PersistentStoreKeys';
import { parseStoredSubtitlePreferences } from './parseStoredSubtitlePreferences';
import { applyPreferredSubtitle } from './applyPreferredSubtitle';
import { findPreferredSubtitle } from './findPreferredSubtitle';
import { SubtitlePreferences, SubtitleRef } from './general';
import { applyDisabledToSubtitles } from './applyDisabledToSubtitles';

function getPreferences() {
  const storedTextValue = storage.persistent.getItem(PersistentStoreKeys.PREFERRED_SUBTITLES);
  return parseStoredSubtitlePreferences(storedTextValue);
}

function savePreferences(preferences: SubtitlePreferences) {
  storage.persistent.setItem(PersistentStoreKeys.PREFERRED_SUBTITLES, JSON.stringify(preferences));
}

export function updatePreferredSubtitle(preferredSubtitle: SubtitleRef, availableSubtitles: SubtitleRef[] = []) {
  const updatedPreferences = applyPreferredSubtitle(getPreferences(), preferredSubtitle, availableSubtitles);
  savePreferences(updatedPreferences);
}

export function disableSubtitles(availableSubtitles: SubtitleRef[] = []) {
  const updatedPreferences = applyDisabledToSubtitles(getPreferences(), availableSubtitles);
  savePreferences(updatedPreferences);
}

export function getPreferredSubtitle<T extends SubtitleRef>(availableSubtitles: T[] = []) {
  return findPreferredSubtitle(getPreferences(), availableSubtitles);
}
