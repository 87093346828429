/**
 * Base class for implementing API loaders with just a path.
 */

import { APIClient, APILoader, APIResult, Params } from './types';
import { DataError, UnexpectedStatusError } from './errors';

export class PathLoader<T, L = undefined> implements APILoader<T, L> {
  protected client: APIClient;
  protected path: string;

  constructor(client: APIClient, path: string) {
    this.client = client;
    this.path = path;
  }

  load(params: Params = {}): Promise<APIResult<T, L>> {
    return this.client.fetch<T>(this.path, params, this.processResponse.bind(this))
      .then((data) => {
        return {
          data,
          links: this.processLinks(data)
        };
      });
  }

  protected processResponse(response: Response): Promise<T> {
    if (response.status !== 200) {
      throw new UnexpectedStatusError(response);
    }

    return response.json()
      .catch((error) => {
        throw new DataError(`Failed to parse JSON: ${error}`, response);
      });
  }

  protected processLinks(data: T): L {
    // Needs to be overriden when L != undefined.
    // @ts-ignore
    return undefined;
  }
}
