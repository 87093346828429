import startButton from './startButton';
import detailsComp from './details';
import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {

  ui.element.style.display = 'none';

  const targetElement = ui.get('targetElement');
  const audioControls = document.createElement('div');
  audioControls.className = 'ludo-audio';

  targetElement.insertBefore(audioControls, targetElement.firstChild);

  const playCol = document.createElement('div');
  playCol.className = 'ludo-audio__col--play';

  const detailsCol = document.createElement('div');
  detailsCol.className = 'ludo-audio__col--details';

  audioControls.appendChild(playCol);
  audioControls.appendChild(detailsCol);

  const button = startButton();
  playCol.appendChild(button.buttonElement);

  const details = detailsComp(ui);
  detailsCol.appendChild(details.element);

  return (player: ExtendedLudo) => {
    button.bind(player);
    details.bind(player);

    player.once(LudoEvents.BEFOREPLAY, () => {
      ui.element.style.display = 'block';
      audioControls.classList.add('ludo-audio--play-started');
    });

    player.on(LudoEvents.ERROR_READABLE, () => {
      // Setting error message is handled elsewhere.
      audioControls.style.display = 'none';
      ui.element.style.display = 'block';
    });

  };
};
