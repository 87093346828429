import CastEvents from '../nrkCastSender/CastEvents';
import { LudoEvents } from '@nrk/ludo-core';
import CastStateEvents from '../LudoCastPlayerAdapter/CastStateEvents';
import { ExtendedLudo } from '../../ludo/interfaces';
import { LudoUIType } from '../../ui/ui/LudoUI';

const ACTIVE_CLASSNAME = 'ludo-cast-button--active';

function getCastButton() {
  // @ts-ignore
  const castButton = document.createElement('google-cast-launcher');
  castButton.title = 'Chromecast';
  castButton.className = 'ludo-cast-button';

  castButton.addEventListener('click', (event: MouseEvent) => event.stopPropagation());

  return castButton;
}

interface PosterCastButtonProps {
  castSender: any;
  ui: LudoUIType;
  castState: any;
}

export default (props: PosterCastButtonProps) => (player: ExtendedLudo) => {
  const { castSender, ui, castState } = props;

  require('./buttons.styl');

  let isAvailable = false;
  let castButton: HTMLElement;

  function render() {
    if (!castButton && !isAvailable) {
      return;
    }

    if (!castButton) {
      castButton = getCastButton();
      const posterOrRootElement = ui.element.querySelector('.ludo-poster') || ui.element;
      posterOrRootElement.appendChild(castButton);
    }

    if (isAvailable) {
      castButton.classList.add(ACTIVE_CLASSNAME);
    }
  }

  castSender.on(CastEvents.API_AVAILABLE, () => {
    isAvailable = true;
    render();
  });

  castSender.on(CastEvents.API_UNAVAILABLE, () => {
    isAvailable = false;
    render();
  });

  player.on(LudoEvents.FULLSCREENON, () => {
    if (castButton) {
      castButton.classList.remove(ACTIVE_CLASSNAME);
    }
  });

  player.on(LudoEvents.FULLSCREENOFF, () => {
    if (castButton) {
      castButton.classList.add(ACTIVE_CLASSNAME);
    }
  });

  castState.on(CastStateEvents.MEDIA_UNLOADED_CONNECTED, () => {
    setTimeout(() => {
      castButton.classList.add(ACTIVE_CLASSNAME);
    }, 10);
  });

  player.once(LudoEvents.PREPARED, () => {
    render();
  });
};
