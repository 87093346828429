import { LudoEvents, LudoItemLoader, LudoMediaItem, LudoPlayer, MediaIdentifier } from '@nrk/ludo-core';
import { APIClient } from '../../APIClient';
import { PlaybackMetadataLoader } from '../../APIClient/psapi/PlaybackMetadataLoader';
import PersistentStoreKeys from '../../../ludo/PersistentStoreKeys';
import logger from 'bows';
import { getWaitTime, requiresReload } from './reload';
import NrkEvents from '../../NrkEvents';
import { PlaybackMediaItem } from './PlaybackMediaItem';
import { IPlaybackMetadata } from '../../APIClient/psapi/response/IPlaybackMetadata';
import { DEFAULT_MANIFEST_NAME } from '../MediaItem';

const log = logger('ludo:ldr:playbk');

const MANIFEST_NAME_TEGNTOLK = 'tegntolk';
const MANIFEST_NAME_SYNSTOLK = 'synstolk';
const MAX_LOAD_ATTEMPTS = 5;
const MIN_RETRY_DELAY = 5000;

/**
 * Loader that uses the playback API. Supports standard ID based identifiers
 * and podcasts with named episodes.
 */
export class PlaybackMediaItemLoader implements LudoItemLoader {
  private apiClient: APIClient;
  private persistentStore: Storage;

  constructor(apiClient: APIClient, persistentStore: Storage) {
    this.apiClient = apiClient;
    this.persistentStore = persistentStore;
  }

  async metadata(player: LudoPlayer, item: MediaIdentifier): Promise<LudoMediaItem[] | null> {
    let shouldAbortLoad = false;
    player.once(LudoEvents.CUE, () => { shouldAbortLoad = true; });

    const id = getManifestReferenceId(item)!;
    const loader = this.getMetadataLoader(item);
    if (!loader) {
      return null;
    }

    // Enter the load loop targeting metadata.
    log('Loading metadata');

    const mediaItems = await this.load(1, loader, player, {
      id,
      startTime: item.startTime
    });
    if (shouldAbortLoad) {
      return new Promise(() => {});
    }
    return mediaItems;
  }

  private getMetadataLoader(item: MediaIdentifier) {
    if (item.type === 'standard') {
      return new PlaybackMetadataLoader(this.apiClient, item.id, item.mediaType);
    } else if (item.type === 'podcast' && typeof item.episode === 'string') {
      // The PSAPI has a slow/expensive query if we only provide the episode
      // (id). The podcast ID limits the scope of the query in some significant
      // way. See https://nrknyemedier.atlassian.net/browse/DNC-1420.
      return new PlaybackMetadataLoader(this.apiClient, [item.podcast, item.episode], 'podcast');
    }
    return undefined;
  }

  private getPreferredManifest(item: MediaIdentifier) {
    if (item.type === 'standard') {
      if (item.synstolk) {
        return MANIFEST_NAME_SYNSTOLK;
      } else if (item.tegntolk) {
        return MANIFEST_NAME_TEGNTOLK;
      } else {
        return this.persistentStore.getItem(PersistentStoreKeys.PREFERRED_MANIFEST) || undefined;
      }
    }
    return;
  }

  async manifest(player: LudoPlayer, item: MediaIdentifier): Promise<LudoMediaItem[] | null> {
    const loader = this.getMetadataLoader(item);
    if (!loader) {
      return null;
    }
    const id = getManifestReferenceId(item);
    const metadata = await loader.load();
    const preferredManifestType = this.getPreferredManifest(item);
    const initialManifestType = getManifestTypeByID(metadata.data, id);
    const targetManifestType = decideManifestType(
      metadata.links.manifests,
      initialManifestType,
      preferredManifestType || null
    );
    log(`Manifest types: initial=${initialManifestType}, preferred=${preferredManifestType}, available=${Object.keys(metadata.links.manifests)}, using=${targetManifestType}.`);

    if (!targetManifestType) {
      log('No manifest available.');
      return null;
    }
    const manifestLoader = metadata.links.manifests[targetManifestType!];
    if (!manifestLoader) {
      log('No manifest loader available.');
      return null;
    }
    const manifest = await manifestLoader.load();
    const mediaItem = new PlaybackMediaItem(metadata.data, preferredManifestType, manifest.data);
    mediaItem.startTime = item.startTime;
    return [mediaItem];
  }

  canLoad(item: MediaIdentifier) {
    if (item.type === 'standard' || item.type === 'podcast' && typeof item.episode === 'string') {
      log('Use PlaybackMediaItemLoader');
      return true;
    }
    return false;
  }

  /**
   * Load data from the metadata loader and create media items from the data.
   * When the metadata is accepted (no reload), then change loader to the
   * manifest loader and create media items from the combined data. When
   * accepted (no reload), return the final media items.
   */
  private async load(attempt: number, loader: PlaybackMetadataLoader, player: LudoPlayer, params: { id: string, startTime: number | undefined, preferredManifestType?: string | null }): Promise<LudoMediaItem[]> {
    let shouldAbortLoad = false;
    player.once(LudoEvents.CUE, () => { shouldAbortLoad = true; });

    // Cache buster. The metadata is cached for a while (especially in IE11?),
    // so we need a fresh copy after any availability count-down in order to
    // get the link to the manifest.
    const queryStringParams = attempt > 1 ? { attempt: `${attempt}` } : undefined;

    // Load and create media items.
    const metadata = await loader.load(queryStringParams);
    const playbackMediaItem = new PlaybackMediaItem(metadata.data);
    playbackMediaItem.startTime = params.startTime;

    // If reload is required, wait and recurse with the current loader.
    if (requiresReload(playbackMediaItem) && attempt < MAX_LOAD_ATTEMPTS) {
      const waitTime = getWaitTime(playbackMediaItem);
      let adjustedWaitTime = attempt === 1 ? waitTime : Math.max(waitTime, MIN_RETRY_DELAY);

      // Spread attempts/requests over time to protect the server
      // by adding wait time by 0 - 3 secs
      adjustedWaitTime += Math.floor(Math.random() * 3000);
      player.emit(NrkEvents.COUNTDOWN_INITIALIZED, adjustedWaitTime, playbackMediaItem);

      log(`Reloading in ${adjustedWaitTime / 1000} seconds`);
      await sleep(adjustedWaitTime);
      if (shouldAbortLoad) {
        return new Promise(() => {});
      }

      return this.load(attempt + 1, loader, player, params);
    }

    return [playbackMediaItem];
  }
}

/**
 * Figure out which manifest type the original media identifier is associated with.
 */
function getManifestTypeByID(metadata: IPlaybackMetadata, id: string | undefined): string | null {
  const manifests = metadata._embedded && metadata._embedded.manifests || [];
  const embeddedManifest = manifests.filter((manifest) => manifest.id === id)[0];
  return embeddedManifest && embeddedManifest._links && embeddedManifest._links.self.name || null;
}

/**
 * The rules for picking the right manifest type.
 */
function decideManifestType(availableTypes: { [name: string]: unknown }, initialManifestType: string | null, preferredManifestType: string | null): string | null {
  if (initialManifestType && initialManifestType !== DEFAULT_MANIFEST_NAME && initialManifestType in availableTypes) {
    return initialManifestType;
  }
  if (preferredManifestType && preferredManifestType in availableTypes) {
    return preferredManifestType;
  }
  if (DEFAULT_MANIFEST_NAME in availableTypes) {
    return DEFAULT_MANIFEST_NAME;
  }
  return null;
}

/**
 * Promise based sleep function.
 */
const sleep = (ms: number) => new Promise<void>((resolve) => window.setTimeout(resolve, ms));

function getManifestReferenceId(item: MediaIdentifier) {
  if (item.type === 'standard') {
    return item.id;
  } else if (item.type === 'podcast' && typeof item.episode === 'string') {
    return item.episode;
  }
  return undefined;
}
