import EventEmitter from 'eventemitter3';
import loadGoogleCastSDK from './loadGoogleCastSDK';
import castNrkPlayer from './castNrkPlayer';
import importedRemoteState from './remoteState';
import CastEvents from './CastEvents';
import { ChromecastOptions } from '../../ludo/interfaces';

const RECEIVER_APPLICATION_IDS = {
  TV_DEV: 'CA379C91',
  TV_TEST: 'A11196C4',
  TV_PROD: '3AEDF8D1'
};

export default (ccOptions: Partial<ChromecastOptions> = {}) => {
  const receiverApplicationId = ccOptions.receiverApplicationId ? ccOptions.receiverApplicationId : RECEIVER_APPLICATION_IDS.TV_PROD;
  const fetchInviteCode = ccOptions.fetchInviteCode;

  const castSender = new EventEmitter();
  const remoteState: any = importedRemoteState({ castSender });

  function init() {
    remoteState.apiInitializing = true;

    return loadGoogleCastSDK()
      .then(() => {
        remoteState.apiAvailable = true;
      })
      .catch((e) => {
        remoteState.apiAvailable = false;
        castSender.emit(CastEvents.ERROR, e);
      });
  }

  const player = castNrkPlayer({
    fetchInviteCode,
    receiverApplicationId,
    castSender,
    remoteState
  });

  type ReturnType = typeof castSender & { init: typeof init } & typeof player;

  return Object.assign(
    castSender,
    { init },
    player
  ) as ReturnType;
};
