import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../../ludo/interfaces';

export function volumeControl(
  player: ExtendedLudo,
  setVolume: (volume: number) => void,
  root: HTMLElement,
  onMouseLeave = () => {}
) {

  const barControl = root.querySelector<HTMLElement>('.ludo-bar__volume__control')!;
  const volumeElement = root.querySelector<HTMLInputElement>('.ludo-bar__volume__slider')!;
  const fillElement = root.querySelector<HTMLElement>('.ludo-bar__volume__slider-overlay__fill')!;
  const thumbElement = root.querySelector<HTMLElement>('.ludo-bar__volume__slider-overlay__thumb')!;

  let focusedByMouse = false;

  function onVolumeChanged() {
    const volume = parseInt(volumeElement.value, 10) / 100;
    setVolume(volume);
  }

  function updateSlider(volume: number) {
    if (typeof volume === 'undefined') {
      return;
    }

    let newVolume;

    if (player.isMuted()) {
      newVolume = 0;
    } else {
      newVolume = volume * 100;
    }

    const existingVolume = parseInt(volumeElement.value, 10);

    if (Math.abs(existingVolume - newVolume) > 1) {
      volumeElement.value = String(newVolume);
    }

    updateOverlay(newVolume);
  }

  function updateOverlay(volume: number) {
    fillElement.style.height = `${volume}%`;
    thumbElement.style.bottom = `${volume}%`;
  }

  player.on(LudoEvents.VOLUMECHANGE, updateSlider);

  volumeElement.addEventListener('change', onVolumeChanged);
  volumeElement.addEventListener('input', onVolumeChanged);

  // Avoid global shortcuts from overriding
  volumeElement.addEventListener('keydown', (event: Event) => {
    event.stopPropagation();
    focusedByMouse = false;
  });

  // Hide control when clicked and left by mouse
  volumeElement.addEventListener('mousedown', () => {
    focusedByMouse = true;
  });
  volumeElement.addEventListener('mouseenter', () => {
    focusedByMouse = false;
  });
  volumeElement.addEventListener('mouseleave', () => {
    if (focusedByMouse) {
      onMouseLeave();
    }
    focusedByMouse = false;
  });
  volumeElement.addEventListener('focus', () => {
    barControl.classList.add('ludo-bar__volume__control--on');
  });
  volumeElement.addEventListener('blur', () => {
    barControl.classList.remove('ludo-bar__volume__control--on');
  });

  player.on(LudoEvents.LOADED, () => {
    updateSlider(player.volume());
  });
}
