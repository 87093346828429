import bows from 'bows';

const loggers: any = {};

export default (key = 'cast:root') => {
  if (typeof loggers[key] !== 'function') {
    loggers[key] = bows(key);
  }

  return loggers[key];
};
