/**
 * Ponyfills that are automatically replaced by webpack ProvidePlugin.
 *
 * This module must be:
 *  - kept as ES5
 *  - not be transpiled with typescript compiler
 *    (will not work with the --importHelpers option)
 */
var window = require('global/window');
var createFetch = require('fetch-ponyfill');
var objectAssign = require('es6-object-assign');
var pinkiePromise = require('pinkie');

// The test for native Promise support in 'pinkie-promise' don't work when
// using webpack.ProvidePlugin. We must refer to window.Promise when testing
// for support.
var Promise = typeof window.Promise === 'function' ? window.Promise : pinkiePromise;

var fetchPonyfill = createFetch({ Promise: Promise });
var fetch = typeof window.fetch === 'function' ? window.fetch : fetchPonyfill.fetch;
var Headers = typeof window.Headers !== 'undefined' ? window.Headers : fetchPonyfill.Headers;

/*
 * Hack for:
 * - "window.getComputedStyle() returns null inside an iframe with display: none"
 *   https://bugzilla.mozilla.org/show_bug.cgi?id=548397
 */
function getComputedStyle(element) {
  var computedStyle = window.getComputedStyle(element);
  if (computedStyle) {
    return computedStyle;
  }
  if (element.style.constructor.name === 'CSS2Properties' && window.self !== window.top) {
    return element.style;
  }
  return null; // never?
}

exports.objectAssign = objectAssign.assign;
exports.Promise = Promise;
exports.fetch = fetch;
exports.Headers = Headers;
exports.getComputedStyle = getComputedStyle;
