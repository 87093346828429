import { SkipButton } from './SkipButton';
import { SkipSectionStates } from '../../../ludo/skip/SkipSection';
import { SkipSectionType } from '../../../ludo/skip/detectSkipSections';
import { userPrefersReducedMotion } from '../../dom/reducedMotion';

const CLASSNAME = 'ludo-skipoverlay';
const ACTIVE_CLASSNAME = `${CLASSNAME}__button--active`;

interface SkipOverlayOptions {
  skipIntro?: boolean;
  skipCredits?: boolean;
}

export class SkipOverlay {
  readonly element: HTMLElement;
  readonly cancelBtn: HTMLButtonElement;
  private readonly skipIntroButton: SkipButton;
  private readonly skipCreditsButton: SkipButton;
  private interval: any;
  private readonly creditsCountDownStart = 9;
  readonly ludoContainsActiveElement: () => boolean;

  constructor(ludoUiElement: HTMLElement, { skipIntro, skipCredits }: SkipOverlayOptions) {

    const skipOverlayDiv = document.createElement('div');
    skipOverlayDiv.className = 'ludo-skipoverlay ludo-skipoverlay--off';
    this.element = skipOverlayDiv;

    this.skipIntroButton = new SkipButton({
      name: 'ludo-skip-intro',
      innerText: 'Hopp over intro'
    }, 'intro');
    this.skipIntroButton.enabled = skipIntro === true;

    this.skipCreditsButton = new SkipButton({
      name: 'ludo-skip-credits',
      innerText: `Neste om ${this.creditsCountDownStart}`
    }, 'credits');
    this.skipCreditsButton.enabled = skipCredits === true;

    this.cancelBtn = document.createElement('button');
    this.cancelBtn.classList.add('ludo-skipoverlay__cancel-btn');
    this.cancelBtn.setAttribute('aria-label', 'Lukk');
    this.cancelBtn.setAttribute('aria-hidden', 'true');
    this.cancelBtn.setAttribute('type', 'button');
    skipOverlayDiv.appendChild(this.cancelBtn);
    skipOverlayDiv.appendChild(this.skipIntroButton.element);
    skipOverlayDiv.appendChild(this.skipCreditsButton.element);

    ludoUiElement.appendChild(skipOverlayDiv);

    this.ludoContainsActiveElement = () => ludoUiElement.contains(document.activeElement);
  }

  setSectionButtonText(section: SkipSectionType, text: string) {
    switch (section) {
      case 'credits':
        this.skipCreditsButton.buttonText = text;
        break;
      case 'intro':
        this.skipIntroButton.buttonText = text;
        break;
    }
  }

  toggle(section: SkipSectionType, state: SkipSectionStates) {
    this.cancelBtn.setAttribute('aria-hidden', 'true');
    switch (section) {
      case 'intro':
        if (state === 'on') {
          this.cancelBtn.removeAttribute('aria-hidden');
        }
        this.skipIntroButton.toggle(state);
        this.focusButtonOnAppear(this.skipIntroButton, state);
        break;

      case 'credits':
        const reduceMotion = userPrefersReducedMotion();
        this.updateCreditsCountDown(state, reduceMotion);
        if (state === 'timedout') {
          if (!reduceMotion) {
            this.clickActiveButton();
          }
        } else {
          this.skipCreditsButton.toggle(state);
        }
        this.focusButtonOnAppear(this.skipCreditsButton, state);
        break;
    }
    // set state class
    ['on', 'off', 'timeout'].forEach((s) => this.element.classList.remove(`${CLASSNAME}--${s}`));
    this.element.classList.add(`${CLASSNAME}--${state}`);
  }

  isVisible(section?: SkipSectionType) {
    if (section) {
      return section === 'credits'
        ? this.skipCreditsButton.isVisible()
        : this.skipIntroButton.isVisible();
    }
    return this.skipIntroButton.isVisible() || this.skipCreditsButton.isVisible();
  }

  hide() {
    this.toggle('intro', 'off');
    this.toggle('credits', 'off');
  }

  clickActiveButton() {
    const button = this.element.querySelector<HTMLButtonElement>(`.${ACTIVE_CLASSNAME}`);
    if (button) {
      button.click();
    }
  }

  private focusButtonOnAppear(skipButton: SkipButton, state: SkipSectionStates) {
    // don't care when focus is outside of ludo
    if (state === 'on' && this.ludoContainsActiveElement()) {
      skipButton.focus();
    }
  }

  private getCountDownMarkup(time: number | null) {
    if (time == null) {
      return `Neste`;
    }
    return `Neste om ${time}`;
  }

  private updateCreditsCountDown(state: SkipSectionStates, reduceMotion: boolean) {
    switch (state) {
      case 'on':
        let time = this.creditsCountDownStart;
        this.skipCreditsButton.buttonText = this.getCountDownMarkup( reduceMotion ? null : time);
        if (!reduceMotion) {
          this.interval = setInterval(() => {
            const nextTime = time--;
            if (time > -1) {
              this.skipCreditsButton.buttonText = this.getCountDownMarkup(nextTime);
            }
          }, 1000);
        }
        break;
      default:
        clearInterval(this.interval);
        break;
    }
  }
}
