import { nrkMediaAirplay, nrkMediaAirplayActive } from '@nrk/core-icons';
import { IS_AIRPLAY_SUPPORTED, LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType, { isAirplaySupported = IS_AIRPLAY_SUPPORTED } = {}) => {
  if (!isAirplaySupported) {
    return () => {};
  }

  const button = ui.element.querySelector<HTMLButtonElement>('button[name="airplay"]')!;
  button.innerHTML = nrkMediaAirplay;
  button.removeAttribute('hidden');

  const setState = (state: boolean) => {
    button.setAttribute('aria-pressed', String(state));
    button.innerHTML = state ? nrkMediaAirplayActive : nrkMediaAirplay;
  };

  return (player: ExtendedLudo) => {
    player.on(LudoEvents.AIRPLAYON, () => setState(true));
    player.on(LudoEvents.AIRPLAYOFF, () => setState(false));
    button.addEventListener('click', (event) => {
      event.stopPropagation();
      player.airplay();
    });
  };
};
