import { MediaItem, Subtitle } from '../../../nrk/mediaItem/MediaItem';
import { SubtitlesID } from './SubtitlesID';
import { ExtendedLudo } from '../../../ludo/interfaces';

export const CURRENT_SUBTITLES = 'currentSubtitles';
export const HAS_MULTIPLE_SUBTITLES_SUPPORT = true;

export interface SubtitleState extends Subtitle {
  visible: boolean;
}

export function getPreferredSubtitles(subtitles: Subtitle[]) {
  if (!Array.isArray(subtitles)) {
    return [];
  }

  const result = subtitles.concat().map((subtitles) => {
    let pri = 0;
    if (subtitles.type === 'ttv') {
      pri = 1;
    }
    if (subtitles.default) {
      pri = 2;
    }
    return {
      pri,
      subtitles
    };
  });

  return result
    .sort((a, b) => b.pri - a.pri)
    .map((subtitles) => subtitles.subtitles);
}

export function getSubtitlesOfMediaItem(mediaItem?: MediaItem) {
  return mediaItem && 'subtitles' in mediaItem ? mediaItem.subtitles : [];
}

interface GetAvailableSubtitlesOptions extends FindVisibleSubtitleOptions {
  supportMultipleSubtitles: boolean;
}

export function getAvailableSubtitles(subtitles: Subtitle[], options: GetAvailableSubtitlesOptions): SubtitleState[] {
  const preferredSubtitles = getPreferredSubtitles(subtitles);
  const preferredSubtitle = preferredSubtitles[0];

  if (!options.supportMultipleSubtitles) {
    subtitles = preferredSubtitle ? [preferredSubtitle] : [];
  }

  const visibleSubtitle = findVisibleSubtitle(subtitles, options);

  return subtitles.map((sub) => ({
    ...sub,
    visible: visibleSubtitle === sub
  }));
}

interface FindVisibleSubtitleOptions {
  subtitlesEnabled?: boolean;
  currentSubtitles?: SubtitlesID;
}

export function findVisibleSubtitle(subtitles: Subtitle[], options: FindVisibleSubtitleOptions = {}) {
  if (options.subtitlesEnabled === false) {
    return;
  }

  if (options.currentSubtitles) {
    const matchingSubtitles = findMatchingSubtitle(subtitles, options.currentSubtitles);
    if (matchingSubtitles) {
      return matchingSubtitles;
    }
  }

  return undefined;
}

export function findMatchingSubtitle(subtitles: Subtitle[], reference: SubtitlesID | undefined) {
  return reference ? subtitles.filter((sub) => sub.type === reference.type && sub.srclang === reference.lang)[0] : undefined;
}

export function hasMultipleSubtitlesSupport(player: ExtendedLudo) {
  const playerSetting: boolean | undefined = player.get('supportMultipleSubtitles');
  return typeof playerSetting !== 'undefined' ?
    playerSetting :
    HAS_MULTIPLE_SUBTITLES_SUPPORT;
}
