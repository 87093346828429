import { Subtitle } from '../../../../nrk/mediaItem/MediaItem';
import { TextTrackRenderer } from '../TextTrackRenderer';
import { SubtitlesID } from '../SubtitlesID';
import { SubtitleState } from '../subtitles';

export class NativeSubtitles implements TextTrackRenderer {
  private videoElement: HTMLVideoElement;
  private currentReference: SubtitlesID | undefined;
  private subtitles: SubtitleState[] | undefined;

  constructor(videoElement: HTMLVideoElement) {
    this.videoElement = videoElement;
    videoElement.setAttribute('crossorigin', 'anonymous');

    // Subtitle track must be attached right after loadedmetadata
    videoElement.addEventListener('loadedmetadata', this.attachSubtitles);
  }

  setSubtitles(subtitles: SubtitleState[]) {
    this.subtitles = subtitles;
  }

  show() {
    this.reflectReference(this.currentReference);
  }

  hide() {
    this.reflectReference();
  }

  toggle(state: boolean) {
    state ? this.show() : this.hide();
  }

  reset() {
    this.removeTextTracks();
  }

  chooseSubtitle(reference?: SubtitlesID) {
    this.currentReference = reference;
    this.reflectReference(this.currentReference);
  }

  private attachSubtitles = () => {
    if (!this.subtitles) {
      return;
    }
    this.subtitles.forEach((subtitle) => {
      const track = createTextTrackElement(subtitle);
      track.addEventListener('load', () => {
        track.track.mode = isTrackElementMatch(track, this.currentReference) ? 'showing' : 'disabled';
      });
      this.videoElement.appendChild(track);
    });
  }

  private reflectReference(reference?: SubtitlesID) {
    const textTracks = this.getTrackElements();
    textTracks.forEach((track) => {
      track.track.mode = isTrackElementMatch(track, reference) ? 'showing' : 'disabled';
    });
  }

  private getTrackElements(): HTMLTrackElement[] {
    const elements = this.videoElement.querySelectorAll('track');
    if (!elements) {
      return [];
    }
    return Array.prototype.filter.call(elements, (textTrack: HTMLTrackElement) => textTrack.kind === 'subtitles');
  }

  private removeTextTracks() {
    this.getTrackElements().forEach((el) => el.parentNode!.removeChild(el));
  }
}

function createTextTrackElement(subtitle: Subtitle) {
  const track = document.createElement('track');
  track.kind = 'subtitles';
  track.src = subtitle.src;
  track.srclang = subtitle.srclang;
  track.label = subtitle.label;
  track.setAttribute('data-type', subtitle.type);
  return track;
}

function isTrackElementMatch(track: HTMLTrackElement, reference?: SubtitlesID) {
  if (!reference) {
    return false;
  }
  return track.getAttribute('data-type') === reference.type && track.srclang === reference.lang;
}
