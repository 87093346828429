import { format } from '../../../duration';
import { LudoEvents } from '@nrk/ludo-core';
import SCRUBBER_EVENTS from '../scrubberEvents';
import { ScrubberContext } from '../index';

export default ({ player, scrubberEvents, leftTimeElement, rightTimeElement }: ScrubberContext) => {

  let isLive: boolean;

  function updateText(left: string, right: string) {
    leftTimeElement.textContent = left;
    rightTimeElement.textContent = right;
    scrubberEvents.emit(SCRUBBER_EVENTS.PLAYED_TIME_UPDATED);
  }

  function prepared() {
    const current = player.current()!;
    isLive = current.isLive;
    if (isLive) {
      updateText('', '');
    } else {
      updateText('', `${format(current.duration)}`);
    }

    scrubberEvents.off(SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED, playingUpdated);
    scrubberEvents.off(SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED, endtimeUpdated);

    if (isLive) {
      scrubberEvents.on(SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED, endtimeUpdated);
    } else {
      scrubberEvents.on(SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED, playingUpdated);
    }

  }

  function playingUpdated() {
    const remaining = player.duration() - player.currentTime();
    updateText(
      '',
      `-${format(remaining)}`
    );
  }

  function endtimeUpdated() {
    updateText(
      format(player.convertTimeToLiveTime(0)),
      format(player.convertTimeToLiveTime(player.duration()))
    );
  }

  player.on(LudoEvents.PREPARED, prepared);

};
