import logger from '../logger';
import {
  ContentType,
  LudoError,
  LudoEvents,
  LudoMediaItem,
  LudoMediaSource,
  LudoOptions,
  LudoPlayer,
  LudoPlayerAdapter
} from '@nrk/ludo-core';

import CastEvents from '../nrkCastSender/CastEvents';
import CastStateEvents from './CastStateEvents';
import castHandler from './castHandler';
import { LudoUIType } from '../../ui/ui/LudoUI';
import { ExtendedLudo } from '../../ludo/interfaces';
import { MediaItem } from '../../nrk/mediaItem';
import { NrkMediaInfoCustomData } from '../nrkCastSender/NrkMediaInfoCustomData';
import { PROPERTIES } from '../nrkCastSender/remoteState';

export const ADAPTER_NAME = 'LudoCastPlayerAdapter';
const DEFAULT_LIVE_DELAY = 30;

const PIPE_THROUGH_EVENTS = {
  [CastEvents.PLAYING]: LudoEvents.PLAYING,
  [CastEvents.PAUSED]: LudoEvents.PAUSE,
  [CastEvents.MUTED]: LudoEvents.MUTED,
  [CastEvents.UNMUTED]: LudoEvents.UNMUTED,
  [CastEvents.VOLUMECHANGE]: LudoEvents.VOLUMECHANGE,
  [CastEvents.SPINNERON]: LudoEvents.SEEKING,
  [CastEvents.SPINNEROFF]: LudoEvents.SEEKED
};

type MediaInfo = chrome.cast.media.MediaInfo;

export default class LudoCastPlayerAdapter implements LudoPlayerAdapter {
  unload() {
    return Promise.resolve();
  }
  canAutoplay(type: ContentType, muted: boolean) {
    return Promise.resolve(true);
  }
  log: any;
  track: any;
  castSender: any;
  castState: any;
  ui: LudoUIType;
  private ludoEvents: any;
  private playbackEvents: any;
  private playbackEventsBound: any;
  private player!: ExtendedLudo;
  private source: LudoMediaSource | null = null;

  constructor(options: any, container: { log?: any, track?: any } = {}) {
    const {
      log = logger('cast:h:adapter'),
      track = logger('cast:h:track')
    } = container;

    this.log = log;
    this.track = track;

    const { castSender, ui, castState } = options;
    this.castSender = castSender;
    this.ui = ui;
    this.castState = castState;

    this.initPlaybackEvents();
  }

  create(player: LudoPlayer) {
    this.track('create');
    this.player = player as ExtendedLudo;

    const { ui, castSender, castState } = this;

    castState.bindToPlayer(player);

    castHandler({
      castState,
      castSender,
      ui,
      player: this.player
    });

    castState.on(CastStateEvents.CURRENT_MEDIA_LOADED, () => {
      this.bindPlaybackEvents();
      player.emit(LudoEvents.LOADED);
      player.emit(LudoEvents.BEFOREPLAY);
    });

    castState.on(CastStateEvents.OTHER_MEDIA_LOADED, () => {
      this.unbindPlaybackEvents();
    });

    castState.on(CastStateEvents.CURRENT_MEDIA_DISCONNECTED, () => {
      player.emit(LudoEvents.ENDED);
    });
  }

  initPlaybackEvents() {

    const pipedEventsWithCallback = Object.keys(PIPE_THROUGH_EVENTS).reduce((o, key) => {
      const ludoEvent = PIPE_THROUGH_EVENTS[key];
      o[key] = (...args: any[]) => {
        this.player.emit(ludoEvent, ...args);
      };
      return o;
    }, {} as any);

    const customEventsWithCallback = {
      [CastEvents.ERROR]: (e: Error, { fatal = false } = {}) => {
        this.player.error(e, {
          fatal,
          type: 'CHROMECAST',
          reason: LudoError.REASONS.MEDIA_PLAYBACK_ERROR
        });
      },
      [CastEvents.IDLE]: () => {
        if (this.castState.isLoaded) {
          this.player.emit(LudoEvents.ENDED);
        }
      },
      [CastEvents.SUBTITLESON]: () => {
        this.player.set(LudoOptions.SUBTITLES, true);
      },
      [CastEvents.SUBTITLESOFF]: () => {
        this.player.set(LudoOptions.SUBTITLES, false);
      },
      [CastEvents.SUBTITLESDISABLED]: () => {
        this.player.set(LudoOptions.SUBTITLES, null);
      },
      [CastEvents.CURRENT_TIME]: () => {
        this.player.emit(LudoEvents.TIMEUPDATE, this.currentTime());
      }
    };

    this.playbackEvents = {
      ...pipedEventsWithCallback,
      ...customEventsWithCallback
    };

    this.ludoEvents = {
      [LudoEvents.SUBTITLESON]: () => {
        this.castSender.subtitlesOn();
      },
      [LudoEvents.SUBTITLESOFF]: () => {
        this.castSender.subtitlesOff();
      },
      [LudoEvents.LOADED]: () => { // Reflect state when loaded
        const state = {
          [LudoEvents.TIMEUPDATE]: this.player.currentTime()
        };
        Object.keys(state).forEach((event) => {
          this.player.emit(event, state[event]);
        });
      }
    };
  }

  bindPlaybackEvents() {
    if (this.playbackEventsBound) {
      return;
    }
    const playbackEvents = this.playbackEvents;
    Object.keys(playbackEvents).forEach((castEvent) => {
      this.log('attach cast event', castEvent);
      this.castSender.on(castEvent, playbackEvents[castEvent]);
    });

    const ludoEvents = this.ludoEvents;
    Object.keys(ludoEvents).forEach((ludoEvent) => {
      this.log('attach ludo event', ludoEvent);
      this.player.on(ludoEvent, ludoEvents[ludoEvent]);
    });

    this.playbackEventsBound = true;
  }

  unbindPlaybackEvents() {
    if (!this.playbackEventsBound) {
      return;
    }
    const playbackEvents = this.playbackEvents;
    Object.keys(playbackEvents).forEach((castEvent) => {
      this.log('detach cast event', castEvent);
      this.castSender.off(castEvent, playbackEvents[castEvent]);
    });

    const ludoEvents = this.ludoEvents;
    Object.keys(ludoEvents).forEach((ludoEvent) => {
      this.log('detach ludo event', ludoEvent);
      this.player.off(ludoEvent, ludoEvents[ludoEvent]);
    });

    this.playbackEventsBound = false;
  }

  mount(element: HTMLElement) {
    this.track('mount', element);
    this.castState.isMounted = true;
  }

  unmount() {
    this.track('unmount');
    this.castState.isMounted = false;
    this.reset();
    this.unbindPlaybackEvents();
    return Promise.resolve();
  }

  reset() {
    this.track('reset');
  }

  static get adapterName() {
    return ADAPTER_NAME;
  }

  get adapterName() {
    return ADAPTER_NAME;
  }

  async play() {
    this.track('play');

    if (this.castState.requireRemoteLoading) {
      await this.sendLoadRequest();
      return;
    }
    if (this.castState.isInitialPlay) {
      this.castSender.updateState();
      return;
    }
    if (this.isPaused()) {
      this.castSender.play();
    }
  }

  private async sendLoadRequest() {
    const {
      subtitles: castSubtitles,
      playerState
    } = this.castSender.remoteState;

    const ludoSubtitles = this.player.get(LudoOptions.SUBTITLES);
    const subtitles = playerState ? castSubtitles : ludoSubtitles;
    const current = this.player.current()!;
    const preferredManifest = current.manifestName;
    const currentTime = this.castState.previousAdaptersCurrentTime;
    const loadOptions = {
      currentTime,
      subtitles,
      preferredManifest
    };
    await this.castSender.load(current.id, loadOptions);
  }

  pause() {
    this.track('pause');
    this.player.emit(LudoEvents.PAUSE);
    this.castSender.pause();
  }

  isPaused() {
    return this.castSender.isPaused();
  }

  currentTime() {
    return this.castSender.currentTime();
  }

  seekTo(seconds: number) {
    this.track('seekTo', seconds);
    const current = this.player.current();
    if (current && current.isLive) {
      const date = this.player.convertTimeToLiveTime(seconds);
      return this.castSender.seekToLive(date);
    }
    return this.castSender.seekTo(seconds);
  }

  duration() {
    const current = this.player.current();
    if (current && current.isLive && current.bufferDuration) {
      return current.bufferDuration - DEFAULT_LIVE_DELAY;
    }
    return this.castSender.duration();
  }

  mute() {
    this.track('mute');
    this.castSender.mute();
    this.player.emit(LudoEvents.VOLUMECHANGE);
  }

  unmute() {
    this.track('mute');
    this.castSender.unmute();
    this.player.emit(LudoEvents.VOLUMECHANGE);
  }

  isMuted() {
    this.track('isMuted');
    return this.castSender.isMuted();
  }

  volume() {
    this.track('volume');
    return this.castSender.volume();
  }

  setVolume(newVolume: number) {
    this.track('setVolume', newVolume);
    this.castSender.setVolume(newVolume);
    if (newVolume === 0) {
      this.player.emit(LudoEvents.VOLUMECHANGE, 0);
    }
  }

  buffered() {
    return {
      length: 0,
      start(i: number) { return 0; },
      end(i: number) { return 0; }
    };
  }

  seekable() {
    return {
      length: 0,
      start(i: number) { return 0; },
      end(i: number) { return 0; }
    };
  }

  played() {
    return {
      length: 0,
      start(i: number) { return 0; },
      end(i: number) { return 0; }
    };
  }

  playbackRate() {
    this.track('playbackRate');
    return 1;
  }

  setPlaybackRate(suggestedPlaybackRate: number) {
    this.track('setPlaybackRate', suggestedPlaybackRate);
    this.player.emit(LudoEvents.RATECHANGE);
  }

  async prepareMedia(mediaItem: LudoMediaItem) {
    this.source = this.selectSource(mediaItem);

    const remoteState = this.castSender.remoteState;
    await awaitCustomData(this.castSender);
    const mediaInfo = remoteState.mediaInfo;
    if (requiresReLoadOnChangedManifest(mediaItem as MediaItem, mediaInfo)) {
      await this.sendLoadRequest();
    }
  }

  absoluteBufferStartTime() {
    const mediaInfo = this.castSender.remoteState.mediaInfo;
    const remoteLiveDetails: NrkMediaInfoCustomData | undefined = this.castSender.remoteState.customData;
    if (remoteLiveDetails && remoteLiveDetails.live) {
      return remoteLiveDetails.live.startOfBufferTimestamp;
    }

    if (mediaInfo && mediaInfo.startAbsoluteTime) {
      return mediaInfo.startAbsoluteTime * 1000;
    }
    return 0;
  }

  selectSource(mediaItem: LudoMediaItem) {
    if (!this.castState.shouldSelectAdapter) {
      return null;
    }
    return mediaItem.sources.length && mediaItem.sources[0] || null;
  }

  canPlay(mediaItem: LudoMediaItem) {
    return this.selectSource(mediaItem) !== null;
  }

  playbackQuality() {}
  getPlaybackQuality() { return null; }
  setPlaybackQuality(quality: number) {}
  playbackQualities() { return []; }
  currentSource() {
    return this.source;
  }
}

function requiresReLoadOnChangedManifest(mediaItem: MediaItem, mediaInfo: MediaInfo | undefined) {
  if (!mediaInfo) {
    return false;
  }
  const customData: NrkMediaInfoCustomData | undefined = mediaInfo.customData as any;
  if (!customData) {
    return false;
  }
  if (mediaItem.id !== mediaInfo.contentId) {
    return false;
  }
  if (!customData.manifestName) {
    return false;
  }
  return customData.manifestName !== mediaItem.manifestName;
}

async function awaitCustomData(castSender: any): Promise<MediaInfo | undefined> {
  const remoteState = castSender.remoteState;
  if (remoteState.playerState !== 'PLAYING') {
    return undefined;
  }
  const customData: MediaInfo = remoteState.customData;
  if (customData) {
    return customData;
  }
  return new Promise((resolve) =>
    castSender.once(PROPERTIES.CUSTOM_DATA, (customData: MediaInfo) => resolve(customData))
  );
}
