import { format } from '../../../duration';
import { LudoEvents } from '@nrk/ludo-core';
import UI_EVENTS from '../../../ui/events';
import SCRUBBER_EVENTS from '../scrubberEvents';
import eventToggler from '../../../ui/eventToggler';
import { ScrubberContext } from '../index';

const VISIBLE_CLASS_NAME = 'ludo-scrubber__time--on';

export default ({ player, ui, currentTimeElement, pointerTimeElement, scrubberEvents, progressBarElement }: ScrubberContext) => {

  let isLive = false;
  let movePosition: number | null = null;

  function slideElement(timeElement: HTMLElement, { onPositioned = () => {} } = {}) {
    let halfWidth = 0;
    let lastLeftPosition: number | null = null;
    let isVisible = false;
    let time = 0;
    let text = '';

    function getLeftPosition() {
      const progressBarWidth = progressBarElement.offsetWidth || 0;

      if (!halfWidth && timeElement.offsetWidth) {
        halfWidth = timeElement.offsetWidth / 2;
      }

      if (!halfWidth || !progressBarWidth) {
        return null;
      }

      const position = time / player.duration();

      let left = Math.round(position * progressBarWidth);
      left = Math.min(left, progressBarWidth - halfWidth);
      left = Math.max(left, halfWidth);

      return left - halfWidth;
    }

    function render() {

      if (!isVisible) {
        timeElement.classList.remove(VISIBLE_CLASS_NAME);
        return;
      }

      timeElement.textContent = text ? text : format(isLive ? player.convertTimeToLiveTime(time) : time);

      const leftPosition = getLeftPosition();
      const hasLeftPosition = leftPosition !== null;

      if (hasLeftPosition && leftPosition !== lastLeftPosition) {
        timeElement.style.left = `${leftPosition}px`;
        onPositioned();
      }

      lastLeftPosition = leftPosition;
      timeElement.classList.toggle(VISIBLE_CLASS_NAME, hasLeftPosition);
    }

    function visible(visibility: boolean) {
      isVisible = visibility;
    }

    function setTime(newTime: number) {
      time = newTime;
    }

    function setText(newText: string) {
      text = newText;
    }

    function resetWidth() {
      halfWidth = 0;
    }

    return {
      render,
      visible,
      setTime,
      setText,
      resetWidth
    };
  }

  const currentTimeSlider = slideElement(currentTimeElement, {
    onPositioned: () => scrubberEvents.emit(SCRUBBER_EVENTS.SLIDING_TIME_POSITIONED)
  });
  const pointerTimeSlider = slideElement(pointerTimeElement);
  const slidingElements = [
    currentTimeSlider,
    pointerTimeSlider
  ];

  function render() {
    slidingElements.forEach((e) => e.render());
  }

  function updatePointerTime() {
    if (movePosition !== null) {
      pointerTimeSlider.setTime(movePosition * player.duration());
      pointerTimeSlider.visible(true);
    } else {
      pointerTimeSlider.visible(false);
    }
    pointerTimeSlider.render();
  }

  function timeUpdated() {
    currentTimeSlider.setTime(player.currentTime());
    currentTimeSlider.visible(true);
    currentTimeSlider.render();

    updatePointerTime();
  }

  function moveHandler(position: number) {
    movePosition = position;
    updatePointerTime();
  }

  function moveEndHandler() {
    movePosition = null;
    pointerTimeSlider.visible(false);
    pointerTimeSlider.render();
  }

  function labelChanged(text: string) {
    pointerTimeSlider.setText(text);
    pointerTimeSlider.resetWidth();
    pointerTimeSlider.render();
  }

  const toggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED]: timeUpdated,
    [SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED]: timeUpdated,
    [SCRUBBER_EVENTS.PIXEL_PROGRESS]: timeUpdated,
    [SCRUBBER_EVENTS.MOVE]: moveHandler,
    [SCRUBBER_EVENTS.MOVEEND]: moveEndHandler,
    [SCRUBBER_EVENTS.LABEL_CHANGED]: labelChanged
  });

  function reset() {
    const current = player.current();
    isLive = !!current && current.isLive;
  }

  player.on(LudoEvents.LOADED, reset);
  ui.on(UI_EVENTS.CONTROLBARVISIBLE, () => {
    toggler.on();
    render();
  });
  ui.on(UI_EVENTS.CONTROLBARHIDDEN, toggler.off);
  ui.on(UI_EVENTS.PLAYER_SIZE_CHANGED, render);
};
