import { LudoEvents } from '@nrk/ludo-core';
import UI_EVENTS from '../../../ui/events';
import eventToggler from '../../../ui/eventToggler';
import SCRUBBER_EVENTS from '../scrubberEvents';
import { ScrubberContext } from '../index';
import { isControlbarFullyVisible } from '../isControlbarFullyVisible';

export default ({ player, ui, playheadElement, playedElement, scrubberEvents, scrubberElement, progressBarElement, zoneModel, clickZoneElement }: ScrubberContext) => {

  if ('ontouchstart' in window) {
    playheadElement.classList.add('ludo-playhead--touch');
  }

  let timeValue: number = 0;
  let dragValue: number | null = null;
  let seekValue: number | null = null;

  const stageElement = ui.element;

  function render() {
    const playedFraction = [seekValue, dragValue, timeValue].filter((n) => typeof n === 'number')[0] || 0;
    const playedPercentage = Math.min(playedFraction * 100, 100);

    playheadElement.style.left = `${playedPercentage}%`;

    if (dragValue === null && seekValue === null) {
      playedElement.style.width = `${playedPercentage}%`;
    }
  }
  function timeUpdated(value: number) {
    timeValue = value / player.duration();
    render();
  }

  const seekTimeToggler = eventToggler(ui, {

    [UI_EVENTS.SEEKTIMEUPDATE]: (value) => {
      seekValue = value / player.duration();
      playheadElement.classList.add('ludo-playhead--move');
      render();
    },

    [UI_EVENTS.SEEKTIMEENDED]: () => {
      player.once(LudoEvents.SEEKED, () => {
        seekValue = null;
        playheadElement.classList.remove('ludo-playhead--move');
        render();
      });
    }

  });

  let isDragging = false;
  let isCanceled = false;

  function startDrag(clickEvent: MouseEvent | TouchEvent) {
    if (!isControlbarFullyVisible(ui.element)) {
      return;
    }

    if (clickEvent.cancelable) {
      clickEvent.preventDefault();
    }
    isDragging = false;
    isCanceled = false;

    // Temporarily disable events outside of scrubber when dragging.
    stageElement.style.pointerEvents = 'none';

    playheadElement.classList.add('ludo-playhead--drag');
    scrubberEvents.emit(SCRUBBER_EVENTS.DRAGSTART);

    ui.emit(UI_EVENTS.BLOCKHIDECONTROLS);

    /* To cancel dragging the playhead on touch, we need to proxy drag() to
       manually do the work of mouseOut and mouseEnter. */
    function touchDrag(moveEvent: MouseEvent | TouchEvent) {
      const clientX = 'touches' in moveEvent ? moveEvent.changedTouches[0].clientX : moveEvent.clientX;
      const clientY = 'touches' in moveEvent ? moveEvent.changedTouches[0].clientY : moveEvent.clientY;

      let outsideClickZone = false;

      // Click zone boundaries:
      const clickZoneBoundary = clickZoneElement.getBoundingClientRect();
      const top = clickZoneBoundary.top;
      const bottom = clickZoneBoundary.bottom;
      const left = clickZoneBoundary.left;
      const right = clickZoneBoundary.right;

      if (clientY < top || clientY > bottom || clientX < left || clientX > right) {
        outsideClickZone = true;
      }

      if (outsideClickZone) {
        cancelDrag(moveEvent);
      } else if (isCanceled && !outsideClickZone) {
        undoCancelDrag(moveEvent);
      }

      drag(moveEvent);
    }

    function drag(moveEvent: MouseEvent | TouchEvent) {
      if (isCanceled) {
        return;
      }

      if (clickEvent.cancelable) {
        moveEvent.preventDefault();
      }
      isDragging = true;

      const clientX = 'touches' in moveEvent ? moveEvent.changedTouches[0].clientX : moveEvent.clientX;
      const width = progressBarElement.offsetWidth;

      const scrubberLeftPos = scrubberElement.getBoundingClientRect().left;
      let left = clientX - scrubberLeftPos;
      left = Math.max(left, 0);
      left = Math.min(left, width);

      const point = zoneModel.findPoint(left);
      let position;
      if (point) {
        position = point.positionPx / width;
      } else {
        position = left / width;
      }
      dragValue = position;
      scrubberEvents.emit(SCRUBBER_EVENTS.DRAG, position, {
        left,
        width
      });

      render();
    }

    function stopDrag(event: Event) {
      event.stopPropagation();
      event.preventDefault();

      ui.emit(UI_EVENTS.ALLOWHIDECONTROLS);

      document.removeEventListener('mouseup', stopDrag);
      document.removeEventListener('mousemove', drag);
      document.removeEventListener('touchend', stopDrag);
      document.removeEventListener('touchmove', touchDrag);

      clickZoneElement.removeEventListener('mouseout', cancelDrag);
      clickZoneElement.removeEventListener('mouseenter', undoCancelDrag);

      stageElement.style.removeProperty('pointer-events');

      playheadElement.classList.remove('ludo-playhead--drag');
      scrubberEvents.emit(SCRUBBER_EVENTS.DRAGEND);

      if (!isDragging) {
        return;
      }
      isDragging = false;

      if (typeof dragValue !== 'number') {
        return;
      }
      player.once(LudoEvents.SEEKED, () => {
        dragValue = null;
        render();
      });
      scrubberEvents.emit(SCRUBBER_EVENTS.SEEKTO, dragValue);
    }

    function cancelDrag(event: Event) {
      if (event.cancelable) {
        event.preventDefault();
      }

      isCanceled = true;
      dragValue = null;

      scrubberEvents.emit(SCRUBBER_EVENTS.MOVEEND);

      render();
    }

    function undoCancelDrag(event: Event) {
      if (event.cancelable) {
        event.preventDefault();
      }

      isCanceled = false;

      render();
    }

    document.addEventListener('mouseup', stopDrag);
    document.addEventListener('mousemove', drag);
    document.addEventListener('touchend', stopDrag);
    document.addEventListener('touchmove', touchDrag);

    clickZoneElement.addEventListener('mouseout', cancelDrag);
    clickZoneElement.addEventListener('mouseenter', undoCancelDrag);

    drag(clickEvent);

    render();
  }

  player.on(LudoEvents.LOADED, () => {
    seekTimeToggler.on();
  });

  player.on(LudoEvents.ITEM_CHANGED, () => {
    seekTimeToggler.off();
  });

  function reflectCurrentTime() {
    timeUpdated(player.currentTime());
  }

  player.once(LudoEvents.LOADED, () => {
    clickZoneElement.addEventListener('mousedown', startDrag);
    clickZoneElement.addEventListener('touchstart', startDrag);

    scrubberEvents.on(SCRUBBER_EVENTS.TOUCHSTART, startDrag);
    scrubberEvents.on(SCRUBBER_EVENTS.PLAYED_TIME_UPDATED, reflectCurrentTime);
    scrubberEvents.on(SCRUBBER_EVENTS.PIXEL_PROGRESS, reflectCurrentTime);
  });

  player.on(LudoEvents.SEEKING, reflectCurrentTime);

};
