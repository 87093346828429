import { AppInsightsTracker } from './index';
import { LudoPlayerOptionsArgument } from '../../ludo/interfaces';
import { PartialMediaIdentifiers } from '@nrk/ludo-core';
import { NullApplicationInsightsTracker } from './NullApplicationInsightsTracker';
import { ApplicationInsightsTracker } from './ApplicationInsightsTracker';
import { getInstrumentationKeyForSite } from './getInstrumentationKey';
import { Site } from '../utilities/Site';

const appInsightsTrackers: { [playerId: string]: ApplicationInsightsTracker } = {};

export function getApplicationInsightsTracker(playerId: string, site: Site, referrer: string, programIds?: PartialMediaIdentifiers, options?: LudoPlayerOptionsArgument): AppInsightsTracker {
  const applicationInsights = options && options.analytics && options.analytics.applicationInsights;

  if (typeof applicationInsights === 'boolean' && !applicationInsights) {
    return new NullApplicationInsightsTracker();
  }

  const instrumentationKey = getInstrumentationKeyForSite(site);
  let appInsightsTracker = appInsightsTrackers[playerId];
  if (!appInsightsTracker) {
    appInsightsTracker = new ApplicationInsightsTracker(instrumentationKey, site, referrer);
    appInsightsTrackers[playerId] = appInsightsTracker;
    appInsightsTracker.setProgramIds(programIds);
    appInsightsTracker.setLudoOptions(options);
  }
  return appInsightsTracker;
}
