import CastEvents from '../nrkCastSender/CastEvents';
import EventEmitter from 'eventemitter3';
import { LudoEvents } from '@nrk/ludo-core';
import CastStateEvents from './CastStateEvents';
import { ADAPTER_NAME } from './';
import logger from '../logger';
import { ExtendedLudo } from '../../ludo/interfaces';

export default ({
  castSender,
  emitter = new EventEmitter(),
  log = logger()
}: any) => {

  const { remoteState } = castSender;

  let boundToPlayer = false;
  let currentRemoteId: string | null = null;
  let currentLocalId: string | null = null;
  let previousRemoteId: string | null = null;
  let previousLocalId: string | null = null;
  let isCurrentMedia = false;
  let connectedEmitted = false;
  let connected = false;
  let castAdaptersCurrentTime = 0;
  let previousAdaptersCurrentTime = 0;
  let isCastPlayerAdapterSet = false;
  let adapterPrepared = false;
  let isInitialPlay = false;
  let isMounted = false;
  let hasPlaybackBeenStarted = false;

  function resolveMediaLoaded() {
    if (!currentLocalId || !currentRemoteId) {
      return;
    }

    if (previousRemoteId === currentRemoteId && previousLocalId === currentLocalId) {
      return;
    }

    isCurrentMedia = currentRemoteId === currentLocalId;

    emitter.emit(CastStateEvents.MEDIA_LOADED);
    emitter.emit(isCurrentMedia ? CastStateEvents.CURRENT_MEDIA_LOADED : CastStateEvents.OTHER_MEDIA_LOADED);
  }

  function resolveConnected() {
    if (connectedEmitted || !connected || !currentLocalId) {
      return;
    }

    emitter.emit(CastStateEvents.CONNECTED);
    connectedEmitted = true;
  }

  function resolveMediaDisconnected() {
    if (!previousRemoteId || currentRemoteId) {
      return;
    }
    const isUnloadingCurrent = previousRemoteId === currentLocalId;
    setTimeout(() => {
      emitter.emit(CastStateEvents.MEDIA_DISCONNECTED);
      emitter.emit(isUnloadingCurrent ? CastStateEvents.CURRENT_MEDIA_DISCONNECTED : CastStateEvents.OTHER_MEDIA_DISCONNECTED);
    }, 100);
  }

  function setRemoteMedia(mediaInfo: any) {
    currentRemoteId = (mediaInfo || {}).contentId;
    resolveMediaDisconnected();
    resolveMediaLoaded();
    previousRemoteId = currentRemoteId;
  }

  function setLocalMedia(mediaItem: any) {
    currentLocalId = (mediaItem || {}).id;
    resolveConnected();
    resolveMediaLoaded();
    previousLocalId = currentLocalId;
  }

  castSender.on(CastEvents.APP_CONNECTED, () => {
    connected = true;
    resolveConnected();
  });

  castSender.on(CastEvents.APP_DISCONNECTED, () => {
    isCurrentMedia = false;
    previousRemoteId = null;
    connected = false;
    connectedEmitted = false;
  });

  castSender.on(CastEvents.MEDIA_INFO, (mediaInfo: any) => {
    setRemoteMedia(mediaInfo);
  });

  castSender.on(CastEvents.IDLE, () => {
    isCurrentMedia = false;
    castAdaptersCurrentTime = 0;
    previousAdaptersCurrentTime = 0;
  });

  castSender.on(CastEvents.MEDIA_UNLOADED, () => {
    currentRemoteId = null;
  });

  function bindToPlayer(player: ExtendedLudo) {
    if (boundToPlayer) {
      return;
    }

    player.on(LudoEvents.PREPARE, () => {
      castAdaptersCurrentTime = 0;

      setLocalMedia(player.current());
    });

    player.on(LudoEvents.TIMEUPDATE, (time) => {
      if (!time) {
        return;
      }
      if (!isCastPlayerAdapterSet) {
        previousAdaptersCurrentTime = time;
        return;
      }
      castAdaptersCurrentTime = time;
    });

    player.on(LudoEvents.ADAPTER, () => {
      isCastPlayerAdapterSet = player.adapterName() === ADAPTER_NAME;
    });

    player.on(LudoEvents.ITEM_CHANGED, () => {
      previousLocalId = null;
      isCurrentMedia = false;
    });

    player.on(LudoEvents.LOADED, () => {
      hasPlaybackBeenStarted = true;
    });

    boundToPlayer = true;
  }

  return {
    bindToPlayer,
    on: emitter.on.bind(emitter),
    off: emitter.off.bind(emitter),
    emit: emitter.emit.bind(emitter),
    once: emitter.once.bind(emitter),
    get isCurrentMedia() {
      return isCurrentMedia;
    },
    get isLoaded() {
      return !!previousRemoteId;
    },
    get isCastPlayerAdapterSet() {
      return isCastPlayerAdapterSet;
    },
    get requireRemoteLoading() {
      return !isCurrentMedia;
    },
    get shouldSelectAdapter() {
      return remoteState.connected;
    },
    get castAdaptersCurrentTime() {
      return castAdaptersCurrentTime;
    },
    get previousAdaptersCurrentTime() {
      return previousAdaptersCurrentTime;
    },
    get adapterPrepared() {
      return adapterPrepared;
    },
    set adapterPrepared(value) {
      adapterPrepared = value;
    },
    get isInitialPlay() {
      return isInitialPlay;
    },
    set isInitialPlay(value) {
      isInitialPlay = value;
    },
    get isMounted() {
      return isMounted;
    },
    set isMounted(value) {
      isMounted = value;
    },
    get hasPlaybackBeenStarted() {
      return hasPlaybackBeenStarted;
    },
    get requireInitialPlay() {
      if (!adapterPrepared) {
        return false;
      }
      if (isCurrentMedia) {
        return true;
      }
      return !previousRemoteId && hasPlaybackBeenStarted;
    }
  };
};
