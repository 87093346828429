import { nrkMediaNext } from '@nrk/core-icons';
import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  const button = ui.element.querySelector<HTMLButtonElement>('button[name="next"]')!;
  button.innerHTML = nrkMediaNext;
  button.removeAttribute('hidden');

  return (player: ExtendedLudo) => {
    button.addEventListener('click', () => player.next());
    player.on(LudoEvents.PREPARED, () => {
      const show = player.playlist().length > 1;
      button[show ? 'removeAttribute' : 'setAttribute']('hidden', '');
    });
  };
};
