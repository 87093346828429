import { nrkMediaPlay } from '@nrk/core-icons';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';

const className = 'ludo-audio__start';

export default () => {
  const button = document.createElement('button');
  button.className = `${className} ${className}--clickable`;
  button.type = 'button';
  button.tabIndex = 0;
  button.innerHTML = nrkMediaPlay;

  function bind(player: ExtendedLudo) {
    const onClick = (event: Event) => {
      event.stopPropagation();

      // The flash element has to be visible to be playable, so we need to display the ui
      player.ui.element.style.display = 'block';
      const playButton = player.ui.element.querySelector<HTMLButtonElement>('.ludo-bar__button--playpause');
      if (playButton) {
        playButton.click();
      }
    };

    player.once(LudoEvents.PLAYING, () => {
      button.tabIndex = -1;
      button.innerHTML = '<svg viewBox="0 0 15 15"><path d="M12.5 5c.3 0 .5.2.5.5v5a.5.5 0 0 1-1 0v-5c0-.3.2-.5.5-.5zm-10-.5c.3 0 .5.2.5.5v5a.5.5 0 0 1-1 0V5c0-.3.2-.5.5-.5zm6-3.5c.3 0 .5.2.5.5v12a.5.5 0 0 1-1 0v-12c0-.3.2-.5.5-.5zm-2 3c.3 0 .5.2.5.5v6a.5.5 0 0 1-1 0v-6c0-.3.2-.5.5-.5zm-2-2.5c.3 0 .5.2.5.5v11a.5.5 0 0 1-1 0V2c0-.3.2-.5.5-.5zm6 1.5c.3 0 .5.2.5.5v9a.5.5 0 0 1-1 0v-9c0-.3.2-.5.5-.5zm4 3c.3 0 .5.2.5.5v2a.5.5 0 0 1-1 0v-2c0-.3.2-.5.5-.5zM.5 6c.3 0 .5.2.5.5v2a.5.5 0 0 1-1 0v-2c0-.3.2-.5.5-.5z" fill-rule="evenodd"/></svg>';

      button.classList.add(`${className}--active`);
      button.classList.remove(`${className}--clickable`);

      const stageElement = player.get(LudoOptions.STAGE_ELEMENT);
      stageElement.tabIndex = -1;
      const playpauseButton = stageElement.querySelector('.ludo-bar__button--playpause');
      if (playpauseButton) {
        playpauseButton.focus();
      }

      button.removeEventListener('click', onClick);
    });

    button.addEventListener('click', onClick);

    player.on(LudoEvents.PREPARED, () => {
      const current = player.current()!;
      const text = `Spill av ${current.title}`;
      button.setAttribute('title', text);
      button.setAttribute('aria-label', text);
    });
  }

  return {
    buttonElement: button,
    bind
  };
};
