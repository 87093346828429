/**
 * Call downloadAndSetup to download full ApplicationInsights script from CDN and initialize it with instrumentation key
 * Ref. https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
 */
import { AppInsights } from 'applicationinsights-js';
import _get from 'lodash/get';

type AppInsightsConfig = typeof AppInsights.config;

export function bootstrapApplicationInsights(instrumentationKey: string, options: Partial<AppInsightsConfig> = {}) {

  const config: AppInsightsConfig = {
    instrumentationKey,
    // disableTelemetry: true,
    // verboseLogging: true,
    // enableDebug: true,
    disableExceptionTracking: true,
    disableAjaxTracking: true,
    maxBatchSizeInBytes: 100,
    maxBatchInterval: 1000,
    enableSessionStorageBuffer: false,
    isStorageUseDisabled: true,
    isRetryDisabled: true,
    ...options
  };

  const LoadedAppInsights: any = _get(window, 'Microsoft.ApplicationInsights.Initialization');
  if (LoadedAppInsights) {
    return new LoadedAppInsights({ config }).loadAppInsights();
  }

  const { AppInsights } = require('applicationinsights-js');

  if (AppInsights.downloadAndSetup) {
    AppInsights.downloadAndSetup(config);
  }

  return AppInsights;
}
