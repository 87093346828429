import nrkCastSender from '../cast/nrkCastSender';
import posterCastButton from '../cast/buttons/posterCastButton';
import controlBarCastButton from '../cast/buttons/controlBarCastButton';
import LudoCastPlayerAdapter from '../cast/LudoCastPlayerAdapter';
import castStateResolver from '../cast/LudoCastPlayerAdapter/castStateResolver';
import castOverlay from '../cast/overlay';
import persistentControls from '../cast/persistentControls';
import { isChromecastSupported } from '../cast/features';
import { PLAYER_TYPES } from './configuration';
import { LudoPlayerOptions } from './interfaces';
import { LudoUIType } from '../ui/ui/LudoUI';

export interface ChromecastArgument {
  ui: LudoUIType;
  options: LudoPlayerOptions;
  use: ((...args: any[]) => any)[];
  adapters: any[];
}

export default ({
  ui,
  options,
  use,
  adapters
}: ChromecastArgument) => {

  const chromecastOptions = options.chromecast;
  const features = options.features;

  if (!features.chromecast) {
    return;
  }

  if (!chromecastOptions.enabled) {
    return;
  }

  if (!isChromecastSupported()) {
    return;
  }

  const castSender = nrkCastSender(chromecastOptions);
  const castState = castStateResolver({castSender});

  use.push(controlBarCastButton({ castSender, ui }));

  const isVideo = options.playerType !== PLAYER_TYPES.audio;
  if (isVideo) {
    use.push(posterCastButton({ castSender, ui, castState }));
    use.push(castOverlay({ castSender, ui, castState }));
  }

  adapters.unshift(new LudoCastPlayerAdapter({ castSender, ui, castState }));

  persistentControls({ ...chromecastOptions, castSender, castState });

  ui.on('chromecast-console', () => {
    castSender.showRemoteConsole();
  });

  ui.on('chromecast-debug', () => {
    castSender.debugReceiver();
  });
};
