import { parseWebVTT } from './parseWebVTT';
import debug from 'bows';

const log = debug('ludo:captions');

function fetchUrl(url: string): Promise<string> {
  const headers = new Headers();
  const options: RequestInit = {
    method: 'GET',
    headers,
    mode: 'cors',
    cache: 'default'
  };

  return fetch(url, options)
    .then((response) => response.text())
    .catch((reason) => {
      log(`failed to load subtitles from ${url}, reason: ${reason}`);
      return '';
    });
}

export function fetchSubtitles(src: string) {
  return fetchUrl(src)
    .then((data) => parseWebVTT(data));
}
