import options from '../../../ui/options';
import events from '../../../ui/events';
import debug from 'bows';
import { classToggle } from '../../../dom';
import { LudoUIType } from '../../../ui/LudoUI';
import { Cue } from '../cue/Cue';
import { TextTrackRenderer } from '../TextTrackRenderer';
import { SubtitleState } from '../subtitles';

const log = debug('ludo:captions');

const ALIGN_HORIZONTALLY = false;
const PERCENTAGE_TEST_REGEX = /^\d*(\.\d+)?%$/;
const CAPTIONS_HIDDEN_CLASSNAME = 'ludo-captions--hidden';

export class HtmlSubtitles implements TextTrackRenderer {
  ui: LudoUIType;
  private ludoSubtitlesElement: HTMLElement;
  private ludoCaptionsElement: HTMLElement;

  constructor(ui: LudoUIType) {
    require('../captions.styl');

    this.ui = ui;

    this.ludoSubtitlesElement = this.ui.element.querySelector<HTMLElement>('.ludo-layout__row--captions')!;

    this.ludoCaptionsElement = document.createElement('div');
    this.ludoCaptionsElement.className = 'ludo-captions';
    this.ludoSubtitlesElement.appendChild(this.ludoCaptionsElement);

    ui.on(events.OPTIONCHANGED, (option, value) => {
      if (option === options.SUBTITLES_DISPLAYED) {
        const display = typeof value === 'boolean' ? value : true;
        if (display) {
          this.toggle(true);
        } else {
          this.toggle(false);
        }
      } else if (option === options.PLAYER_DIMENSIONS) {
        this.updateFontSize();
      }
    });
  }

  toggle(state: boolean) {
    state ? this.toggleHtmlSubtitles(true) : this.toggleHtmlSubtitles(false);
  }

  reset() {
    this.clearLines();
  }

  setCue(cue?: Cue | undefined) {
    if (!cue) {
      this.ludoCaptionsElement.innerHTML = '';
      return;
    }

    const { config = {} } = cue;
    const { line, align } = config;
    logCue(cue);

    const { top, bottom } = getVerticalAlignmentByLine(line);
    this.ludoCaptionsElement.style.top = top || '';
    this.ludoCaptionsElement.style.bottom = bottom || '';

    if (ALIGN_HORIZONTALLY) {
      this.ludoCaptionsElement.style.removeProperty('text-align');

      if (align === 'end') {
        this.ludoCaptionsElement.style.textAlign = 'right';
      } else if (align === 'middle') {
        this.ludoCaptionsElement.style.textAlign = 'center';
      }
    }

    this.renderLines(cue.lines);
  }

  setSubtitles(subtitles: SubtitleState[]) {
    this.updateFontSize();
  }

  private clearLines() {
    while (this.ludoCaptionsElement.firstChild) {
      this.ludoCaptionsElement.removeChild(this.ludoCaptionsElement.firstChild);
    }
  }

  private renderLines(lines: string[]) {
    this.clearLines();

    lines.forEach((line) => {
      const formatted = formatText(line);
      const lineElement = document.createElement('div');
      lineElement.className = classNames.line;
      const innerElement = document.createElement('span');
      lineElement.appendChild(innerElement);
      innerElement.className = classNames.inner;
      innerElement.innerHTML = formatted;
      this.ludoCaptionsElement.appendChild(lineElement);
    });
  }

  private updateFontSize() {
    const dimensions = this.ui.get(options.PLAYER_DIMENSIONS);
    if (dimensions) {
      const scale = this.ui.get(options.BREAKPOINT) === 'small' ? 2 : 4 / 3;
      this.ludoCaptionsElement.style.fontSize = dimensions.getRemFontSize({ scale });
    }
  }

  private toggleHtmlSubtitles(enabled: boolean) {
    classToggle(this.ludoCaptionsElement, CAPTIONS_HIDDEN_CLASSNAME, !enabled);
    this.updateFontSize();
  }
}

const classNamePrefix = 'ludo-captions__';

const classNames = {
  bold: `${classNamePrefix}bold`,
  italic: `${classNamePrefix}italic`,
  underline: `${classNamePrefix}underline`,
  color: `${classNamePrefix}color`,
  line: `${classNamePrefix}line`,
  inner: `${classNamePrefix}line-inner`
};

function formatText(line: string) {
  return line
    .replace(/(\r|\n)$/g, ' $&')
    .replace(/<b>/g, `<span class="${classNames.bold}">`)
    .replace(/<i>/g, `<span class="${classNames.italic}">`)
    .replace(/<u>/g, `<span class="${classNames.underline}">`)
    .replace(/<c\.(.*?)>/g, `<span class="${classNames.color}-${'$1'}">`)
    .replace(/<\/b>|<\/i>|<\/u>|<\/c>/g, '</span>');
}

function logCue(cue: Cue) {
  const config: any = cue.config;
  if (!config) {
    return;
  }
  const configdump = Object.keys(config).reduce((o: any, k) => {
    if (config[k]) {
      o.push(` ${k}: ${config[k]}`);
    }
    return o;
  }, []);

  log(`${cue.start} --> ${cue.end}: ${cue.lines.join('').replace(/<[^>]+>/g, '').replace(/\s+/g, ' ')}${configdump}`);
}

// Ref https://www.w3.org/TR/webvtt1/#webvtt-line-cue-setting
function getVerticalAlignmentByLine(line: string = '') {
  if (!PERCENTAGE_TEST_REGEX.test(line)) {
    return { bottom: '5%' };
  }
  const linePercentage = parseInt(line, 10);
  if (linePercentage > 50) {
    return { bottom: `${100 - linePercentage}%` };
  }
  return { top: line };
}
