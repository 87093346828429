import { removeChild } from '../ui/dom';

export interface PosterSetupArgument {
  stageElement: HTMLElement;
  initialPoster: HTMLImageElement | null;
  initialPlayer: HTMLMediaElement | null;
  posterElement: HTMLElement;
}

export default ({ stageElement, initialPoster, initialPlayer, posterElement }: PosterSetupArgument) => {
  if (initialPlayer) {
    stageElement.insertBefore(initialPlayer, stageElement.firstChild);
  }

  if (initialPoster && initialPlayer && initialPlayer instanceof HTMLVideoElement) {
    initialPlayer.poster = initialPoster.src;
  }

  if (initialPoster) {
    const existingPoster = posterElement.querySelector('img');
    if (existingPoster) {
      removeChild(initialPoster);
      removeChild(existingPoster);
      initialPoster.classList.add('ludo-poster__image');
      posterElement.insertBefore(initialPoster, posterElement.firstChild);
    }
  }
};
