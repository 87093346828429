import { PlayedSequenceObserver } from './PlayedSequenceObserver';

export class LivePlayedSequenceObserver implements PlayedSequenceObserver {
  destroy() {
  }

  getDistinctPlayedDuration() {
    return 0;
  }
}
