import { LudoEvents } from '@nrk/ludo-core';
import SCRUBBER_EVENTS from '../scrubberEvents';
import UI_EVENTS from '../../../ui/events';
import eventToggler from '../../../ui/eventToggler';
import { removeChild } from '../../../dom';
import { ScrubberContext } from '../index';

function createBufferElement() {
  const element = document.createElement('div');
  element.className = 'ludo-scrubber__buffered';
  return element;
}

function parsePercentage(value: number) {
  return (Math.min(Math.max(value, 0), 100)).toFixed(2);
}

export default ({ player, ui, progressBarElement, scrubberEvents }: ScrubberContext) => {

  player.once(LudoEvents.LOADED, () => {
    let buffered: HTMLElement[] = [];

    function render() {
      const duration = player.duration();
      const bufferedRanges = player.buffered();
      const bufferedSize = bufferedRanges.length;

      if (buffered.length > bufferedSize) {

        for (let i = bufferedSize; i < buffered.length; i++) {
          removeChild(buffered[i]);
        }

        buffered = buffered.slice(0, bufferedSize);

      } else if (buffered.length < bufferedSize) {
        do {
          const bufferElement = createBufferElement();
          progressBarElement.appendChild(bufferElement);
          buffered.push(bufferElement);
        } while (buffered.length < bufferedSize);
      }

      function cropBufferRange(val: number) {
        return Math.max(Math.min(val, duration), 0);
      }

      for (let i = 0; i < buffered.length; i++) {
        const start = cropBufferRange(bufferedRanges.start(i));
        const end = cropBufferRange(bufferedRanges.end(i));

        buffered[i].style.left = `${parsePercentage(start / duration * 100)}%`;
        buffered[i].style.width = `${parsePercentage((end - start) / duration * 100)}%`;
      }
    }

    const toggler = eventToggler(scrubberEvents, {
      [SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED]: render,
      [SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED]: render
    });
    ui.on(UI_EVENTS.CONTROLBARVISIBLE, () => {
      toggler.on();
      render();
    });
    ui.on(UI_EVENTS.CONTROLBARHIDDEN, toggler.off);

  });

};
