import { LudoUIType } from '../../ui/LudoUI';

export const buildElement = (ui: LudoUIType) => {
  const element = document.createElement('pre');
  element.className = 'ludo-debug-overlay';

  function show() {
    element.style.display = 'block';
  }

  function hide() {
    element.style.display = 'none';
  }

  function content(obj: { [key: string]: any; }) {
    const str = Object.keys(obj).map((key) => {
      return `${key}: ${obj[key]}`;
    }).sort().join('\n');
    element.innerHTML = str;
  }

  function isVisible() {
    return element.style.display !== 'none';
  }

  hide();
  ui.insert(element);

  return {
    show,
    hide,
    isVisible,
    content
  };
};
