import { LudoOptions } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { MediaItem } from '../../../nrk/mediaItem/MediaItem';
import {
  CURRENT_SUBTITLES,
  getAvailableSubtitles,
  getSubtitlesOfMediaItem,
  hasMultipleSubtitlesSupport,
  SubtitleState
} from '../subtitles/subtitles';
import { getSubtitlesLabel } from './getSubtitlesLabel';
import { SubtitlesID } from '../subtitles/SubtitlesID';

export function getCaptions(subtitles: SubtitleState[] = [], supportMultipleSubtitles = false): AccessibilityOption[] {
  if (!subtitles.length) {
    return [];
  }

  const captionsList: AccessibilityOption[] = subtitles.map((subtitle) => {
    return {
      label: getSubtitlesLabel(subtitle, supportMultipleSubtitles),
      id: `sub_${subtitle.srclang}_${subtitle.type}`,
      value: false.toString(),
      checked: subtitle.visible
    };
  });

  const hasSelectedSubtitles = !!subtitles.filter((sub) => sub.visible).length;
  const captionsOff: AccessibilityOption = {
    label: 'Av',
    id: 'sub_off',
    value: true.toString(),
    checked: !hasSelectedSubtitles
  };

  return [captionsOff, ...captionsList];
}

export function getStreamState(player: ExtendedLudo): StreamState {
  const mediaItem = player.current() as MediaItem;
  const currentSubtitles: SubtitlesID | undefined = player.get(CURRENT_SUBTITLES);
  const supportMultipleSubtitles = hasMultipleSubtitlesSupport(player);
  const subtitlesEnabled = player.get(LudoOptions.SUBTITLES);
  const availableSubtitles = getAvailableSubtitles(getSubtitlesOfMediaItem(mediaItem), {
    supportMultipleSubtitles,
    subtitlesEnabled,
    currentSubtitles
  });
  const hasVisibleSubtitles = availableSubtitles.filter((sub) => sub.visible).length > 0;

  return {
    isSubtitlesOn: hasVisibleSubtitles,
    subtitles: availableSubtitles,
    streams: mediaItem ? mediaItem.streams : [],
    manifestName: mediaItem ? mediaItem.manifestName : ''
  };
}

export interface AccessibilityOption {
  id: string;
  label: string;
  value: string;
  checked?: boolean;
  href?: string;
}

export interface StreamState {
  isSubtitlesOn: boolean;
  subtitles: SubtitleState[];
  streams: AccessibilityOption[];
  manifestName: string;
}
