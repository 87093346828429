import ControlBar from './controlbar';
import uiEvents from '../../ui/events';
import { LudoUIType } from '../../ui/LudoUI';

function toggleVisible(el: HTMLElement, enable: boolean) {
  if (enable && el.style.display === 'block'
    || !enable && el.style.display === 'none') {
    return;
  }

  el.style.display = enable ? 'block' : 'none';
}

const Controller = (ui: LudoUIType) => {
  const element = ui.element.querySelector<HTMLElement>('.ludo-controller')!;
  toggleVisible(element, false);

  const controlBar = ControlBar(ui);

  let toggleTimer: number | null = null;
  let forceVisible = false;
  let blockHide = false;

  function clearToggleTimer() {
    if (toggleTimer) {
      window.clearTimeout(toggleTimer);
      toggleTimer = null;
    }
  }

  function toggleOff(delay = 0) {
    clearToggleTimer();
    if (forceVisible || blockHide) {
      return;
    }
    toggleTimer = window.setTimeout(() => {
      controlBar.toggle(false);
      ui.emit(uiEvents.CONTROLLEROFF);
    }, delay);
  }

  function toggleOn(toggleOffOnIdle = true) {
    document.body.style.cursor = 'auto';
    clearToggleTimer();
    controlBar.toggle(true);

    ui.emit(uiEvents.CONTROLLERON, toggleOffOnIdle);
  }

  function toggle() {
    controlBar.isVisible() ? toggleOff() : toggleOn();
  }

  ui.on(uiEvents.BLOCKHIDECONTROLS, () => {
    blockHide = true;
    controlBar.blockHide();
  });

  ui.on(uiEvents.ALLOWHIDECONTROLS, () => {
    blockHide = false;
    controlBar.allowHide();
  });

  return {
    toggleOff,
    toggleOn,
    toggle,
    enable() {
      toggleVisible(element, true);
    },
    disable() {
      toggleVisible(element, false);
    },
    pinned() {
      forceVisible = true;
      toggleVisible(element, true);
      toggleOn();
    },
    unpinned() {
      forceVisible = false;
    },
    element,
    controlBar
  };
};

export default Controller;

export type ControllerType = ReturnType<typeof Controller>;
