import logging from 'bows';
import { LudoEvents } from '@nrk/ludo-core';
import { LiveEPGTracker } from './LiveEPGTracker';
import { EPGLoader } from './EPGLoader';
import { ProgramList } from './ProgramList';
import { EPGLiveBufferLoader } from '../APIClient/psapi/EPGLiveBufferLoader';

type APIClient = import('../APIClient/types').APIClient;
type ExtendedLudo = import('../../ludo/interfaces').ExtendedLudo;

const log = logging('ludo:liveepg');

export function LiveEpg(apiClient: APIClient) {
  return function init(player: ExtendedLudo) {

    let loader: EPGLoader | null;
    let tracker: LiveEPGTracker | null;

    function stop() {
      if (tracker) {
        tracker.destroy();
        tracker = null;
      }
      if (loader) {
        loader.destroy();
        loader = null;
      }
    }

    function start() {
      stop();

      const current = player.current();
      if (!current || !current.isChannel) {
        return;
      }

      const epgPath = current.epgLiveBufferPath ?? `/channels/${current.id}/epglivebuffer`;
      log(`Using EPG path (${current.epgLiveBufferPath !== undefined ? 'HAL link' : 'constructed'}): ${epgPath}`);

      const programList = new ProgramList();
      const apiLoader = new EPGLiveBufferLoader(apiClient, epgPath);
      tracker = new LiveEPGTracker(player, programList);
      loader = new EPGLoader(player, apiLoader, programList);
    }

    player.on(LudoEvents.ITEM_CHANGED, stop);
    player.on(LudoEvents.LOADED, start);
  };
}
