import { Sequence } from './PlayedSequenceObserver';

function getDuration(start: number, end: number) {
  return Math.max(0, end - start);
}

export function summarizeDuration(sequences: Sequence[]) {
  const durations = sequences.map(({ start, end }) => getDuration(start, end));
  return durations.reduce((agg, duration) => agg + duration, 0);
}

function isPointInside(point: number, limit: Sequence) {
  return point >= limit.start && point <= limit.end;
}

export function summarizeDistinctDuration(sequences: Sequence[]) {
  const sorted = sequences.concat()
    .sort((a, b) => a.end - b.end)
    .sort((a, b) => a.start - b.start);

  let current: Sequence | undefined;
  let merged: Sequence | undefined;
  const flattened: Sequence[] = [];

  do {
    current = sorted.shift();

    if (!current) {
      continue;
    }

    const { start, end } = current;

    if (!merged || !isPointInside(current.start, merged)) {
      merged = { start, end };
      flattened.push(merged);
    }

    merged.end = Math.max(current.end, merged.end);
  } while (current);

  return summarizeDuration(flattened);
}
