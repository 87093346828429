import seekToHandler from './seekToHandler';
import { IS_PIP_SUPPORTED, LudoEvents, LudoOptions } from '@nrk/ludo-core';
import LudoUIOptions from '../../ui/options';
import playerMethods from '../../ui/playerMethods';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {

  return (player: ExtendedLudo) => {

    const stageElement = ui.element;
    const seekTo = seekToHandler(ui, player);

    const { playpause, adjustVolume } = playerMethods(player);

    function seekToPercent(percent: number) {
      seekTo({ percent });
    }

    function seekToAddSeconds(seconds: number) {
      seekTo({ seconds });
    }

    function onKeyDown(keyEvent: KeyboardEvent) {
      const keyCode = keyEvent.which;
      const metaKey = keyEvent.metaKey || keyEvent.ctrlKey || keyEvent.altKey || keyEvent.shiftKey;
      const videoElement = player.get(LudoOptions.VIDEO_ELEMENT);
      let actionTriggered = false;
      let keyboardNavigation = false;

      switch (keyCode) {
        case 9: // Tab
          // Turn button outline on and off when switching between keyboard and mouse navigation.
          if (!keyboardNavigation) {
            keyboardNavigation = true;
            stageElement.classList.add('ludo--keyboard-navigation');

            const switchToMouseNavigation = () => {
              keyboardNavigation = false;
              stageElement.classList.remove('ludo--keyboard-navigation');
              window.removeEventListener('mousedown', switchToMouseNavigation);
            };

            window.addEventListener('mousedown', switchToMouseNavigation);
          }

          break;
        // seek keys
        case 37: // left
          seekToAddSeconds(metaKey ? -60 : -10);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        case 39: // right
          seekToAddSeconds(metaKey ? 60 : 10);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        default:
          break;
      }

      if (metaKey) {
        return;
      }

      switch (keyCode) {
        // play/pause keys
        case 13: // enter
        case 32: // space
          if (keyEvent.target === stageElement) {
            playpause();
            keyEvent.preventDefault();
            actionTriggered = true;
          }
          break;

        case 75: // k
          playpause();
          actionTriggered = true;
          break;

        // seek keys
        case 74: // j
          seekToAddSeconds(-10);
          actionTriggered = true;
          break;

        case 76: // l
          seekToAddSeconds(10);
          actionTriggered = true;
          break;

        // jump keys
        case 48: // 0
        case 49: // 1
        case 50: // 2
        case 51: // 3
        case 52: // 4
        case 53: // 5
        case 54: // 6
        case 55: // 7
        case 56: // 8
        case 57: // 9
          seekToPercent((keyCode - 48) / 10);
          actionTriggered = true;
          break;

        case 36: // home
          seekToPercent(0);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        case 35: // end
          seekToPercent(1);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        // volume keys
        case 38: // up
          adjustVolume(0.05);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        case 40: // down
          adjustVolume(-0.05);
          keyEvent.preventDefault();
          actionTriggered = true;
          break;

        // toggle keys
        case 70: // f
          if (ui.get(LudoUIOptions.FULLSCREEN_ENABLED)) {
            player.fullscreen();
            actionTriggered = true;
          }
          break;

        case 77: // m
          player.isMuted() ? player.unmute() : player.mute();
          actionTriggered = true;
          break;

        case 67: // c
          player.subtitles(!player.get(LudoOptions.SUBTITLES));
          actionTriggered = true;
          break;

        case 80: // p
          if (IS_PIP_SUPPORTED) {
            const presentationMode = videoElement.webkitPresentationMode;

            if (presentationMode === 'inline') {
              player.emit(LudoEvents.PIPON);
            } else {
              player.emit(LudoEvents.PIPOFF);
            }
            actionTriggered = true;
          }
          break;

        case 65: // a - aspect ratio
        case 90: { // z - zoom
        // { cover: 'Zoom', fill: 'Strekk', none: '1:1', contain: 'Normal' }
          const values = ['cover', 'contain'];
          const current = videoElement.style.objectFit;
          const nextIndex = (values.indexOf(`${current}`) + 1) % values.length;
          videoElement.style.objectFit = values[nextIndex];
          break;
        }

        default:
          break;
      }

      if (actionTriggered) {
        ui.showControls();
      }
    }

    player.on(LudoEvents.PREPARED, () => {

      function bindKeyEvents() {
        document.addEventListener('keydown', onKeyDown);
      }

      function unbindKeyEvents() {
        document.removeEventListener('keydown', onKeyDown);
      }

      stageElement.addEventListener('focus', bindKeyEvents, true);
      stageElement.addEventListener('blur', unbindKeyEvents, true);

    });

  };
};
