import overlay from './overlay';
import { LudoEvents } from '@nrk/ludo-core';
import NrkEvents from '../../../nrk/NrkEvents';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  return (player: ExtendedLudo) => {
    let ol: ReturnType<typeof overlay> | null;

    function cleanup() {
      if (ol) {
        ol.remove();
        ol = null;
      }
    }

    player.on(NrkEvents.COUNTDOWN_INITIALIZED, (waitTime, mediaItem) => {
      cleanup();
      ol = overlay(ui, mediaItem);
    });

    player.on(LudoEvents.PREPARE, cleanup);
  };
};
