import EventEmitter = require('eventemitter3');

function wrap(bind = () => {}, unbind = () => {}) {
  let isAttached = false;

  function on() {
    if (isAttached) {
      return;
    }
    bind();
    isAttached = true;
  }

  function off() {
    if (!isAttached) {
      return;
    }
    unbind();
    isAttached = false;
  }

  function toggle(switchState?: boolean) {
    const shouldAttach = typeof switchState === 'boolean' ? switchState : !isAttached;
    if (shouldAttach) {
      on();
    } else {
      off();
    }
  }

  return {
    on,
    off,
    toggle
  };
}

export default (emitter: EventEmitter, events: { [key: string]: (...args: any[]) => any; }) => {
  const eventNames = Object.keys(events);

  function forEach(func: 'on' | 'off') {
    eventNames.forEach((eventName) => {
      if (func === 'on') {
        emitter.on(eventName, events[eventName]);
      } else {
        emitter.off(eventName, events[eventName]);
      }
    });
  }

  return wrap(
    () => forEach('on'),
    () => forEach('off')
  );
};
