import { LudoUIType } from '../../../ui/LudoUI';
import { Cue } from '../cue/Cue';
import { TextTrackRenderer } from '../TextTrackRenderer';
import { SubtitleState } from '../subtitles';

export class AccessibilitySubtitles implements TextTrackRenderer {
  private ludoSubtitlesElement: HTMLElement;
  private enabled: boolean = true;
  private cue: Cue | undefined;

  constructor(ui: LudoUIType) {
    this.ludoSubtitlesElement = document.createElement('div');
    this.ludoSubtitlesElement.className = 'ludo--visually-hidden';
    this.ludoSubtitlesElement.setAttribute('aria-live', 'polite');

    ui.insert(this.ludoSubtitlesElement);
  }

  setCue(cue?: Cue) {
    this.cue = cue;
    this.render();
  }

  setSubtitles(subtitles: SubtitleState[]) {
    const visibleSubtitle = subtitles.filter((sub) => sub.visible)[0];
    this.setLanguage(visibleSubtitle && visibleSubtitle.srclang);
  }

  toggle(state: boolean) {
    this.enabled = state;
    this.render();
  }

  reset(): void {
    this.setCue();
  }

  private render() {
    const lines = this.cue && this.enabled ? this.cue.lines : [];
    this.ludoSubtitlesElement.textContent = removeFormatting(lines.map((l) => l.trim()).join(' '));
  }

  private setLanguage(lang?: string) {
    if (lang) {
      this.ludoSubtitlesElement.setAttribute('lang', lang);
    } else {
      this.ludoSubtitlesElement.removeAttribute('lang');
    }
  }
}

function removeFormatting(line: string) {
  return line
    .replace(/(\r|\n)$/g, '')
    .replace(/<b>/g, '')
    .replace(/<i>/g, '')
    .replace(/<u>/g, '')
    .replace(/<c\.(.*?)>/g, '')
    .replace(/<\/b>|<\/i>|<\/u>|<\/c>/g, '');
}
