import CastEvents from '../nrkCastSender/CastEvents';

export default ({ logger }: any) => {
  let castSender: any;
  let imgElem: HTMLElement;
  let linkElem: HTMLElement;
  let titleElem: HTMLElement;
  let castNameElem: HTMLElement;
  let getLinkBackUrl: (data: any) => string | null;

  function getFixedBaseUrl(baseUrl: string) {
    return `${baseUrl}${ '/?=&'.split('').indexOf(baseUrl[baseUrl.length - 1]) === -1 ? '/' : '' }`;
  }

  function linkBackUrlByProgramBaseUrl({
    programBaseUrl,
    liveBaseUrl
  }: any) {
    return function(data: any) {
      const {
        contentId: programId = '',
        streamType = ''
      } = data || {};

      if (!programId) {
        return null;
      }

      const baseUrl = streamType === 'LIVE' ? (liveBaseUrl || programBaseUrl) : programBaseUrl;
      return `${getFixedBaseUrl(baseUrl)}${programId}`;
    };
  }

  function updateMediaInfo(data: any) {
    if (!data) {
      return;
    }

    const { metadata = {} } = data || {};
    const { title = '' } = metadata;
    const { images = [] } = metadata;
    const { url: imgSrc = '' } = images[0] || {};

    const link = getLinkBackUrl(data);
    if (link) {
      linkElem.setAttribute('href', link);
    }

    imgElem.setAttribute('src', imgSrc);
    titleElem.textContent = title;
    titleElem.setAttribute('title', title);

    logger('Updated media info', data);
  }

  function updateCastDeviceName(deviceName: string) {
    if (deviceName) {
      const title = `Sender til ${deviceName}`;
      castNameElem.textContent = title;
      castNameElem.setAttribute('title', title);

      logger('Updated cast name');
    }
  }

  interface CreateProps {
    programBaseUrl: string;
    liveBaseUrl: string;
    getProgramUrl?: (data: any) => string;
  }

  return {
    create: (client: any, props: CreateProps) => {
      castSender = client;
      const {
        programBaseUrl,
        liveBaseUrl,
        getProgramUrl
      } = props;

      if (typeof getProgramUrl === 'function') {
        getLinkBackUrl = getProgramUrl;
      } else if (typeof programBaseUrl === 'string') {
        getLinkBackUrl = linkBackUrlByProgramBaseUrl({ programBaseUrl, liveBaseUrl });
      } else {
        getLinkBackUrl = () => null;
      }

      const mediaInfoElem = document.createElement('div');
      mediaInfoElem.className = 'ludo-persistent-controls--media-info-container';

      linkElem = document.createElement('a');
      linkElem.className = 'ludo-persistent-controls--program-link';

      imgElem = document.createElement('img');
      imgElem.setAttribute('aria-hidden', 'true');
      imgElem.setAttribute('alt', '');

      titleElem = document.createElement('div');
      titleElem.className = 'ludo-persistent-controls--program-title';

      castNameElem = document.createElement('div');
      castNameElem.className = 'ludo-persistent-controls--cast-name';

      updateMediaInfo(castSender.remoteState.mediaInfo);
      updateCastDeviceName(castSender.remoteState.castDeviceFriendlyName);

      linkElem.appendChild(imgElem);
      linkElem.appendChild(titleElem);
      linkElem.appendChild(castNameElem);
      mediaInfoElem.appendChild(linkElem);

      castSender.on(CastEvents.MEDIA_INFO, updateMediaInfo);
      castSender.on(CastEvents.DEVICE_NAME, updateCastDeviceName);

      return mediaInfoElem;
    },

    destroy: () => {
      castSender.off(CastEvents.MEDIA_INFO, updateMediaInfo);
      castSender.off(CastEvents.DEVICE_NAME, updateCastDeviceName);
    }
  };
};
