import { Subtitle } from '../../../nrk/mediaItem/MediaItem';
import { HAS_MULTIPLE_SUBTITLES_SUPPORT } from '../subtitles/subtitles';

export type SubtitleLabelArgument = Pick<Subtitle, 'type' | 'srclang' | 'label'>;

export function getSubtitlesLabel(subtitle: SubtitleLabelArgument, supportMultipleSubtitles = HAS_MULTIPLE_SUBTITLES_SUPPORT) {
  if (supportMultipleSubtitles === false) {
    return subtitle.srclang !== 'en' ? 'Norsk' : 'English';
  }

  if (subtitle.srclang === 'nb') {
    if (subtitle.type === 'nor') {
      return 'Norsk';
    }
    if (subtitle.type === 'mix') {
      return 'Norsk - på all tale';
    }
    if (subtitle.type === 'ttv') {
      return 'Norsk - på all tale';
    }
  }

  return subtitle.label;
}
