import { IS_ANDROID, IS_IOS, IS_LINUX, IS_NRKNO_APP, IS_WINDOWS_DESKTOP, IS_WINDOWS_PHONE } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';

export function contentNotSupported(player: ExtendedLudo, {
  ANDROID = IS_ANDROID,
  WINDOWS_PHONE = IS_WINDOWS_PHONE,
  WINDOWS_DESKTOP = IS_WINDOWS_DESKTOP,
  LINUX = IS_LINUX,
  IOS = IS_IOS,
  NRKNO_APP = IS_NRKNO_APP
} = {}) {
  const mediaItem = player.current();
  const isVideo = !!mediaItem && mediaItem.isVideo;
  let msg = '';

  if (NRKNO_APP) { // Check nrk.no app first
    msg = getInsideAppMessage(isVideo);
  } else if (WINDOWS_PHONE) { // Check Windows phone before other devices, as it identifies as everything.
    msg = getWindowsPhoneMessage(isVideo);
  } else if (IOS) {
    msg = getIOSMessage(isVideo);
  } else if (ANDROID) {
    msg = getAndroidMessage(isVideo);
  } else if (LINUX) { // Android is also Linux, so keep this after Android
    msg = getLinuxMessage(isVideo);
  }
  return msg || `Du må oppdatere nettleseren din eller bruke en annen nettleser for å ${isVideo ? 'se videoen' : 'høre lyden'}.`;
}

function getInsideAppMessage(isVideo: boolean) {
  return `Vi kan ikke spille av ${isVideo ? 'videoen' : 'lyden'} på enheten din.`;
}

function getWindowsPhoneMessage(isVideo: boolean) {
  return `Vi kan ikke spille av ${isVideo ? 'videoen' : 'lyden'} på enheten din.`;
}

function getIOSMessage(isVideo: boolean) {
  return `Du må oppgradere iOS for å ${isVideo ? 'se videoen' : 'høre lyden'}.`;
}

function getAndroidMessage(isVideo: boolean) {
  return `Du må oppdatere nettleseren din, bruke en annen nettleser eller laste ned appen vår for å ${isVideo ? 'se videoen' : 'høre lyden'}.`;
}

function getLinuxMessage(isVideo: boolean) {
  return `Du må installere codecs for mp4 for å ${isVideo ? 'se videoen' : 'høre lyden'}.`;
}
