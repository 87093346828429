/**
 * There are two systems for scaling the UI:
 *
 * - Breakpoints: Based on Ludo's size, a class representing the current
 *   breakpoint is set on Ludo's container.
 * - Continuously calculated: Based on Ludo's size, objects providing the
 *   current sizes and units are continuously shared through UI options and UI
 *   resize events. Listen on the PLAYER_SIZE_CHANGED event to receive the
 *   PlayerDimensions.
 */
import { classToggle } from '../dom';
import uiEvents from './events';
import uiOptions from './options';
import { LudoUIType } from './LudoUI';
import { ResizeSensor } from '../dom/resize';
import { PlayerDimensions } from './playerDimensions';

const BREAKPOINTS: { [name: string]: { className: string; maxWidth: number } } = {
  small: { className: 'ludo--small', maxWidth: 480 },
  medium: { className: 'ludo--medium', maxWidth: 768 },
  large: { className: 'ludo--large', maxWidth: 1024 },
  full: { className: 'ludo--full', maxWidth: Infinity }
};

export default (ui: LudoUIType) => {
  const container = ui.get(uiOptions.TARGET_ELEMENT);
  const barElement = container.querySelector<HTMLElement>('.ludo-bar')!;
  const observer = new MutationObserver(prioritizeButtons); // Yes, supported even in IE11

  /**
   * Whenever appropriate, get the player dimensions and share them.
   */
  function handleResizeEvent() {
    let size = container.getBoundingClientRect();

    // The container size above is normally good, but some browsers(?) give 0x0
    // in HTML5 fullscreen. Then, check the media element instead (doesn't apply to flash).
    if (size.width === 0 && size.height === 0) {
      const mediaElement = container.querySelector<HTMLElement>('.ludo-player');
      if (mediaElement) {
        size = mediaElement.getBoundingClientRect();
      }
    }

    const dimensions = new PlayerDimensions(size.width, size.height);
    ui.set(uiOptions.PLAYER_DIMENSIONS, dimensions);
    ui.emit(uiEvents.PLAYER_SIZE_CHANGED, dimensions);
  }

  /**
   * Update the breakpoint when the player dimensions change.
   */
  function updateBreakpoint({ width }: ClientRect) {
    const breakpointName = Object.keys(BREAKPOINTS).filter((name) => width <= BREAKPOINTS[name].maxWidth)[0];

    if (ui.get(uiOptions.BREAKPOINT) !== breakpointName) {
      prioritizeButtons();
      Object.keys(BREAKPOINTS).forEach((name) => {
        classToggle(container, BREAKPOINTS[name].className, name === breakpointName);
      });

      ui.set(uiOptions.BREAKPOINT, breakpointName);
      ui.emit(uiEvents.BREAKPOINT, breakpointName);
    }
  }

  function prioritizeButtons() {
    let nowWidth = 0;
    const maxWidth = barElement.offsetWidth;
    const isBarVisible = barElement.offsetWidth && barElement.offsetHeight;
    const hideElements = [].slice.call(container.querySelectorAll('[data-ludo-overflow]:not([hidden])'));

    if (isBarVisible) {
      // Remove all overflows
      hideElements.forEach((el: HTMLElement) => el.classList.remove('ludo--overflow'));

      // Sort by overflow priority and contitionally hide
      hideElements.sort((a: HTMLElement, b: HTMLElement) =>
        Number(a.getAttribute('data-ludo-overflow')) - Number(b.getAttribute('data-ludo-overflow'))
      ).forEach((el: HTMLElement) => {
        const style = getComputedStyle(el);
        const width = el.offsetWidth + parseFloat(style.marginLeft!) + parseFloat(style.marginRight!);

        nowWidth += width;
        el.classList[nowWidth > maxWidth ? 'add' : 'remove']('ludo--overflow');
      });
    }
  }

  ui.on(uiEvents.PLAYER_SIZE_CHANGED, updateBreakpoint);
  ui.on(uiEvents.PLAYER_SIZE_CHANGED, prioritizeButtons);
  ResizeSensor(ui.element, handleResizeEvent);
  handleResizeEvent();

  // Listen for `hidden` attribute change and add/remove elements
  observer.observe(barElement, {
    attributeFilter: ['hidden'],
    attributes: true,
    childList: true,
    subtree: true
  });
};
