import { TextTrackRenderer } from './TextTrackRenderer';
import { Cue } from './cue/Cue';
import { SubtitlesID } from './SubtitlesID';
import { SubtitleState } from './subtitles';

export class TextTrackRenderers implements TextTrackRenderer {
  private viewers: TextTrackRenderer[];

  constructor(viewers: TextTrackRenderer[]) {
    this.viewers = viewers;
  }

  toggle(state: boolean): void {
    this.viewers.forEach((viewer) => viewer.toggle(state));
  }

  reset(): void {
    this.viewers.forEach((viewer) => viewer.reset());
  }

  setCue(cue?: Cue) {
    this.viewers.forEach((viewer) => {
      if (viewer.setCue) {
        viewer.setCue(cue);
      }
    });
  }

  setSubtitles(subtitles: SubtitleState[] = []) {
    this.viewers.forEach((viewer) => {
      if (viewer.setSubtitles) {
        viewer.setSubtitles(subtitles);
      }
    });
  }

  chooseSubtitle(reference?: SubtitlesID) {
    this.viewers.forEach((viewer) => {
      if (viewer.chooseSubtitle) {
        viewer.chooseSubtitle(reference);
      }
    });
  }
}
