/**
 * Example usage:
 *
 * const client = new RetryClient('psapi.nrk.no');
 * const loader = new PlaybackMetadataLoader(client, 'nrk1');
 * const metadata = await loader.load();
 * const manifest = await loader.links.manifests.default.load();
 */
export * from './types';
export * from './RetryClient';
