import uiEvents from '../../ui/events';
import { LudoUIType } from '../../ui/LudoUI';
import { ExtendedLudo } from '../../../ludo/interfaces';

export default (ui: LudoUIType, player: ExtendedLudo) => {
  let timeoutId: number;
  let newTime: number | null = null;

  return function seekTo({ seconds, percent }: { seconds?: number, percent?: number }) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (newTime === null) {
      newTime = player.currentTime();
    }

    const duration = player.duration();

    if (typeof percent === 'number') {
      newTime = duration * percent;
    } else if (newTime && seconds) {
      newTime += seconds;
    }

    newTime = Math.min(newTime, duration);
    newTime = Math.max(newTime, 0);

    ui.emit(uiEvents.SEEKTIMEUPDATE, newTime);

    timeoutId = window.setTimeout(() => {
      player.seekTo(newTime!);
      newTime = null;
      ui.emit(uiEvents.SEEKTIMEENDED);
    }, 500);
  };
};
