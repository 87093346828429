import { nrkMediaPlay } from '@nrk/core-icons';
import playIcon from '../playIcon';
import playerMethods from '../../ui/playerMethods';
import { LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  const button = ui.element.querySelector<HTMLButtonElement>('.ludo-bar__button--playpause')!;
  const bindPlayIcon = playIcon(ui, button, 'ludo-bar__button');

  button.innerHTML = nrkMediaPlay;
  button.removeAttribute('hidden');

  return (player: ExtendedLudo) => {
    // @ts-ignore // focusOptions is experimental and not spec.
    bindPlayIcon(player);
    button.addEventListener('click', playerMethods(player).playpause);
    player.once(LudoEvents.PLAYBACKSTARTED, () => {
      ui.preventShowControlsOnFocus();
      button.focus({ preventScroll: true });
    });
  };
};
