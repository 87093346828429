import logger from '../logger';

/*
* Lazy load Google Cast SDK v3
* Ref. https://developers.google.com/cast/docs/chrome_sender_integrate#load-the-library
*/

export default ({
  log = logger('cast:load')
} = {}) => new Promise<void>((resolve, reject) => {
  if (window.cast) {
    resolve();
    return;
  }

  if (window.__ludoChromecastAttached) {
    // script already loaded
    // wait for window.cast

    let countdown = 10;
    const waitForAvailableIntervalId = setInterval(() => {
      countdown--;
      if (window.cast) {
        resolve();
        clearInterval(waitForAvailableIntervalId);
        return;
      }
      if (!countdown) {
        reject(new Error('Loading Google Cast SDK timed out'));
        clearInterval(waitForAvailableIntervalId);
      }
    }, 100);
    return;
  }

  // wrap previous callback
  const prevousonGCastApiAvailable = typeof window.__onGCastApiAvailable === 'function' ?
    window.__onGCastApiAvailable
    : () => null;

  window.__onGCastApiAvailable = function(isAvailable: boolean) {
    if (isAvailable) {
      resolve();
    } else {
      reject(new Error('Failed to load Google Cast SDK'));
    }

    prevousonGCastApiAvailable(isAvailable);
  };

  const script = document.createElement('script');
  script.setAttribute('src', 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('async', '');

  const body = document.querySelector('body');

  if (body) {
    body.appendChild(script);
  }

  window.__ludoChromecastAttached = true;
}).catch(log);
