/**
 * Loader for /channels/{channel}/epglivebuffer.
 */

import { PathLoader } from '../PathLoader';
import { APIClient } from '../types';

type EPGLiveBuffer = import('./response/EPGLiveBuffer').EPGLiveBuffer;

export class EPGLiveBufferLoader extends PathLoader<EPGLiveBuffer> {
  constructor(client: APIClient, path: string) {
    if (!/^\/channels\/\w+\/epglivebuffer$/.exec(path)) {
      throw new Error(`Bad epglivebuffer PATH: ${path}`);
    }
    super(client, path);
  }

  protected processResponse(response: Response): Promise<EPGLiveBuffer> {
    if (response.status === 204) {
      return Promise.resolve([]);
    }
    return super.processResponse(response);
  }
}
