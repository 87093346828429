import { nrkSpinner } from '@nrk/core-icons';
import { LudoEvents } from '@nrk/ludo-core';
import NrkEvents from '../../../nrk/NrkEvents';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

class SpinnerState {
  error: boolean;
  buffering: boolean;
  seeking: boolean;
  base: boolean;
  isVisible: () => boolean;

  constructor() {
    this.error = false;
    this.buffering = false;
    this.seeking = false;
    this.base = true;

    this.isVisible = () => !this.error && (this.buffering || this.seeking || this.base);
  }
}

export default (ui: LudoUIType) => {

  require('./spinner.styl');

  return (player: ExtendedLudo) => {
    const spinner = document.createElement('div');
    const speaker = ui.element.querySelector<HTMLElement>('.ludo-speaker')!;

    spinner.className = 'ludo-spinner';
    spinner.innerHTML = nrkSpinner;

    ui.insert(spinner, {
      position: ':after',
      positionTarget: player.get('videoElement')
    });

    const hide = () => spinner.setAttribute('hidden', '');
    const show = () => {
      const videoElement = player.get('videoElement');
      spinner.style.zIndex = videoElement.style.zIndex;
      spinner.removeAttribute('hidden');
    };

    let state = new SpinnerState();
    let lastVisibility: boolean;

    function update() {
      const isVisible = state.isVisible();
      if (isVisible === lastVisibility) {
        return;
      }
      lastVisibility = isVisible;

      if (isVisible) {
        show();
      } else {
        speaker.textContent = '';
        hide();
      }
    }

    player.on(NrkEvents.COUNTDOWN_INITIALIZED, () => {
      state.base = false;
      update();
    });

    player.on(LudoEvents.CUE, () => {
      state.base = true;
      update();
    });

    player.once(LudoEvents.BEFOREPLAY, () => {
      speaker.textContent = 'Laster inn';
      state.base = true;
      update();
    });

    player.on(LudoEvents.PLAYBACKSTARTED, () => {
      state.base = false;
      update();
    });

    player.on(LudoEvents.PREPARED, () => {
      state.base = false;
      update();
    });

    player.on(LudoEvents.ERROR, (e, details: any = {}) => {
      if (details.fatal) {
        state.error = true;
        update();
      }
    });

    player.on(LudoEvents.BUFFERSTART, () => {
      state.buffering = true;
      update();
    });

    player.on(LudoEvents.BUFFEREND, () => {
      state.buffering = false;
      update();
    });

    player.on(LudoEvents.ITEM_CHANGED, () => {
      state = new SpinnerState();
      update();
    });

    player.on(LudoEvents.SEEKING, () => {
      state.seeking = true;
      update();
    });

    player.on(LudoEvents.SEEKED, () => {
      state.seeking = false;
      update();
    });

    update();
  };
};
