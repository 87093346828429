import eventToggler from '../../ui/eventToggler';
import { LudoEvents } from '@nrk/ludo-core';
import arrayFind from 'array-find';
import SCRUBBER_EVENTS from './scrubberEvents';
import UI_EVENTS from '../../ui/events';
import { ScrubberContext } from './index';
import { Zone } from './ZoneModel';

const ZONE_WIDTH = 14;

export interface Point {
  id: string;
}
export interface Points {
  [key: string]: Point;
}

function minMax(source: number, min = 0, max = Number.MAX_VALUE) {
  let result = source;
  result = Math.min(result, max);
  result = Math.max(result, min);
  return result;
}

export const normalizePoints = (typedPoints: { [key: string]: Points; } = {}, { duration = 0, progressBarWidth = 0, isLive = false } = {}) => {
  const allPoints: any[] = [];

  Object.keys(typedPoints).forEach((type) => {
    const points = typedPoints[type] || {};
    Object.keys(points).forEach((key) => {
      const point = points[key];
      allPoints.push({
        ...point,
        key: `${type}_${key}`,
        id: key,
        type
      });
    });
  });

  const pointsWithZones = allPoints
    .sort((pointA, pointB) => {
      return pointA.position > pointB.position ? 1 : -1;
    })
    .map((point) => {
      const { position } = point;
      const positionPx = duration ? (position / duration) * progressBarWidth : 0;
      const zonePxAdjust = isLive ? 5 : 2;
      const zoneLeft = minMax(positionPx - ZONE_WIDTH / 2 + zonePxAdjust, 0, progressBarWidth);
      const zoneRight = minMax(positionPx + ZONE_WIDTH / 2 + zonePxAdjust, 0, progressBarWidth);

      return {
        ...point,
        positionPx,
        zoneLeft,
        zoneRight
      };
    });

  pointsWithZones.forEach((point) => {
    const overlap = arrayFind(pointsWithZones, (p: any) => point.zoneRight > p.zoneLeft && point.zoneRight < p.zoneRight);
    if (overlap) {
      const diff = point.zoneRight - overlap.zoneLeft;
      point.zoneRight -= diff / 2;
      overlap.zoneLeft += diff / 2;
    }
  });

  return pointsWithZones;
};

export default ({ player, scrubberEvents, ui, zoneModel, progressBarElement }: ScrubberContext) => {

  const typedPoints: { [key: string]: any; } = {};
  let points: Zone[] = [];
  let currentKey: string;

  function updateZones() {
    const progressBarWidth = progressBarElement.offsetWidth || 0;
    const duration = player.duration();
    const current = player.current();
    const isLive = !!current && current.isLive;
    points = normalizePoints(typedPoints, { duration, progressBarWidth, isLive });
    zoneModel.setPoints(points);
  }

  function pointsUpdated({ type, points = {} }: { type?: string, points?: any } = {}) {
    if (type) {
      typedPoints[type] = { ...points };
    }

    updateZones();
  }

  function trackPoint(point?: Zone) {
    const { key, title } = point || <any>{};
    const pointChanged = currentKey !== key;

    if (pointChanged) {
      scrubberEvents.emit(point ? SCRUBBER_EVENTS.ZONE_ENTER : SCRUBBER_EVENTS.ZONE_LEAVE, point);
    }
    scrubberEvents.emit(SCRUBBER_EVENTS.LABEL_CHANGED, title);

    currentKey = key;
  }

  function findPoint(left: number) {
    return arrayFind(points, ({ zoneLeft, zoneRight }: any) => left > zoneLeft && left < zoneRight);
  }

  function moveHandler(position: number, { left }: { left: number }) {
    const point = findPoint(left);
    trackPoint(point);
  }

  function moveEnd() {
    trackPoint();
  }

  function seekTo(position: number) {
    const progressBarWidth = progressBarElement.offsetWidth || 0;
    const point = findPoint(position * progressBarWidth);

    if (point) {
      player.seekTo(point.position);
      return;
    }

    const seekToTarget = position * player.duration();

    if (isNaN(seekToTarget)) {
      return;
    }

    player.seekTo(seekToTarget);
  }

  const scrubberToggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.MOVE]: moveHandler,
    [SCRUBBER_EVENTS.MOVEEND]: moveEnd,
    [SCRUBBER_EVENTS.POINTS_UPDATED]: pointsUpdated,
    [SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED]: updateZones,
    [SCRUBBER_EVENTS.SEEKTO]: seekTo
  });

  player.on(LudoEvents.ITEM_CHANGED, () => {
    Object.keys(typedPoints).forEach((key) => {
      delete typedPoints[key];
    });

    updateZones();
  });

  ui.on(UI_EVENTS.PLAYER_SIZE_CHANGED, updateZones);
  window.addEventListener('orientationchange', updateZones);

  scrubberToggler.on();

};
