import SCRUBBER_EVENTS from './scrubberEvents';
import { LudoEvents } from '@nrk/ludo-core';
import UI_EVENTS from '../../ui/events';
import eventToggler from '../../ui/eventToggler';
import { ScrubberContext } from './index';

export default ({ player, ui, scrubberEvents }: ScrubberContext) => {
  let updateTimeout: number;
  let endTimeout: number;
  let isLive: boolean;
  let lastSecond: number;

  function playingTimeUpdate() {
    let delayUntilNextUpdate;
    let second;

    if (isLive) {
      const liveTime = player.currentLiveTime();
      delayUntilNextUpdate = 1001 - liveTime.getMilliseconds();
      second = liveTime.getSeconds();
    } else {
      const time = player.currentTime();
      delayUntilNextUpdate = Math.max((Math.ceil(time) - time) * 1000 + 10, time === 0 ? 1000 : 100);
      second = Math.floor(time);
    }

    clearPlayingTimeUpdateTimeout();
    if (!player.isPaused()) {
      updateTimeout = window.setTimeout(playingTimeUpdate, delayUntilNextUpdate);
    }

    if (lastSecond === second) {
      return;
    }

    scrubberEvents.emit(SCRUBBER_EVENTS.PLAYING_SECOND_UPDATED);

    lastSecond = second;
  }

  function clearPlayingTimeUpdateTimeout() {
    if (updateTimeout) {
      window.clearTimeout(updateTimeout);
    }
  }

  function endTimeUpdate() {
    if (!isLive) {
      return;
    }
    const liveTime = player.convertTimeToLiveTime(player.duration());
    const delayUntilNextUpdate = 1001 - liveTime.getMilliseconds();

    clearEndUpdateTimeout();
    endTimeout = window.setTimeout(endTimeUpdate, delayUntilNextUpdate);

    scrubberEvents.emit(SCRUBBER_EVENTS.ENDTIME_SECOND_UPDATED);
  }

  function clearEndUpdateTimeout() {
    if (endTimeout) {
      window.clearTimeout(endTimeout);
    }
  }

  function updated() {
    playingTimeUpdate();
    endTimeUpdate();
  }

  function stopped() {
    clearPlayingTimeUpdateTimeout();
    endTimeUpdate();
  }

  function clearTimeouts() {
    clearPlayingTimeUpdateTimeout();
    clearEndUpdateTimeout();
  }

  const resolveTimeEvents = eventToggler(player, {
    [LudoEvents.PLAYING]: updated,
    [LudoEvents.SEEKING]: updated,
    [LudoEvents.SEEKED]: updated,
    [LudoEvents.PAUSE]: stopped,
    [LudoEvents.ENDED]: stopped
  });

  function on() {
    clearTimeouts();
    resolveTimeEvents.on();
    updated();
  }

  function off() {
    clearTimeouts();
    resolveTimeEvents.off();
  }

  player.on(LudoEvents.LOADED, () => {
    isLive = player.current()!.isLive;
  });

  ui.on(UI_EVENTS.CONTROLBARVISIBLE, on);
  ui.on(UI_EVENTS.CONTROLBARHIDDEN, off);

};
