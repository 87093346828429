import builder from './builder';
import uiEvents from '../../ui/events';
import uiOptions from '../../ui/options';
import { format, getReadableDurationText } from '../../duration';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { MediaItem } from '../../../nrk/mediaItem/MediaItem';
import { ExtendedLudo, LudoPosterOptions } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { getAccessibleLabel } from './getAccessibleLabel';

export default (ui: LudoUIType, options: LudoPosterOptions, container: any = {}) => {

  require('./poster.styl');

  const poster: ReturnType<typeof builder> = (container.builder || builder)(ui, options);

  function displayDetails(mediaItem: MediaItem) {
    const legalAge = 'legalAge' in mediaItem ? mediaItem.legalAge : undefined;
    const subtitle = mediaItem.subtitle;
    const duration = mediaItem.isLive ? '' : format(mediaItem.duration);
    const title = mediaItem.title;
    const legalAgeText = legalAge && legalAge.text;
    const age = legalAge && legalAge.badge;
    const liveIndicatorVisible = mediaItem.isLive && !mediaItem.isChannel && mediaItem.isOngoing;
    const readableDuration = !mediaItem.isLive ? getReadableDurationText(mediaItem.duration) : undefined;

    const accessibleLabel = getAccessibleLabel({
      liveIndicatorVisible,
      title: mediaItem.title || '',
      ageText: legalAgeText,
      readableDuration
    });
    poster.setAccessibleLabel(accessibleLabel);

    if (mediaItem.isChannel) {
      poster.displayDetails({});
      return;
    }

    poster.displayDetails({
      title,
      subtitle,
      duration,
      'age-number': age,
      'age-text': legalAgeText,
      'accessible-play-button__title': title
    });
  }

  return (player: ExtendedLudo) => {
    poster.show();
    projectMediaItemToPoster(player.current());

    const videoElement = player.get(LudoOptions.VIDEO_ELEMENT);

    player.on(LudoEvents.BEFOREPLAY, () => {
      poster.hidePlayButton();
    });

    player.on(LudoEvents.ERROR, (error, { fatal = false } = {}) => {
      if (fatal) {
        poster.hidePlayButton();
      }
    });

    player.on(LudoEvents.PLAYBACKSTARTED, () => {
      poster.hide();
    });

    poster.updateSizes(ui.get(uiOptions.PLAYER_DIMENSIONS));
    ui.on(uiEvents.PLAYER_SIZE_CHANGED, (playerDimensions) => {
      poster.updateSizes(playerDimensions);
    });

    const doAddShade = typeof options.shade === 'undefined' || options.shade;
    if (doAddShade) {
      poster.addShade();
    }

    function projectMediaItemToPoster(mediaItem?: MediaItem) {
      if (!mediaItem) {
        return;
      }

      if (mediaItem.isPlayable) {
        poster.displayPlayButton();
      } else {
        poster.hidePlayButton();
      }

      /*
       * Display live indicator when a live event is ongoing
       */
      const isOngoingLiveEvent = mediaItem.isLive && !mediaItem.isChannel && mediaItem.isOngoing;
      const isLiveIndicatorEnabled = (player.get('features') || {}).liveIndicator;
      if (isLiveIndicatorEnabled && isOngoingLiveEvent) {
        poster.showLiveIndicator();
      }

      if (mediaItem.startTime && mediaItem.duration
        && mediaItem.startTime > 0 && mediaItem.startTime <= mediaItem.duration) {
        const progress = Math.round(mediaItem.startTime / mediaItem.duration * 100);
        poster.setPlayProgress(progress);
      } else {
        poster.clearPlayProgress();
      }

      if ('posters' in mediaItem) {
        poster.setSourceSet(mediaItem.posters);
      }

      if (mediaItem.poster) {
        if (!poster.hasImage()) {
          poster.setImage(mediaItem.poster);
        }

        if (videoElement) {
          videoElement.poster = mediaItem.poster;
        }
      }

      displayDetails(mediaItem);
    }

    return new Promise<void>((resolve) => {

      let isBeforePlayEmitted = false;

      player.once(LudoEvents.BEFOREPLAY, () => {
        isBeforePlayEmitted = true;
      });

      player.once(LudoEvents.PREPARED, () => {

        const mediaItem = player.current();
        projectMediaItemToPoster(mediaItem);

        if (mediaItem && !mediaItem.isPlayable) {
          return resolve();
        }

        if (isBeforePlayEmitted) {
          resolve();
        }

        poster.onClick(() => {
          // tslint:disable-next-line:no-floating-promises
          player.play({ userInteraction: true });
        });

        player.once(LudoEvents.ITEM_CHANGED, () => {
          videoElement.removeAttribute('poster');
        });

        player.once(LudoEvents.BEFOREPLAY, resolve);
      });
    });
  };
};
