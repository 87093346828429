import CastEvents from '../nrkCastSender/CastEvents';
import CastStateEvents from './CastStateEvents';
import { LudoOptions } from '@nrk/ludo-core';
import LudoUIOptions from '../../ui/ui/options';
import CustomLudoEvents from '../CustomLudoEvents';
import logEvents from './logEvents';
import { ExtendedLudo } from '../../ludo/interfaces';
import { LudoUIType, UIState } from '../../ui/ui/LudoUI';

interface CastHandlerProps {
  castSender: any;
  ui: LudoUIType;
  player: ExtendedLudo;
  castState: any;
}

export default (props: CastHandlerProps) => {
  const { castSender, ui, player, castState } = props;
  const { remoteState } = castSender;
  let reloadOtherAdapter = false;

  castSender.on(CastEvents.API_AVAILABLE, () => castSender.connect());

  function initialPlay() {
    castState.isInitialPlay = true;
    // tslint:disable-next-line:no-floating-promises
    player.play();
    castState.isInitialPlay = false;
  }

  castState.on(CastStateEvents.CONNECTED, () => {
    const current = player.current();

    if (!current) {
      return;
    }

    if (!castState.isCastPlayerAdapterSet) {
      castState.adapterPrepared = false;

      player.prepareMedia(current)
        .then(() => {
          castState.adapterPrepared = true;
          if (castState.requireInitialPlay || player.get(LudoOptions.AUTOPLAY)) {
            castState.emit(CastStateEvents.LOADING);
            initialPlay();
          }
        })
        .catch((e) => {
          castSender.emit(CastEvents.ERROR, e);
        });
    }
  });

  const uiAttachedState = {
    [LudoUIOptions.PINNED_CONTROL_OVERLAY]: true,
    [LudoUIOptions.FULLSCREEN_ENABLED]: false,
    [LudoUIOptions.SUBTITLES_DISPLAYED]: false
  };
  const uiOptionKeys = Object.keys(uiAttachedState);
  const uiInitialState: { [key: string]: any; } = {};

  function attachUI() {
    uiOptionKeys
      .map((key) => key as keyof object)
      .forEach((key) => {
        uiInitialState[key] = player.get(key);
        ui.set(key, uiAttachedState[key]);
      });
    player.emit(CustomLudoEvents.CHROMECASTON);
  }

  function detachUI() {
    uiOptionKeys.forEach((key) => ui.set(key as keyof UIState, uiInitialState[key]));
    player.emit(CustomLudoEvents.CHROMECASTOFF);
  }

  castState.on(CastStateEvents.CURRENT_MEDIA_LOADED, () => {
    attachUI();
    if (castState.requireInitialPlay) {
      initialPlay();
    }
  });

  castState.on(CastStateEvents.OTHER_MEDIA_LOADED, () => {
    detachUI();
  });

  function continuePlaybackFromSamePosition() {
    if (reloadOtherAdapter) {
      return;
    }

    reloadOtherAdapter = true;

    const currentTime = castState.castAdaptersCurrentTime;
    const current = player.current();
    const isLive = current ? current.isLive : false;

    function closeCastState() {
      castState.emit(CastStateEvents.CURRENT_MEDIA_DETACHED);
      if (!isLive) {
        player.seekTo(currentTime);
        player.pause();
      }
      castState.emit(CastStateEvents.CURRENT_MEDIA_UNLOADED);
      reloadOtherAdapter = false;
    }

    player.playAt(player.index())
      .then(() => closeCastState())
      .catch(() => closeCastState());
  }

  castState.on(CastStateEvents.CURRENT_MEDIA_DISCONNECTED, () => {
    if (remoteState.connected) {
      setTimeout(() => castState.emit(CastStateEvents.MEDIA_UNLOADED_CONNECTED));
      return;
    }

    detachUI();
    continuePlaybackFromSamePosition();
  });

  castSender.on(CastEvents.APP_DISCONNECTED, () => {
    if (!castState.hasPlaybackBeenStarted) {
      const current = player.current();
      if (current) {
        // tslint:disable-next-line:no-floating-promises
        player.prepareMedia(current);
      }
    } else {
      continuePlaybackFromSamePosition();
    }
  });

  logEvents({
    castSender,
    castState
  });

  castSender.init();
};
