import { IS_IOS, LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { NativeSubtitles } from './render/NativeSubtitles';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { CueObserver, CueObserverEvents } from './cue/CueObserver';
import { HtmlSubtitles } from './render/HtmlSubtitles';
import { TextTrackRenderer } from './TextTrackRenderer';
import { Cue } from './cue/Cue';
import { AccessibilitySubtitles } from './render/AccessibilitySubtitles';
import { TextTrackRenderers } from './TextTrackRenderers';
import { SubtitlesID } from './SubtitlesID';
import {
  findMatchingSubtitle,
  getSubtitlesOfMediaItem,
  getAvailableSubtitles,
  CURRENT_SUBTITLES,
  SubtitleState,
  hasMultipleSubtitlesSupport
} from './subtitles';
import { getPreferredSubtitle } from '../../../nrk/subtitles/preferences';

export default (ui: LudoUIType) => {

  return (player: ExtendedLudo) => {
    const cueObserver = new CueObserver(player);
    let availableSubtitles: SubtitleState[] = [];

    function getSubtitleViewers() {
      const renderers: TextTrackRenderer[] = [];
      const accessibilitySubtitles = new AccessibilitySubtitles(ui);

      let useNative = IS_IOS;
      let useOverlay = !IS_IOS;
      const subtitleRenderer: string | undefined = player.get('subtitlesRenderer');
      if (subtitleRenderer) {
        useNative = /native/.test(subtitleRenderer);
        useOverlay = /overlay/.test(subtitleRenderer);
      }

      if (useNative) {
        const nativeSubtitles = new NativeSubtitles(player.get(LudoOptions.VIDEO_ELEMENT));
        renderers.push(nativeSubtitles);
      }

      if (useOverlay) {
        const htmlSubtitles = new HtmlSubtitles(ui);
        renderers.push(htmlSubtitles);

        player.on(LudoEvents.PIPON, () => {
          htmlSubtitles!.toggle(true);
        });

        player.on(LudoEvents.PIPOFF, () => {
          htmlSubtitles!.toggle(false);
        });
      }

      return new TextTrackRenderers([...renderers, accessibilitySubtitles]);
    }

    const viewers = getSubtitleViewers();

    cueObserver.on(CueObserverEvents.CUE_CHANGED, (cue?: Cue) => {
      viewers.setCue(cue);
    });

    function chooseSubtitle(reference?: SubtitlesID) {
      const subtitle = findMatchingSubtitle(availableSubtitles, reference);
      if (subtitle) {
        cueObserver.start(subtitle.src);
      } else {
        cueObserver.stop();
      }
      viewers.chooseSubtitle(reference);
    }

    player.on(LudoEvents.OPTIONCHANGED, (option: typeof LudoOptions.SUBTITLES, enabled: boolean) => {
      if (option === LudoOptions.SUBTITLES) {
        viewers.toggle(enabled);
      }
    });

    player.on(LudoEvents.OPTIONCHANGED, (option: typeof CURRENT_SUBTITLES, reference?: SubtitlesID) => {
      if (option === CURRENT_SUBTITLES && reference) {
        chooseSubtitle(reference);
      }
    });

    player.on(LudoEvents.PREPARED, () => {
      const supportMultipleSubtitles = hasMultipleSubtitlesSupport(player);
      const allSubtitles = getSubtitlesOfMediaItem(player.current());
      availableSubtitles = getAvailableSubtitles(allSubtitles, {
        supportMultipleSubtitles,
        subtitlesEnabled: player.get(LudoOptions.SUBTITLES),
        currentSubtitles: player.get(CURRENT_SUBTITLES)
      });
      viewers.setSubtitles(availableSubtitles);

      let initialSubtitles = getPreferredSubtitle(availableSubtitles);
      if (initialSubtitles === false) {
        return;
      }
      if (!initialSubtitles) {
        initialSubtitles = availableSubtitles.filter((sub) => sub.visible)[0];
      }
      if (!initialSubtitles) {
        initialSubtitles = availableSubtitles.filter((sub) => sub.default)[0];
      }
      if (initialSubtitles) {
        const { srclang: lang, type } = initialSubtitles;
        player.set(CURRENT_SUBTITLES, { lang, type });
      }
    });

    player.on(LudoEvents.PLAYBACKSTARTED, () => {
      viewers.toggle(player.get(LudoOptions.SUBTITLES));
    });

    player.on(LudoEvents.ITEM_CHANGED, () => {
      cueObserver.stop();
      viewers.reset();
    });
  };
};
