import { ExtendedLudo } from '../../interfaces';
import { EventBinder } from '../../../nrk/utilities/EventBinder';
import { summarizeDistinctDuration } from './calculations';
import { PlayedSequenceObserver, Sequence } from './PlayedSequenceObserver';
import { LudoEvents } from '@nrk/ludo-core';

export class OnDemandPlayedSequenceObserver implements PlayedSequenceObserver {
  private played: Readonly<Sequence>[] = [];
  private sequence?: Sequence | null;
  private isPaused: boolean = true;
  private player: ExtendedLudo;
  private binder: EventBinder;

  constructor(player: ExtendedLudo) {
    this.player = player;
    this.binder = new EventBinder(player);
    this.binder.on(LudoEvents.TIMEUPDATE, () => this.observe());
    this.binder.on(LudoEvents.PLAYING, () => this.observe());
    this.binder.on(LudoEvents.PAUSE, () => this.observe());
    this.binder.on(LudoEvents.SEEKING, () => this.reset());
  }

  private reset() {
    this.isPaused = true;
  }

  private observe() {
    const isPaused = this.player.isPaused();
    const currentTime = this.player.currentTime();
    const playHasJustStarted = this.isPaused && !isPaused;

    this.isPaused = isPaused;

    if (isPaused) {
      return;
    }

    if (playHasJustStarted) {
      this.sequence = {
        start: currentTime,
        end: currentTime
      };
      this.played.push(this.sequence);
      return;
    }

    if (this.sequence) {
      this.sequence.end = Math.max(this.sequence.end, currentTime);
    }
  }

  destroy() {
    if (!this.binder) {
      return;
    }
    this.binder.removeAllListeners();
    // @ts-expect-error - Not actually optional.
    delete this.player;
    // @ts-expect-error - Not actually optional.
    delete this.binder;
  }

  /*
   * Returns time played for current media. Parts of media that is played multiple times will only be counted once.
   */
  getDistinctPlayedDuration() {
    return summarizeDistinctDuration(this.played);
  }
}
