import { nrkMediaFfw15sec } from '@nrk/core-icons';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import { LudoEvents } from '@nrk/ludo-core';

export default (ui: LudoUIType) => {
  const button = ui.element.querySelector<HTMLButtonElement>('button[name="ffw15"]')!;
  button.innerHTML = nrkMediaFfw15sec;
  button.removeAttribute('hidden');

  return (player: ExtendedLudo) => {
    const disableWhenPositionIsAtEndOfBuffer = () => {
      button.disabled = player.currentTime() > player.duration() - 1;
    };

    disableWhenPositionIsAtEndOfBuffer();
    player.on(LudoEvents.PLAYING, disableWhenPositionIsAtEndOfBuffer);
    player.on(LudoEvents.ENDED, disableWhenPositionIsAtEndOfBuffer);
    player.on(LudoEvents.SEEKED, disableWhenPositionIsAtEndOfBuffer);
    player.on(LudoEvents.BUFFEREND, disableWhenPositionIsAtEndOfBuffer);
    player.on(LudoEvents.PAUSE, () => (button.disabled = false));
    ui.on('click.ffw15', () => player.seekTo(player.currentTime() + 15));
  };
};
