import { Subtitle } from '../../mediaItem/MediaItem';

export interface SubtitlePreference {
  enabled?: boolean;
  precedes?: string[];
}

export interface SubtitlePreferences {
  [key: string]: SubtitlePreference;
}

export type SubtitleRef = Pick<Subtitle, 'srclang' | 'type'>;

export function getKey(sub: SubtitleRef | undefined) {
  if (!sub) {
    return 'off';
  }
  const srclang = sub.srclang;
  let type = sub.type;

  // mix and ttv should map to the same preference
  if (srclang === 'nb' && type === 'mix') {
    type = 'ttv';
  }
  return srclang + '_' + type;
}
