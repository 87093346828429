/**
 * Events not used internally, but emitted to support our dependents.
 */
export enum PublicEvents {
  /**
   * When the user updates their preferred stream. (I.e., synstolk or tegntolk)
   */
  PREFERRED_STREAM_UPDATE = 'preferred-stream-update',
  THEATER_MODE_TOGGLED = 'theater-mode-toggled',
  SKIP_TO_NEXT = 'skip-to-next',
  CANCEL_SKIP_TO_NEXT = 'cancel-skip-to-next'
}

/**
 * The new state for their preferred stream.
 */
export interface PreferredStreamUpdateDetail {
  synstolk: boolean;
  tegntolk: boolean;
}
