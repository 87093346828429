import throttle from 'lodash/throttle';
import { LudoUIType } from '../../ui/LudoUI';
import { ControllerType } from './controller';
import { LudoUIEvents } from '../..';

export default (ui: LudoUIType, controller: ControllerType) => {

  const stageElement = ui.element;
  let isTap = false;

  const playButton = ui.element.querySelector('.ludo-playicon') as HTMLElement;

  const videoElem = ui.element.querySelector('video,audio')!;

  const controlbarScreenReaderToggleButton = document.createElement('button');
  controlbarScreenReaderToggleButton.className = 'ludo-controlbar-toggle ludo--ignore-focus-event';
  controlbarScreenReaderToggleButton.tabIndex = 0;
  controlbarScreenReaderToggleButton.type = 'button';
  controlbarScreenReaderToggleButton.title = 'Trykk for å vise videokontrollerne og trykk deretter på pauseknappen for å unngå at disse skjules';
  controlbarScreenReaderToggleButton.setAttribute('aria-label', 'Video');

  ui.element.insertBefore(controlbarScreenReaderToggleButton, videoElem);

  const toggleOnThrottled = throttle(controller.toggleOn, 100, { trailing: false });
  stageElement.addEventListener('touchmove', () => {
    isTap = false;
    toggleOnThrottled();
  });

  stageElement.addEventListener('touchstart', () => {
    isTap = true;
  });

  ui.on(LudoUIEvents.CONTROLBARHIDDEN, () => {
    controlbarScreenReaderToggleButton.classList.remove('ludo-controlbar-toggle--on');
    controlbarScreenReaderToggleButton.title = 'Trykk for å vise videokontrollerne og trykk deretter på pauseknappen for å unngå at disse skjules.';

    setTimeout(() => {
      if (ui.element.contains(document.activeElement)) {
        controlbarScreenReaderToggleButton.focus();
      }
    }, 300);
  });

  ui.on(LudoUIEvents.CONTROLBARVISIBLE, () => {
    controlbarScreenReaderToggleButton.classList.add('ludo-controlbar-toggle--on');
    controlbarScreenReaderToggleButton.title = 'Trykk for å skjule videokontrollerne.';

    setTimeout(() => {
      if (ui.element.contains(document.activeElement)) {
        playButton.focus();
      }
    }, 300);
  });

  stageElement.addEventListener('touchend', (e) => {
    toggleOnThrottled.cancel();

    if (!isTap) {
      return;
    }
    const changedTouch = e.changedTouches[0];
    const el = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY);
    if (!el || !el.classList.contains('ludo-layout')) {
      return;
    }

    controller.toggle();
  });
};
