import arrayFind from 'array-find';

export interface Zone {
  position: number;
  zoneLeft: number;
  zoneRight: number;
  positionPx: number;
}

export class ZoneModel {
  private points: Zone[] = [];

  findPoint(left: number) {
    return arrayFind(this.points, ({zoneLeft, zoneRight}) => left >= zoneLeft && left <= zoneRight);
  }

  setPoints(p: Zone[]) {
    this.points = p;
  }
}
