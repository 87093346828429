import { LudoEvents } from '@nrk/ludo-core';
import NrkEvents from '../nrk/NrkEvents';
import { ExtendedLudo } from './interfaces';

type successArgument = (event: string, timespan?: number, timedout?: boolean) => void;
type failureArgument = (error: Error, event: string, timespan?: number, timedout?: boolean) => void;

const noop = (...args: any[]) => {};

export const detectLudoFailure = function detectLudoFailure(
  player: ExtendedLudo,
  failure: failureArgument = noop,
  success: successArgument = noop
) {

  function waitForExpectedEvent(event: string) {

    const timeStamp = Date.now();

    let timedout = false;
    let failed = false;
    let succeeded = false;

    function getTimespan() {
      return Date.now() - timeStamp;
    }

    function onFailure(error: Error) {
      if (failed) {
        return;
      }
      failure(error, event, getTimespan(), timedout);
      stopFailureListening();
      failed = true;
    }

    function onSuccess() {
      if (succeeded) {
        return;
      }
      success(event, getTimespan(), timedout);
      stopFailureListening();
      succeeded = true;
    }

    function stopFailureListening() {
      player.off(LudoEvents.ERROR, onError);
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    player.once(event, onSuccess);
    player.once(NrkEvents.COUNTDOWN_INITIALIZED, onSuccess);

    function onError(error: Error, { fatal }: any = {}) {
      if (!fatal) {
        return;
      }

      const current = player.current();

      if (current && !current.isPlayable) {
        onSuccess();
        return;
      }

      onFailure(error);
    }

    player.on(LudoEvents.ERROR, onError);

    function onBeforeUnload() {
      timedout = true;
      onFailure(new Error(`Expected event ${event} timed out`));
    }

    window.addEventListener('beforeunload', onBeforeUnload);
  }

  waitForExpectedEvent(LudoEvents.PREPARED);

  player.once(LudoEvents.BEFOREPLAY, () => {
    waitForExpectedEvent(LudoEvents.PLAYING);
  });
};
