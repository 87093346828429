import { MediaItem } from '../MediaItem';

export function getWaitTime(mediaItem: MediaItem) {
  if (!(mediaItem.availableFrom instanceof Date)) {
    return -1;
  }

  return mediaItem.availableFrom.getTime() - Date.now();
}

// As long as the event has not ended, a reload is required until isOngoing is true.
export function requiresReload(mediaItem: MediaItem) {
  if (!mediaItem.isLive) {
    return false;
  }

  if (mediaItem.programRightsHasNotStarted) {
    return true;
  }

  if (mediaItem.isOngoing) {
    return false;
  }

  if (!(mediaItem.availableTo instanceof Date)) {
    return false;
  }

  return Date.now() < mediaItem.availableTo.getTime();
}
