import { PlayedSequenceObserver } from './played/PlayedSequenceObserver';
import { ExtendedLudo } from '../interfaces';
import { MediaItem } from '../../nrk/mediaItem/MediaItem';
import { LivePlayedSequenceObserver } from './played/LivePlayedSequenceObserver';
import { OnDemandPlayedSequenceObserver } from './played/OnDemandPlayedSequenceObserver';
import { v4 as uuidv4 } from 'uuid';
import { LudoEvents } from '@nrk/ludo-core';
import { EventBinder } from '../../nrk/utilities/EventBinder';

/*
 * PlaybackSessionState represents the state of a single media playback session.
 */
export interface PlaybackSessionState {
  readonly currentTime: number;
  readonly duration: number;
  readonly distinctPlayedDuration: number;
  readonly isLive: boolean;
  readonly playbackStarted: boolean;
  readonly playbackSessionId: string;
}

export class PlaybackSession {
  readonly playbackSessionId: string;
  private sequenceObserver: PlayedSequenceObserver;
  private player: ExtendedLudo;
  private current: MediaItem;
  private finalState!: PlaybackSessionState;
  private playbackStarted: boolean = false;
  private binder: EventBinder;
  private currentTime: number = 0;
  private duration: number = 0;

  constructor(player: ExtendedLudo, current: MediaItem) {
    this.player = player;
    this.current = current;
    this.playbackSessionId = uuidv4();
    this.sequenceObserver = current.isLive ?
      new LivePlayedSequenceObserver() :
      new OnDemandPlayedSequenceObserver(player);

    this.binder = new EventBinder(player);
    this.binder.on(LudoEvents.PLAYING, () => {
      this.playbackStarted = true;
      this.duration = this.player.duration();
    });

    this.binder.on(LudoEvents.TIMEUPDATE, () => {
      this.currentTime = this.player.currentTime();
    });
  }

  getDistinctPlayedDuration() {
    return this.sequenceObserver.getDistinctPlayedDuration();
  }

  getPlaybackSessionState(): PlaybackSessionState {
    const distinctPlayedDuration = this.getDistinctPlayedDuration();
    const currentTime = this.currentTime;
    const duration = this.duration;
    const { isLive } = this.current;
    const playbackSessionId = this.playbackSessionId;
    const playbackStarted = this.playbackStarted;

    return {
      currentTime,
      duration,
      distinctPlayedDuration,
      isLive,
      playbackSessionId,
      playbackStarted
    };
  }

  sessionEnded() {
    if (this.finalState) {
      return;
    }
    this.finalState = this.getPlaybackSessionState();
    this.getPlaybackSessionState = () => this.finalState;
    // @ts-expect-error - Not actually optional.
    delete this.current;
    // @ts-expect-error - Not actually optional.
    delete this.player;
    this.sequenceObserver.destroy();
    this.binder.removeAllListeners();
  }

  get mediaItem() {
    return this.current;
  }
}
