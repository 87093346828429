import { nrkMediaVolumeMuted, nrkMediaVolume3 } from '@nrk/core-icons';
import CastEvents from '../nrkCastSender/CastEvents';

export default ({ logger }: any) => {
  let castSender: any;
  let muteButtonElem: HTMLButtonElement;
  let muteButtonTextElem: HTMLElement;
  let muteButtonIconElem: HTMLElement;

  function clickHandler() {
    const isMuted = castSender.remoteState.muted;

    isMuted ? castSender.unmute() : castSender.mute();
  }

  function update() {
    const isMuted = castSender.remoteState.muted;

    if (isMuted) {
      muteButtonTextElem.textContent = 'Lyd på';
      muteButtonIconElem.innerHTML = nrkMediaVolumeMuted;
      logger('Muted');
    } else {
      muteButtonTextElem.textContent = 'Lyd av';
      muteButtonIconElem.innerHTML = nrkMediaVolume3;
      logger('Unmuted');
    }
  }

  return {
    create: (client: any) => {
      castSender = client;

      muteButtonElem = document.createElement('button');
      muteButtonElem.type = 'button';

      muteButtonTextElem = document.createElement('span');
      muteButtonIconElem = document.createElement('span');

      muteButtonElem.className = 'ludo-persistent-controls--mute-button';
      muteButtonTextElem.className = 'ludo-persistent--visually-hidden';

      muteButtonElem.appendChild(muteButtonTextElem);
      muteButtonElem.appendChild(muteButtonIconElem);
      muteButtonElem.addEventListener('click', clickHandler);

      castSender.on(CastEvents.MUTED, update);
      castSender.on(CastEvents.UNMUTED, update);

      update();

      return muteButtonElem;
    },

    destroy: () => {
      castSender.off(CastEvents.MUTED, update);
      castSender.off(CastEvents.UNMUTED, update);

      muteButtonElem.removeEventListener('click', clickHandler);
    }
  };
};
