import logger from 'bows';

interface ModuleEnvironment {
  define?: (() => void) & { amd?: {} };
  requirejs?: RequireJS;
}

type RequireJS = (
  moduleIDs: string[],
  loadCb: (...modules: any[]) => void,
  errorCb?: (error: RequireJSError) => void
) => void;

interface RequireJSError {
  requireType?: string;
  requireModules?: string[];
}

export function load(global: string, url: string) {
  const log = logger('ludo:lazy');
  const env = window as ModuleEnvironment;

  if (typeof env.define === 'function' && typeof env.define.amd !== 'undefined') {
    // AMD (Async Module Definition) environment.

    // Require.js
    if (typeof env.requirejs === 'function') {
      log(`Loading ${url} with require.js.`);
      return new Promise((resolve, reject) => env.requirejs!([url], resolve, (error) => {
        const what = error.requireModules && error.requireModules[0] || url;
        reject(new Error(`Failed to load ${what}. Reason: ${error.requireType}`));
      }));
    }

    // Don't support other AMD loaders, so things will probably break here.
    log.warn('AMD context but unsupported loader.');
  }

  log(`Loading ${url} with script tag.`);
  return loadViaScriptTag(global, url);
}

function loadViaScriptTag(global: string, url: string) {
  const win: Window & { [key: string]: any } = window;

  return new Promise<void>((resolve, reject) => {
    if (win[global] || (win.exports && win.exports[global])) {
      return resolve();
    }

    let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`);
    const create = !script;

    if (create) {
      script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.crossOrigin = 'anonymous';
      script.src = url;
    }

    script!.addEventListener('load', () => resolve());
    script!.addEventListener('error', reject);

    if (create) {
      document.head!.appendChild(script!);
    }
  }).then(() => {
    if (typeof win[global] === 'undefined'
      && (typeof win.exports === 'undefined' || typeof win.exports[global] === 'undefined')) {
        throw new Error(`Failed to load ${url}. '${global}' is not in the global or global.exports scope.`);
    }
    return win[global] || (win.exports && win.exports[global]);
  });
}

export function getter(property: string, global: string, url: string) {
  const mod = {};
  Object.defineProperty(mod, property, {
    get: () => load(global, url)
  });
  return mod;
}
