import { LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import { classToggle } from '../../../dom';
import eventToggler from '../../../ui/eventToggler';
import SCRUBBER_EVENTS from '../scrubberEvents';
import throttle from 'lodash/throttle';
import { removeChild } from '../../../dom';
import { ScrubberContext } from '../index';
import { EPGScrubberItem } from '../epgBuilder';
import { EPGProgram } from '../../../../nrk/LiveEpg/types';

interface EpgElements {
  [key: string]: {
    programElement: HTMLElement;
    playedElement: HTMLElement;
  };
}

interface ProgramModel {
  [key: string]: EPGScrubberItem;
}

export default ({ player, scrubberEvents, progressBarElement }: ScrubberContext) => {
  const epgElements: EpgElements = {};

  let cachedEpg: EPGScrubberItem[] = [];
  let intervalId: number;
  let focusedProgramKey: string | null = null;

  const epgElement = document.createElement('div');
  epgElement.className = 'ludo-progress-bar__epg';
  epgElement.setAttribute('aria-hidden', 'true');
  progressBarElement.appendChild(epgElement);

  let shadowColor: string;
  let programColor: string | any;
  let progressColor: string | any;
  let invisibleColor: string;

  function createProgramElement(p: EPGScrubberItem) {
    const programElement = document.createElement('div');
    programElement.classList.add('ludo-progress-bar__program');
    programElement.setAttribute('title', p.title);

    const playedElement = document.createElement('div');
    playedElement.classList.add('ludo-progress-bar__program',
      'ludo-progress-bar__program--progress');

    return { programElement, playedElement };
  }

  function setColorValues(programElement: HTMLElement, playedElement: HTMLElement) {
    invisibleColor = 'rgba(0, 0, 0, 0)';
    shadowColor = 'rgba(255,255,255,0.1)';

    programColor = getComputedStyle(programElement).backgroundColor;
    progressColor = getComputedStyle(playedElement).backgroundColor;
  }

  function getProgramKey({ programId, actualStartUTC }: { programId: string, actualStartUTC: number }) {
    return `${programId}-${actualStartUTC}`;
  }

  function updateElements(program: EPGScrubberItem, programElement: HTMLElement, playedElement: HTMLElement, programKey: string) {
    programElement.style.left = `${program.leftPc}%`;
    programElement.style.width = `${program.adjustedWidthPc}%`;

    if (program.adjustedPlayedWidthPc) {
      playedElement.style.left = `${program.leftPc}%`;
      playedElement.style.width = `${program.adjustedPlayedWidthPc}%`;
    }

    playedElement.style.display = program.adjustedPlayedWidthPc ? '' : 'none';
    const isInZone = focusedProgramKey === programKey;
    classToggle(programElement, 'ludo-progress-bar__program--zoneover', isInZone);
    classToggle(playedElement, 'ludo-progress-bar__program--zoneover', isInZone);
    classToggle(playedElement, 'ludo-progress-bar__program--current', program.isCurrentProgram);
  }

  function setGradients(program: EPGScrubberItem, programElement: HTMLElement, playedElement: HTMLElement) {
    const progressBarWidth = progressBarElement.offsetWidth || 0;
    let gradientStartsAt: string | undefined;
    let gradientEndsAt: string | undefined;

    const calculateGradients = (gradientStartsAt?: string, gradientEndsAt?: string) => {
      let playedGradients: string | undefined;
      let programGradients: string | undefined;

      if (gradientStartsAt && gradientEndsAt) {
        playedGradients = `linear-gradient(
          to right, ${shadowColor} ${gradientStartsAt},
                    ${progressColor} 0
        )`;
        programGradients = `linear-gradient(
          to right, ${invisibleColor} ${gradientStartsAt},
                    ${programColor} ${gradientEndsAt},
                    ${shadowColor} 0
        )`;
      } else if (gradientStartsAt) {
        playedGradients = `linear-gradient(
          to right, ${shadowColor} ${gradientStartsAt},
                    ${progressColor} 0
        )`;
        programGradients = `linear-gradient(
          to right, ${invisibleColor} ${gradientStartsAt},
                    ${programColor} 0
        )`;
      } else if (gradientEndsAt) {
        playedGradients = `linear-gradient(
          to right, ${progressColor} ${gradientEndsAt},
                    ${shadowColor} 0
        )`;
        programGradients = `linear-gradient(
          to right, ${programColor} ${gradientEndsAt},
                    ${shadowColor} 0
        )`;
      }

      return { playedGradients, programGradients };
    };

    if (program.beginningOutsideBuffer) {
      gradientStartsAt = program.startPosition < 0 ? `${Math.round(Math.abs(program.leftPx))}px` : undefined;
    }

    if (program.playsIntoFuture || program.startsInFuture) {
      const shadowStart = progressBarWidth - program.leftPx;

      if (program.isLastVisibleProgram) {
        gradientEndsAt = `${Math.round(Math.abs(shadowStart))}px`;
      } else {
        gradientEndsAt = '0px';
      }
    }

    if (gradientStartsAt || gradientEndsAt) {
      const { playedGradients, programGradients } = calculateGradients(gradientStartsAt, gradientEndsAt);

      playedElement.style.background = playedGradients || '';
      programElement.style.background = programGradients || '';
    } else {
      playedElement.style.background = progressColor;
      programElement.style.background = programColor;
    }
  }

  function updateProgramStartIndicators(program: EPGScrubberItem, programElement: HTMLElement) {
    const showProgramStartIndicator = !program.beginningOutsideBuffer
          && !program.startsInFuture
          && program.type !== 'emptyProgram';
    const fadeOut = program.beginningOutsideBuffer;

    const programStartIndicatorClass = 'ludo-progress-bar__program__start-indicator';
    const programStartIndicatorFadeClass = 'ludo-progress-bar__program__start-indicator--fade';

    if (program.startsInFuture) {
      programElement.classList.add(programStartIndicatorFadeClass);
    }

    if (showProgramStartIndicator) {
      programElement.classList.add(programStartIndicatorClass);

      if (programElement.classList.contains(programStartIndicatorFadeClass)) {
        window.setTimeout(() => programElement.classList.remove(programStartIndicatorFadeClass));
      }
    }

    // When program reaches scrubber end to the right, fade out program start indicator
    if (fadeOut) {
      // Fade out program start indicator when a program becomes the first program on the scrubber
      if (programElement.classList.contains(programStartIndicatorClass)) {
        if (!programElement.classList.contains(programStartIndicatorFadeClass)) {
          const handleFade = () => {
            programElement.classList.remove(programStartIndicatorClass);
            programElement.classList.remove(programStartIndicatorFadeClass);
            programElement.removeEventListener('transitionend', handleFade);
          };

          programElement.addEventListener('transitionend', handleFade);
          programElement.classList.add(programStartIndicatorFadeClass);
        }
      }
    }
  }

  function render() {
    const programsModel = (cachedEpg || [])
      .reduce((o, model) => {
        o[getProgramKey(model)] = model;
        return o;
      }, {} as ProgramModel);

    Object.keys(programsModel).forEach((programKey) => {
      const program = programsModel[programKey];
      let elements = epgElements[programKey];
      const isNew = !elements;

      if (isNew) {
        elements = createProgramElement(program);
        epgElements[programKey] = elements;
      }

      const { programElement, playedElement } = elements;

      if (isNew) {
        epgElement.appendChild(programElement);
        epgElement.appendChild(playedElement);

        if (!programColor || !progressColor) {
          setColorValues(programElement, playedElement);
        }
      }

      updateElements(program, programElement, playedElement, programKey);
      setGradients(program, programElement, playedElement);
      updateProgramStartIndicators(program, programElement);
    });

    // cleanup
    Object.keys(epgElements).forEach((programKey) => {
      if (programsModel.hasOwnProperty(programKey)) {
        return;
      }
      const { programElement, playedElement } = epgElements[programKey];
      removeChild(programElement);
      removeChild(playedElement);
      delete epgElements[programKey];
    });
  }

  const throttledRender = throttle(render, 5000);

  function epgUpdated(epg: EPGScrubberItem[] = [], { updated = false } = {}) {
    cachedEpg = epg;

    if (updated) {
      throttledRender.cancel();
    }

    throttledRender();

    if (intervalId) {
      window.clearInterval(intervalId);
    }
    if (epg.length) {
      intervalId = window.setInterval(throttledRender, 1000);
    }
  }

  function programEnter(program: EPGProgram) {
    focusedProgramKey = getProgramKey(program);
    render();
  }

  function programLeave() {
    focusedProgramKey = null;
    render();
  }

  const toggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.EPG_UPDATED]: epgUpdated,
    [SCRUBBER_EVENTS.PROGRAM_ENTER]: programEnter,
    [SCRUBBER_EVENTS.PROGRAM_LEAVE]: programLeave,
    [SCRUBBER_EVENTS.POINTS_UPDATED]: render
  });

  player.on(LudoEvents.LOADED, () => {
    epgUpdated([]);
    toggler.toggle(player.mediaType() === LudoMediaTypes.DVR);
  });
};
