import { LudoUIType } from '../../ui/LudoUI';
import EventEmitter from 'eventemitter3';

let topZIndex = 10000;

export const buildElement = (ui: LudoUIType, emitter: EventEmitter) => {
  const element = ui.element.querySelector<HTMLElement>('.ludo-overlay')!;
  const contentElement = element.querySelector<HTMLElement>('.ludo-overlay__content')!;
  const actionsElement = element.querySelector<HTMLElement>('.ludo-overlay__actions')!;
  const closeButton = element.querySelector<HTMLElement>('.ludo-overlay__close')!;

  closeButton.setAttribute('aria-label', 'Lukk');

  const content = (obj: string | Node) => {
    if (!obj) {
      return;
    }
    if (typeof obj === 'string') {
      contentElement.innerHTML = obj;
      return;
    }
    contentElement.innerHTML = '';
    contentElement.appendChild(obj);
  };

  const addButton = (btn: HTMLButtonElement) => {
    actionsElement.appendChild(btn);
  };

  function show() {
    element.removeAttribute('hidden');
    element.classList.add('fadein');
    element.style.zIndex = '' + topZIndex++;
    closeButton.focus();
    emitter.emit('opened');
  }

  function hide() {
    element.classList.remove('fadein');
    setTimeout(() => {
      element.setAttribute('hidden', '');
      emitter.emit('closed');
    }, 350);
  }

  const isVisible = () => !element.hasAttribute('hidden');

  contentElement.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  closeButton.addEventListener('click', (e) => {
    e.stopPropagation();
    hide();
  });

  function onKeyDown(keyEvent: KeyboardEvent) {
    if (keyEvent.which !== 27) {
      return;
    }
    hide();
  }

  function bindKeyEvents() {
    ui.element.addEventListener('keydown', onKeyDown);
  }

  function unbindKeyEvents() {
    ui.element.removeEventListener('keydown', onKeyDown);
  }

  ui.insert(element);

  return {
    content,
    addButton,
    isVisible,
    show,
    hide,
    bindKeyEvents,
    unbindKeyEvents
  };
};
