import CastEvents from '../nrkCastSender/CastEvents';
import CastStateEvents from './CastStateEvents';
import arrayIncludes from 'array-includes';
import logger from '../logger';

export default ({
  castSender,
  castState
}: any) => {

  const track = logger('cast:events');

  Object.keys(CastEvents)
    .map((event) => event as keyof object)
    .filter((event) => !arrayIncludes([CastEvents.CURRENT_TIME], CastEvents[event]))
    .forEach((event) => {
      castSender.on(CastEvents[event], (...args: any[]) => {
        track(event, args);
      });
    });

  const trackCastState = logger('cast:s:events');
  Object.keys(CastStateEvents)
    .map((event) => event as keyof object)
    .forEach((event) => {
      castState.on(CastStateEvents[event], (...args: any[]) => {
        trackCastState(event, args);
      });
    });
};
