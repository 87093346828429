import CastEvents from './CastEvents';
import logger from '../logger';
import mediaItem from './mediaItem';
import { FetchInviteCode } from '../../ludo/interfaces';
import EventEmitter from 'eventemitter3';
import { NrkMediaInfoCustomData } from './NrkMediaInfoCustomData';

const CMD_NAMESPACE = 'urn:x-cast:nrktv.chromecast.command';
const ERROR_NAMESPACE = 'urn:x-cast:nrktv.chromecast.error';

const MINIMUM_TIME_BETWEEN_API_AVAIABLE_AND_CONNECT = 100;

const CAST_NRK_TV_STATUSES = {
  HIDE_SPINNER: 'HIDE_SPINNER',
  SHOW_SPINNER: 'SHOW_SPINNER',
  SUB_VISIBLE: 'SUB_VISIBLE',
  SUB_HIDDEN: 'SUB_HIDDEN',
  NO_SUBTITLE: 'NO_SUBTITLE',
  CODE_CHALLENGE: 'CODE_CHALLENGE',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  USER_REJECTED: 'USER_REJECTED'
};

const CAST_NRK_TV_COMMANDS = {
  RECEIVER_NAME: 'RECEIVER_NAME',
  SET_METADATA: 'SET_METADATA',
  TOGGLE_HUD: 'TOGGLE_HUD',
  TOGGLE_SUB: 'TOGGLE_SUB',
  SUB_STATUS: 'SUB_STATUS',
  SUBS_ON: 'SUBS_ON',
  SUBS_OFF: 'SUBS_OFF',
  CANCEL_NEXT_EPISODE: 'CANCEL_NEXT_EPISODE',
  REQUEST_CODE_CHALLENGE: 'REQUEST_CODE_CHALLENGE',
  REMOTE_CONSOLE: 'REMOTE_CONSOLE',
  DEBUG_RECEIVER: 'DEBUG_RECEIVER'
};

const CONTROLLER_FIELDS = {
  IS_CONNECTED: 'isConnected',
  IS_MEDIA_LOADED: 'isMediaLoaded',
  DURATION: 'duration',
  CURRENT_TIME: 'currentTime',
  IS_PAUSED: 'isPaused',
  VOLUME_LEVEL: 'volumeLevel',
  IS_MUTED: 'isMuted',
  CAN_PAUSE: 'canPause',
  CAN_SEEK: 'canSeek',
  DISPLAY_NAME: 'displayName',
  STATUS_TEXT: 'statusText',
  TITLE: 'title',
  DISPLAY_STATUS: 'displayStatus',
  MEDIA_INFO: 'mediaInfo',
  IMAGE_URL: 'imageUrl',
  PLAYER_STATE: 'playerState'
};

export function getRemoteLiveDetails(mediaInfo: chrome.cast.media.MediaInfo | chrome.cast.media.MediaInfo[]) {
  const mi = Array.isArray(mediaInfo) ? mediaInfo[0] : mediaInfo;
  if (!mi) {
    return;
  }
  return mi.customData as NrkMediaInfoCustomData;
}

interface Options {
  fetchInviteCode?: FetchInviteCode;
  receiverApplicationId: string;
  castSender: any;
  remoteState: any;
  log?: (...args: unknown[]) => void;
}

export default ({
  fetchInviteCode,
  receiverApplicationId,
  castSender,
  remoteState,
  log = logger('cast:player')
}: Options) => {

  log('player');

  let remotePlayer: cast.framework.RemotePlayer;
  let remotePlayerController: cast.framework.RemotePlayerController;
  let castSession: cast.framework.CastSession;
  let isConnected = false;
  let cast: typeof window.cast;
  let chrome: typeof window.chrome;
  let localPlayStartedTimestamp: number;
  const codeChallengeEmitter = new EventEmitter();
  const usedCodeChallenges: Record<string, string> = {};
  let previousLoadTime: Date | undefined;
  let previousLoadedProgramId: string | undefined;

  const remoteMedia = mediaItem();

  function getTimeSinceMediaStartedLocally() {
    return localPlayStartedTimestamp ? (new Date().getTime() - localPlayStartedTimestamp) / 1000 : 0;
  }

  function onAnyChange({ field, value }: { field: string; value: any; }) {
    switch (field) {
      case CONTROLLER_FIELDS.IS_CONNECTED:
        remoteState.connected = value;
        break;

      case CONTROLLER_FIELDS.IS_PAUSED:
        remoteState.paused = remotePlayer.isPaused;
        break;

      case CONTROLLER_FIELDS.IS_MUTED:
        remoteState.muted = remotePlayer.isMuted;
        break;

      case CONTROLLER_FIELDS.VOLUME_LEVEL:
        remoteState.volume = remotePlayer.volumeLevel;
        break;

      case CONTROLLER_FIELDS.DURATION:
        remoteState.duration = value;
        break;

      case CONTROLLER_FIELDS.CURRENT_TIME:
        if (remoteState.customData && remoteState.customData.live) {
          remoteState.currentTime = remoteState.currentTime;
        } else {
          remoteState.currentTime = value;
        }
        break;

      case CONTROLLER_FIELDS.MEDIA_INFO:
        remoteState.mediaInfo = value;
        const remoteLiveDetails = getRemoteLiveDetails(remoteState.mediaInfo);
        if (remoteLiveDetails && remoteLiveDetails.live) {
          remoteState.currentTime = remoteLiveDetails.live.currentTimeSec + getTimeSinceMediaStartedLocally();
          remoteState.duration = remoteLiveDetails.live.durationSec;
          remoteState.customData = remoteState.mediaInfo.customData;
        }
        break;

      case CONTROLLER_FIELDS.IS_MEDIA_LOADED:
        remoteState.isMediaLoaded = value;
        if (value) {
          localPlayStartedTimestamp = new Date().getTime();
        }

        // Required to reflect the remote media status
        remoteMedia.reflectMediaStatus();
        break;

      case CONTROLLER_FIELDS.PLAYER_STATE:
        remoteState.playerState = value;
        break;

      case CONTROLLER_FIELDS.CAN_PAUSE:
        remoteState.canPause = value;
        break;

      case CONTROLLER_FIELDS.CAN_SEEK:
        remoteState.canSeek = value;
        break;

      case CONTROLLER_FIELDS.STATUS_TEXT:
      case CONTROLLER_FIELDS.DISPLAY_NAME:
      case CONTROLLER_FIELDS.DISPLAY_STATUS:
      case CONTROLLER_FIELDS.TITLE:
      case CONTROLLER_FIELDS.IMAGE_URL:
        break;

      default:
        log('missing mapping', field, value);
        break;
    }
  }

  function trackRemotePlayer() {
    const { RemotePlayerEventType } = cast.framework;
    remotePlayerController.addEventListener(RemotePlayerEventType.ANY_CHANGE, onAnyChange);
  }

  function reflectSessionState() {
    const castDevice = castSession.getCastDevice();
    remoteState.castDeviceFriendlyName = castDevice.friendlyName;
  }

  function trackSessionState() {
    const context = cast.framework.CastContext.getInstance();
    context.addEventListener(
      cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
      ({ sessionState }) => {
        remoteState.sessionState = sessionState;

        switch (sessionState) {
          case cast.framework.SessionState.NO_SESSION:
            break;

          case cast.framework.SessionState.SESSION_STARTING:
            break;

          case cast.framework.SessionState.SESSION_STARTED:
          case cast.framework.SessionState.SESSION_RESUMED:
            castSender.emit(CastEvents.APP_SESSION_CONNECTED);
            castSession = cast.framework.CastContext.getInstance().getCurrentSession()!;

            reflectSessionState();
            trackSessionMessages();
            trackSessionEvents();
            break;

          case cast.framework.SessionState.SESSION_ENDING:
            break;

          case cast.framework.SessionState.SESSION_ENDED:
            castSender.emit(CastEvents.APP_SESSION_DISCONNECTED);
            break;

          case cast.framework.SessionState.SESSION_START_FAILED:
            castSender.emit(CastEvents.ERROR, new Error('Cast session failed.'));
            break;

          default:
            break;
        }
      }
    );
  }

  function trackSessionEvents() {
    const castSession = cast.framework.CastContext.getInstance().getCurrentSession();

    if (!castSession) {
      return;
    }

    const { SessionEventType } = cast.framework;
    Object.keys(SessionEventType).forEach((eventName) => {
      castSession.addEventListener(eventName as any, (...args) => {
        log(eventName, ...args);
      });
    });
  }

  function trackSessionMessages() {
    if (!castSession) {
      return;
    }

    castSession.addMessageListener(CMD_NAMESPACE, (ns: string, message: string) => {
      const data = JSON.parse(message) || {};
      const command = data.command;

      switch (command) {
        case CAST_NRK_TV_STATUSES.SHOW_SPINNER:
          remoteState.spinner = true;
          break;

        case CAST_NRK_TV_STATUSES.HIDE_SPINNER:
          remoteState.spinner = false;
          break;

        case CAST_NRK_TV_STATUSES.SUB_VISIBLE:
          remoteState.subtitles = true;
          break;

        case CAST_NRK_TV_STATUSES.SUB_HIDDEN:
          remoteState.subtitles = false;
          break;

        case CAST_NRK_TV_STATUSES.NO_SUBTITLE:
          remoteState.subtitles = null;
          break;

        case CAST_NRK_TV_STATUSES.CODE_CHALLENGE:
          codeChallengeEmitter.emit(CAST_NRK_TV_STATUSES.CODE_CHALLENGE, data);
          break;

        default:
          log('Unknown command', command);
          break;
      }
    });

    castSession.addMessageListener(ERROR_NAMESPACE, (ns: string, message: string) => {
      log(ns, message);

      castSender.emit(CastEvents.ERROR, new Error(`${ns}: ${message}`));
    });
  }

  function sendMessage(command: string, data: Record<string, unknown> = {}) {
    if (!castSession) {
      return Promise.reject(new Error('Session not available'));
    }

    return castSession.sendMessage(CMD_NAMESPACE, {
      command,
      ...data
    }).then((...args: any[]) => {
      log('sendMessage sent', command, ...args);
    }).catch((e: Error) => {
      log('Message failed', e);
      castSender.emit(CastEvents.ERROR, e);
    });
  }

  function connect() {
    return new Promise<void>((resolve, reject) => {

      if (!remoteState.apiAvailable) {
        reject(new Error('Tried to connect without available api'));
        return;
      }

      function connect() {
        cast = window.cast;
        chrome = window.chrome;

        if (!cast || !cast.framework || !chrome || !chrome.cast) {
          reject(new Error('Chromecast api not available'));
          return;
        }

        const { RemotePlayerEventType } = cast.framework;

        const instance = cast.framework.CastContext.getInstance();

        instance.setOptions({
          receiverApplicationId,
          autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
        });

        remotePlayer = new cast.framework.RemotePlayer();
        remotePlayerController = new cast.framework.RemotePlayerController(remotePlayer);

        trackSessionState();

        function whenConnectedChanged({ value }: any) {
          remotePlayerController.removeEventListener(RemotePlayerEventType.IS_CONNECTED_CHANGED, whenConnectedChanged);
          if (value) {
            resolve();
          } else {
            reject(new Error('Could not connect to Chromecast app'));
          }
        }

        remotePlayerController.addEventListener(RemotePlayerEventType.IS_CONNECTED_CHANGED, whenConnectedChanged);

        trackRemotePlayer();
      }

      const timeSinceAvailable = remoteState.apiAvailableFrom ? Date.now() - remoteState.apiAvailableFrom : 0;
      const safeTimeUntilConnect = Math.max(0, MINIMUM_TIME_BETWEEN_API_AVAIABLE_AND_CONNECT - timeSinceAvailable);

      window.setTimeout(connect, safeTimeUntilConnect);
    })
      .then(() => {
        isConnected = true;
      })
      .catch((e) => {
        castSender.emit(CastEvents.ERROR, e);
      });
  }

  function disconnect() {}

  async function resolveCodeChallenge(): Promise<string | void> {
    try {
      return await api.requestCodeChallenge();
    } catch (e) {
      log('Failed when requesting code challenge, continue without invite code.', e);
    }
  }

  async function resolveInviteCode(): Promise<{ inviteCode: string; codeChallenge: string; } | void> {
    if (typeof fetchInviteCode !== 'function') {
      return;
    }
    const codeChallenge = await resolveCodeChallenge();
    if (!codeChallenge) {
      return;
    }
    try {
      const inviteCode = await fetchInviteCode(codeChallenge);
      if (!inviteCode) {
        return;
      }
      usedCodeChallenges[codeChallenge] = codeChallenge;
      return {
        inviteCode,
        codeChallenge
      };
    } catch (e) {
      log('Failed when fetching secret key, continue without invite code.', e);
    }
  }

  interface LoadOptions {
    currentTime?: number;
    subtitles?: boolean;
    preferredManifest?: string;
  }

  async function load(programId: string, options: LoadOptions = {}) {
    const {
      currentTime = 0,
      subtitles: subtitle,
      preferredManifest
    } = options;
    log('Loading...', programId, options);
    const now = new Date();
    const timeSinceLastLoad = previousLoadTime ? now.getTime() - previousLoadTime.getTime() : Infinity;
    if (timeSinceLastLoad < 100 && programId === previousLoadedProgramId) {
      return;
    }
    previousLoadedProgramId = programId;
    previousLoadTime = now;

    if (!cast || !cast.framework) {
      throw new Error('Api not available');
    }

    if (!remotePlayer.isConnected) {
      throw new Error('Not connected');
    }

    castSender.emit(CastEvents.LOADING);

    const mediaInfo = new chrome.cast.media.MediaInfo(programId, 'application/x-mpegURL');
    mediaInfo.customData = {
      deviceType: chrome.cast.SenderPlatform.CHROME
    };
    mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
    (mediaInfo.metadata as any).metadataType = chrome.cast.media.MetadataType.GENERIC;

    const customData: Record<string, any> = {
      handleStats: true
    };
    if (typeof subtitle === 'boolean') {
      customData.subtitle = subtitle;
    }
    const inviteResult = await resolveInviteCode();
    if (inviteResult) {
      customData.inviteCode = inviteResult.inviteCode;
      customData.codeChallenge = inviteResult.codeChallenge;
    }
    if (preferredManifest) {
      customData.preferredManifest = preferredManifest;
    }
    const request = new chrome.cast.media.LoadRequest(mediaInfo);
    request.customData = customData;
    request.currentTime = currentTime;

    try {
      const errorCode = await castSession.loadMedia(request);
      if (errorCode) {
        log(`Remote media load error: ${errorCode}`);
        throw new Error(`Failed when loading: ${errorCode}`);
      }
      castSender.emit(CastEvents.LOADED);
    } catch (e) {
      castSender.emit(CastEvents.ERROR, e);
      throw e;
    }
  }

  const api = {
    play: remoteMedia.play,
    pause: remoteMedia.pause,
    stop: remoteMedia.stop,
    currentTime: () => remoteState.currentTime,
    duration: () => remotePlayer.duration,
    setVolume: (volume: number) => {
      if (volume === 0) {
        api.mute();
        return;
      }
      castSession.setVolume(volume).catch(log);
    },
    volume: () => remotePlayer.volumeLevel,
    mute: () => !remotePlayer.isMuted && remotePlayerController.muteOrUnmute(),
    unmute: () => remotePlayer.isMuted && remotePlayerController.muteOrUnmute(),
    isMuted: () => remotePlayer.isMuted,
    isPaused: () => {
      if (!remotePlayer.isMediaLoaded) {
        return true;
      }
      const media = remoteMedia.getMedia();
      return (media) ? media.playerState !== 'PLAYING' : true;
    },
    seekTo: remoteMedia.seekTo,
    seekToLive: (time: Date) => {
      const mediaSession = castSession.getMediaSession();
      if (!mediaSession) {
        throw new Error('No mediaSession');
      }

      const adjustedTime = new Date(time.getTime() - (getTimeSinceMediaStartedLocally() * 1000));

      const request = new chrome.cast.media.SeekRequest();
      remotePlayer.currentTime = 0;
      request.currentTime = 0;
      request.customData = {
        seekToTimestamp: adjustedTime
      };

      mediaSession.seek(request, () => {}, () => {});
    },
    subtitlesOn: () => sendMessage(CAST_NRK_TV_COMMANDS.SUBS_ON),
    subtitlesOff: () => sendMessage(CAST_NRK_TV_COMMANDS.SUBS_OFF),
    requestCodeChallenge: () => {
      sendMessage(CAST_NRK_TV_COMMANDS.REQUEST_CODE_CHALLENGE).catch(log);

      return new Promise<string>((resolve, reject) => {
        let timeoutId: number;

        const destroy = () => {
          codeChallengeEmitter.off(CAST_NRK_TV_STATUSES.CODE_CHALLENGE, onMessage);
          window.clearTimeout(timeoutId);
        };

        const onMessage = (data: any) => {
          resolve(data.codeChallenge);
          destroy();
        };

        codeChallengeEmitter.once(CAST_NRK_TV_STATUSES.CODE_CHALLENGE, onMessage);

        timeoutId = window.setTimeout(() => {
          destroy();
          reject(new Error('Requesting code challenge timed out'));
        }, 1000);
      });
    },
    showRemoteConsole: () => { sendMessage(CAST_NRK_TV_COMMANDS.REMOTE_CONSOLE).catch(() => {}) ; },
    debugReceiver: () => { sendMessage(CAST_NRK_TV_COMMANDS.DEBUG_RECEIVER).catch(() => {}); }
  };

  const apiCall = (name: keyof typeof api) => (...args: any[]) => {
    if (!isConnected) {
      return 0;
    }
    return (api[name] as (...args: any[]) => any)(...args);
  };

  const apiProxy = Object.keys(api).reduce((o, k) => {
    o[k] = apiCall(k as keyof object);
    return o;
  }, {} as { [key: string]: () => any; }) as unknown as typeof api;

  return Object.assign({
    load,
    connect,
    disconnect,
    remoteState,
    updateState: remoteMedia.reflectMediaStatus
  },
  apiProxy);
};
