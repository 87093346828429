import uiEvents from '../../ui/events';
import { classToggle } from '../../dom';
import { LudoUIType } from '../../ui/LudoUI';

// The delay to make the controlbar clickable.
// Decreasing this will cause buttons to be clickable before they're visible. (iOS in some contexts)
const REMOVE_VISUALLY_HIDDEN_DELAY = 100;

function reflectOpacityAsClassName(element: HTMLElement) {
  // Set classname if transition is not supported
  const computedStyle = window.getComputedStyle(element);
  if (!computedStyle.hasOwnProperty('transition')) {
    element.classList.add('ludo-controlbar--visible');
  }

  element.addEventListener('transitionend', () => {
    const computedStyle = window.getComputedStyle(element);
    const opacity = parseFloat(computedStyle.opacity || '') || 0;

    classToggle(element, 'ludo-controlbar--visible', opacity > .9);
  });

  element.addEventListener('transitionstart', () => {
    element.classList.remove('ludo-controlbar--visible');
  });
}

const ControlBar = (ui: LudoUIType) => {
  const element = ui.element.querySelector('.ludo-controlbar') as HTMLElement;

  reflectOpacityAsClassName(element);

  let controlBarToggleTimeout: number;
  let blockHideControlBar: boolean;
  let lastEnable: boolean;

  function toggle(enable: boolean) {
    if (!enable && blockHideControlBar) {
      return;
    }

    if (lastEnable === enable) {
      return;
    }
    lastEnable = enable;

    if (controlBarToggleTimeout) {
      window.clearTimeout(controlBarToggleTimeout);
    }
    if (enable) {
      controlBarToggleTimeout = window.setTimeout(() => {
        element.classList.add('ludo-controlbar--on');
        ui.emit(uiEvents.CONTROLBARVISIBLE);
      }, REMOVE_VISUALLY_HIDDEN_DELAY);
    } else {
      element.classList.remove('ludo-controlbar--on');
      controlBarToggleTimeout = window.setTimeout(() => {
        ui.emit(uiEvents.CONTROLBARHIDDEN);
      }, 300);
    }
  }

  return {
    toggle,
    isVisible() {
      return element.classList.contains('ludo-controlbar--on');
    },
    blockHide() {
      blockHideControlBar = true;
    },
    allowHide() {
      blockHideControlBar = false;
    },
    element
  };

};

export default ControlBar;

export type ControlBarType = ReturnType<typeof ControlBar>;
