import { IS_MOBILE, LudoEvents, SUPPORTS_TOUCH } from '@nrk/ludo-core';
import uiEvents from '../../ui/events';
import uiOptions from '../../ui/options';
import SCRUBBER_EVENTS from './scrubberEvents';
import { ScrubberContext } from './index';
import { isControlbarFullyVisible } from './isControlbarFullyVisible';

const TRIGGER_DRAG_ON_PLAYHEAD__WIDTH = 100;

export default ({ player, ui, scrubberEvents, playheadElement, clickZoneElement, progressBarElement }: ScrubberContext) => {

  const stageElement = ui.element;
  let isVisible = false;
  let ignoreMouseMoves = false;

  player.once(LudoEvents.LOADED, () => {

    let previousIsInZone = false;
    let recentlyTouchend = false;
    let isDragging = false;

    function getRelativeEventPositionDetails(event: MouseEvent | TouchEvent) {
      let clientX: number;

      if ('changedTouches' in event) {
        const touchEvent = event.changedTouches && event.changedTouches[0];
        clientX = touchEvent.clientX;
      } else {
        clientX = event.clientX;
      }

      const width = progressBarElement.offsetWidth || 0;

      let leftOffset = 0;

      if (!IS_MOBILE) {
        leftOffset = parseInt(getComputedStyle(clickZoneElement).paddingLeft || '0', 10);
      }

      let left = clientX - clickZoneElement.getBoundingClientRect().left - leftOffset;
      left = Math.max(0, left);
      left = Math.min(width, left);

      return {
        left,
        width
      };
    }

    function move(e: MouseEvent | TouchEvent) {
      if (recentlyTouchend) {
        recentlyTouchend = false;
        return;
      }
      if (isDragging || !isVisible) {
        return;
      }
      const {
        left,
        width
      } = getRelativeEventPositionDetails(e);

      const isInZone = isEventInsideElement(e, clickZoneElement);
      if (isInZone !== previousIsInZone) {
        scrubberEvents.emit(isInZone ? SCRUBBER_EVENTS.MOVESTART : SCRUBBER_EVENTS.MOVEEND);
      }
      previousIsInZone = isInZone;

      if (SUPPORTS_TOUCH && e.cancelable && (isDragging || isInZone)) {
        e.preventDefault(); // avoid scroll while sliding
      }

      if (!isInZone || ignoreMouseMoves) {
        return;
      }

      const position = left / width;

      scrubberEvents.emit(SCRUBBER_EVENTS.MOVE, position, {
        left,
        width
      });
    }

    function click(e: MouseEvent | TouchEvent) {
      if (isDragging || !isVisible) {
        return;
      }

      if (e.target && e.target === playheadElement) {
        return;
      }

      if (e.target !== clickZoneElement) {
        return;
      }

      if (!isControlbarFullyVisible(stageElement)) {
        return;
      }

      const {
        left,
        width
      } = getRelativeEventPositionDetails(e);

      if (!isEventInsideElement(e, clickZoneElement)) {
        return;
      }

      scrubberEvents.emit(SCRUBBER_EVENTS.SEEKTO, left / width);
    }

    function touchend() {
      if (isDragging || !isVisible) {
        return;
      }
      recentlyTouchend = true;
      scrubberEvents.emit(SCRUBBER_EVENTS.MOVEEND);
    }

    function touchstart(e: TouchEvent) {
      if (isDragging || !isVisible) {
        return;
      }
      const {
        left
      } = getRelativeEventPositionDetails(e);

      if (!isEventInsideElement(e, clickZoneElement)) {
        return;
      }
      const start = playheadElement.offsetLeft - TRIGGER_DRAG_ON_PLAYHEAD__WIDTH / 2;
      const end = playheadElement.offsetLeft + TRIGGER_DRAG_ON_PLAYHEAD__WIDTH / 2;
      const isInsidePlayhead = left >= start && left <= end;

      if (!isInsidePlayhead) {
        return;
      }

      scrubberEvents.emit(SCRUBBER_EVENTS.TOUCHSTART, e);
    }

    stageElement.addEventListener('mousemove', move);
    stageElement.addEventListener('mouseleave', move);
    stageElement.addEventListener('click', click);

    if (SUPPORTS_TOUCH) {
      stageElement.addEventListener('touchmove', move);
      stageElement.addEventListener('touchend', touchend);
      stageElement.addEventListener('touchstart', touchstart);
    }

    scrubberEvents.on(SCRUBBER_EVENTS.DRAGSTART, () => {
      isDragging = true;
      scrubberEvents.emit(SCRUBBER_EVENTS.MOVESTART);
    });

    scrubberEvents.on(SCRUBBER_EVENTS.DRAGEND, () => {
      isDragging = false;
      scrubberEvents.emit(SCRUBBER_EVENTS.MOVEEND);
    });

    scrubberEvents.on(SCRUBBER_EVENTS.DRAG, (position, details) => {
      scrubberEvents.emit(SCRUBBER_EVENTS.MOVE, position, details);
    });
  });

  ui.on(uiEvents.CONTROLBARVISIBLE, () => {
    isVisible = true;
  });
  ui.on(uiEvents.CONTROLBARHIDDEN, () => {
    isVisible = false;
  });

  ui.on(uiEvents.OPTIONCHANGED, (option, value) => {
    if (option === uiOptions.SCRUBBER_IGNORE_MOUSE_MOVE) {
      ignoreMouseMoves = value;
    }
  });
};

function isEventInsideElement(event: MouseEvent | TouchEvent, element: Element) {
  let clientX: number;
  let clientY: number;

  if ('changedTouches' in event) {
    const touchEvent = event.changedTouches && event.changedTouches[0];
    clientX = touchEvent.clientX;
    clientY = touchEvent.clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }

  const rect = element.getBoundingClientRect();

  return clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom;
}
