import { ExtendedLudo } from '../interfaces';
import { LudoEvents } from '@nrk/ludo-core';
import { NrkEvents } from '../../nrk/index';

export function detectPlaybackEnded(player: ExtendedLudo) {

  let previouslyEmittedSessionId: string;

  /*
   * Emit PLAYBACK_ENDED only when playback has started and only once for each session.
   */
  function emitPlaybackEnded() {
    const session = player.getPlaybackSession();
    if (!session) {
      return;
    }
    if (previouslyEmittedSessionId === session.playbackSessionId) {
      return;
    }
    previouslyEmittedSessionId = session.playbackSessionId;
    const playbackSessionState = session.getPlaybackSessionState();
    if (!playbackSessionState.playbackStarted) {
      return;
    }
    player.emit(NrkEvents.PLAYBACK_ENDED, playbackSessionState, session.mediaItem);
  }

  window.addEventListener('beforeunload', () => emitPlaybackEnded());
  player.on(LudoEvents.ITEM_CHANGED, () => emitPlaybackEnded());
}
