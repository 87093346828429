export default {
  MOVE: 'move',
  MOVEEND: 'moveend',
  MOVESTART: 'movestart',
  DRAG: 'drag',
  DRAGSTART: 'dragstart',
  DRAGEND: 'dragend',
  SEEKTO: 'seekto',
  PROGRAM_ENTER: 'program-enter',
  PROGRAM_LEAVE: 'program-leave',
  ZONE_ENTER: 'zone-enter',
  ZONE_LEAVE: 'zone-leave',
  LABEL_CHANGED: 'label-changed',
  THUMBNAIL_LOADED: 'thumbnail-loaded',
  THUMBNAIL_LOADING: 'thumbnail-loading',
  THUMBNAIL_REMOVE: 'thumbnail-remove',
  THUMBNAIL_HIDE_IMAGE: 'thumbnail-hide-image',
  THUMBNAIL_POSITION_CHANGED: 'thumbnail-position-changed',
  THUMBNAIL_VISIBILITY_CHANGED: 'thumbnail-visibility-changed',
  PLAYING_SECOND_UPDATED: 'playing_second_updated',
  ENDTIME_SECOND_UPDATED: 'endtime_second_updated',
  SLIDING_TIME_POSITIONED: 'sliding-time-position',
  PLAYED_TIME_UPDATED: 'played-time-updated',
  POINTS_UPDATED: 'points-updated',
  EPG_UPDATED: 'epg-updated',
  TOUCHSTART: 'touchstart',
  PIXEL_PROGRESS: 'pixel-progress'
};
