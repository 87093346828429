import CastEvents from './CastEvents';
import logger from '../logger';

const PLAYER_STATE = {
  PAUSED: 'PAUSED',
  PLAYING: 'PLAYING',
  IDLE: 'IDLE',
  BUFFERING: 'BUFFERING'
};

const SESSION_STATES = {
  NO_SESSION: 'NO_SESSION',
  SESSION_STARTING: 'SESSION_STARTING',
  SESSION_STARTED: 'SESSION_STARTED',
  SESSION_RESUMED: 'SESSION_RESUMED',
  SESSION_ENDING: 'SESSION_ENDING',
  SESSION_ENDED: 'SESSION_ENDED',
  SESSION_START_FAILED: 'SESSION_START_FAILED'
};

const DEFAULT_VALUES = {
  apiInitializing: false,
  apiAvailable: false,
  apiAvailableFrom: null,
  connected: false,
  loaded: false,
  contentId: null,
  spinner: false,
  subtitles: false,
  paused: false,
  muted: false,
  volume: 0,
  duration: 0,
  currentTime: 0,
  mediaInfo: null,
  isMediaLoaded: false,
  playerState: null,
  sessionState: null
};

export const PROPERTIES = {
  API_INITIALIZING: 'apiInitializing',
  API_AVAILABLE: 'apiAvailable',
  API_AVAILABLE_FROM: 'apiAvailableFrom',
  CONNECTED: 'connected',
  LOADED: 'loaded',
  CONTENT_ID: 'contentId',
  SPINNER: 'spinner',
  SUBTITLES: 'subtitles',
  PAUSED: 'paused',
  MUTED: 'muted',
  VOLUME: 'volume',
  DURATION: 'duration',
  CURRENT_TIME: 'currentTime',
  CUSTOM_DATA: 'customData',
  MEDIA_INFO: 'mediaInfo',
  IS_MEDIA_LOADED: 'isMediaLoaded',
  PLAYER_STATE: 'playerState',
  SESSION_STATE: 'sessionState',
  DEVICE_NAME: 'castDeviceFriendlyName',
  CAN_SEEK: 'canSeek',
  CAN_PAUSE: 'canPause'
};

export default ({
  castSender,
  log = logger('cast:state')
}: any) => {

  const propertyNames = Object.keys(PROPERTIES).map((k) => PROPERTIES[k as keyof object]);

  function defaultState() {
    return propertyNames.reduce((o, k) => {
      const hasDefault = Object.prototype.hasOwnProperty.call(DEFAULT_VALUES, k);
      o[k] = hasDefault ? (DEFAULT_VALUES as any)[k] : false;
      return o;
    }, {} as any);
  }

  const state = defaultState();

  function reEmit(key: string, value: any) {
    switch (key) {
      case PROPERTIES.API_INITIALIZING:
        if (value) {
          castSender.emit(CastEvents.API_INITIALIZE);
        }
        break;

      case PROPERTIES.API_AVAILABLE:
        set(PROPERTIES.API_INITIALIZING, false);
        if (value) {
          set(PROPERTIES.API_AVAILABLE_FROM, Date.now());
        }
        castSender.emit(value ? CastEvents.API_AVAILABLE : CastEvents.API_UNAVAILABLE);
        break;

      case PROPERTIES.CONNECTED:
        castSender.emit(value ? CastEvents.APP_CONNECTED : CastEvents.APP_DISCONNECTED);
        break;

      case PROPERTIES.MUTED:
        castSender.emit(value ? CastEvents.MUTED : CastEvents.UNMUTED);
        break;

      case PROPERTIES.PAUSED:
        castSender.emit(value ? CastEvents.PAUSED : CastEvents.PLAYING);
        break;

      case PROPERTIES.VOLUME:
        castSender.emit(CastEvents.VOLUMECHANGE, value);
        break;

      case PROPERTIES.DURATION:
        castSender.emit(CastEvents.DURATION, value);
        break;

      case PROPERTIES.CURRENT_TIME:
        castSender.emit(CastEvents.CURRENT_TIME, value);
        break;

      case PROPERTIES.MEDIA_INFO:
        castSender.emit(CastEvents.MEDIA_INFO, value);
        break;

      case PROPERTIES.CUSTOM_DATA:
        castSender.emit(CastEvents.CUSTOM_DATA, value);
        break;

      case PROPERTIES.IS_MEDIA_LOADED:
        castSender.emit(value ? CastEvents.MEDIA_LOADED : CastEvents.MEDIA_UNLOADED);
        break;

      case PROPERTIES.DEVICE_NAME:
        castSender.emit(CastEvents.DEVICE_NAME, value);
        break;

      case PROPERTIES.PLAYER_STATE:
        switch (value) {
          case PLAYER_STATE.PLAYING:
            castSender.emit(CastEvents.PLAYING);
            break;

          case PLAYER_STATE.PAUSED:
            castSender.emit(CastEvents.PAUSED);
            break;

          case PLAYER_STATE.BUFFERING:
            castSender.emit(CastEvents.BUFFERING);
            break;

          case PLAYER_STATE.IDLE:
            castSender.emit(CastEvents.IDLE);
            break;

          default:
            log('unknown playerState', value);
            break;
        }
        break;

      case PROPERTIES.SPINNER:
        castSender.emit(value ? CastEvents.SPINNERON : CastEvents.SPINNEROFF);
        break;

      case PROPERTIES.SUBTITLES:
        castSender.emit((typeof value === 'boolean') ? (value ? CastEvents.SUBTITLESON : CastEvents.SUBTITLESOFF) : CastEvents.SUBTITLESDISABLED);
        break;

      case PROPERTIES.SESSION_STATE:
        switch (value) {
          case SESSION_STATES.NO_SESSION:
            castSender.emit(CastEvents.SESSION_STATE, 'NONE');
            break;

          case SESSION_STATES.SESSION_STARTING:
            castSender.emit(CastEvents.SESSION_STATE, 'STARTING');
            break;

          case SESSION_STATES.SESSION_STARTED:
            castSender.emit(CastEvents.SESSION_STATE, 'STARTED');
            break;

          case SESSION_STATES.SESSION_RESUMED:
            castSender.emit(CastEvents.SESSION_STATE, 'RESUMED');
            break;

          case SESSION_STATES.SESSION_ENDING:
            castSender.emit(CastEvents.SESSION_STATE, 'ENDING');
            break;

          case SESSION_STATES.SESSION_ENDED:
            castSender.emit(CastEvents.SESSION_STATE, 'ENDED');
            break;

          case SESSION_STATES.SESSION_START_FAILED:
            castSender.emit(CastEvents.SESSION_STATE, 'FAILED');
            break;

          default:
            log('No matching session state!');
            break;
        }

        break;

      default:
        break;
    }
  }

  function set(key: string, value: any) {
    state[key] = value;
    reEmit(key, value);

    if (key === PROPERTIES.MEDIA_INFO && value && value.customData) {
      set(PROPERTIES.CUSTOM_DATA, value);
    }
  }

  function reset() {
    const defaults = defaultState();
    propertyNames.forEach((key) => {
      state[key] = defaults[key];
    });
  }

  const returnObject = propertyNames.reduce((o, propertyName) => {
    Object.defineProperty(o, propertyName, {
      get: () => state[propertyName],
      set: (value) => set(propertyName, value),
      enumerable: true
    });
    return o;
  }, {});

  return Object.assign(returnObject, {
    reset
  });
};
