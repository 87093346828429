import debounce from 'lodash/debounce';
import { addMouseClickEventListener } from '../../dom/events';
import playerMethods from '../../ui/playerMethods';
import { LudoUIType } from '../../ui/LudoUI';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { ControllerType } from './controller';
import { ControlBarType } from './controlbar';

function isParentOrSame(el: HTMLElement, parent: HTMLElement) {
  let current: (Node & ParentNode) | null  = el;
  while (current) {
    if (current === parent) {
      return true;
    }
    current = current.parentNode;
  }
  return false;
}

export default (ui: LudoUIType, controller: ControllerType, controlBar: ControlBarType, delayIdle: () => void, player: ExtendedLudo) => {

  const stageElement = ui.element;
  const controllerElement = controller.element;
  const controlBarElement = controlBar.element;

  const { playpause } = playerMethods(player);

  let lastMouseMovePos = '';
  let isTouching = false;
  let temporarilyIgnoreMouseMoves = false;
  let hideTimer: number | null = null;
  let touchTimeoutId: number;

  const mousemove = debounce((event) => {
    const mousePos = `${event.screenX}x${event.screenY}`;
    if (mousePos !== lastMouseMovePos) {
      controller.toggleOn();
    }
    lastMouseMovePos = mousePos;
  }, undefined, { trailing: false, leading: true });

  const setHideTimer = debounce(() => {
    if (hideTimer) {
      window.clearTimeout(hideTimer);
    }
    hideTimer = window.setTimeout(() => {
      const isInsideControlBar = controlBarElement.classList.contains('ludo-controlbar--hover');
      const isKeyboardNavigation = stageElement.classList.contains('ludo--keyboard-navigation');
      if (isInsideControlBar || isKeyboardNavigation) {
        return;
      }

      if (!player.isPaused()) {
        controlBar.allowHide();
        controller.toggleOff();
      }
    }, 5000);
  }, undefined, { trailing: true, leading: false });

  function abortMouseMove() {
    mousemove.cancel();
    isTouching = true;
    if (touchTimeoutId) {
      window.clearTimeout(touchTimeoutId);
    }
    touchTimeoutId = window.setTimeout(() => {
      isTouching = false;
    }, 200);
  }

  // Abort mousemove events that is triggered by touch event
  stageElement.addEventListener('touchstart', abortMouseMove);
  stageElement.addEventListener('touchmove', abortMouseMove);

  stageElement.addEventListener('mousemove', (e) => {
    if (isTouching || temporarilyIgnoreMouseMoves) {
      return;
    }
    mousemove(e);
    setHideTimer();
  });

  stageElement.addEventListener('mouseleave', () => {
    mousemove.cancel();
    controller.toggleOff();
  });

  addMouseClickEventListener(controllerElement, (event: Event) => {
    let el = event.target as HTMLElement;
    while (el && el !== controllerElement && el !== controlBarElement) {
      if (el.classList.contains('ludo--desktop--delay-hide-after-click')) {
        setHideTimer();
        return;
      }
      el = el.parentNode as HTMLElement;
    }
    if (el === controllerElement) {
      playpause();
    }

    // When clicking controller, it should be hidden (unless video is paused)
    if (!player.isPaused()) {
      controlBar.allowHide();
      controller.toggleOff(700);

      // Mouse is sensitive to movement when clicking; make sure controls
      // dosen't stay open by accident by ignoring mouse moves for a little
      // while.
      temporarilyIgnoreMouseMoves = true;
      window.setTimeout(() => {
        temporarilyIgnoreMouseMoves = false;
      }, 300);
    }
  });

  stageElement.addEventListener('focus', (e) => {
    if (e.target && (<HTMLElement>e.target).classList.contains('ludo--ignore-focus-event')) {
      return;
    }

    if (ui.showControlsOnFocusPrevented) {
      return;
    }

    // Touch behavior triggers focus events programmatically; these should
    // be ignored. (Checking !event.isTrusted to see if the event is user
    // triggered is an alternative, but does not work cross browser.)
    if (!isTouching) {
      controller.toggleOn();
    }
  }, true);

  stageElement.addEventListener('blur', (e) => {
    const relatedTarget = e.relatedTarget as HTMLElement;
    if (relatedTarget && isParentOrSame(relatedTarget, stageElement)) {
      delayIdle();
      return;
    }
    controller.toggleOff();
  }, true);

  stageElement.addEventListener('keydown', (e) => {
    const activeElement = document.activeElement as HTMLElement;
    if (e.which === 27 && activeElement) {
      activeElement.blur();
    }
  }, true);

  let toggleOffOnMouseLeave: number;

  controlBarElement.addEventListener('mouseleave', () => {
    controlBarElement.classList.remove('ludo-controlbar--hover');
    if (toggleOffOnMouseLeave) {
      window.clearTimeout(toggleOffOnMouseLeave);
    }
    if (isTouching || temporarilyIgnoreMouseMoves) {
      return;
    }
    abortMouseMove();
    controlBar.allowHide();
    // Short delay to avoid blinking if mouse continues move.
    toggleOffOnMouseLeave = window.setTimeout(() => {
      controller.toggleOff();
    }, 300);
  });

  controlBarElement.addEventListener('mouseenter', () => {
    controlBarElement.classList.add('ludo-controlbar--hover');
    if (toggleOffOnMouseLeave) {
      window.clearTimeout(toggleOffOnMouseLeave);
    }
    if (isTouching || temporarilyIgnoreMouseMoves) {
      return;
    }
    controlBar.blockHide();
  });
};
