import debug from 'bows';
import { LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import throttle from 'lodash/throttle';
import SCRUBBER_EVENTS from '../scrubberEvents';
import eventToggler from '../../../ui/eventToggler';
import { ScrubberContext } from '../index';

const log = debug('ludo:thumb:vod');

function fetchImg(url: string) {
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = resolve;
    img.onerror = reject;
    img.src = url;
  });
}

export default ({ player, scrubberEvents }: ScrubberContext) => {

  let retryCount: number = 0;
  let currentThumbnail: any = null;

  const moveHandler = throttle((position, details) => {

    const frameDuration = currentThumbnail.frameDuration || 10;
    const offset = Math.round(player.duration() / frameDuration * position);
    const cols = currentThumbnail.cols || 20;
    const row = Math.floor(offset / cols);
    const column = offset % cols;
    const left = details.left;

    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_POSITION_CHANGED, {
      row,
      column,
      left
    });
  }, 100);

  function moveEnd() {
    moveHandler.cancel();
    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_HIDE_IMAGE);
  }

  const toggler = eventToggler(scrubberEvents, {
    [SCRUBBER_EVENTS.MOVE]: moveHandler,
    [SCRUBBER_EVENTS.MOVEEND]: moveEnd
  });

  function load() {
    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_LOADING);

    retryCount = 0;

    startPreloading();
  }

  function unload() {
    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_REMOVE);

    toggler.off();
  }

  function startPreloading() {
    retryCount++;
    const current = player.current();
    currentThumbnail = current && 'thumbnails' in current && current.thumbnails;

    if (currentThumbnail && currentThumbnail.src) {
      fetchImg(currentThumbnail.src)
        .then(thumbnailLoadHandler)
        .catch(thumbnailErrorHandler);
    }
  }

  function thumbnailLoadHandler() {
    log('Thumnails loaded', currentThumbnail);

    const frameDuration = currentThumbnail.frameDuration || 10;
    const frames = Math.ceil(player.duration() / frameDuration);
    const cols = currentThumbnail.cols || 20;
    const rows = Math.ceil(frames / cols);

    scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_LOADED, {
      ...currentThumbnail,
      rows
    });

    toggler.on();
  }

  function thumbnailErrorHandler() {
    if (retryCount === 1) { // only first error
      generateThumbnails();
    }
    if (retryCount < 10) {
      const tryAgainInMs = (10 + retryCount * retryCount * 10 * Math.random()) * 1000;
      log(`Try again in ${Math.round(tryAgainInMs)}ms`);
      setTimeout(startPreloading, tryAgainInMs);
    }

    toggler.off();
  }

  function generateThumbnails() {
    const triggerUrl = currentThumbnail.trigger;
    triggerThumbnailGeneration(triggerUrl);
  }

  function triggerThumbnailGeneration(url: string) {
    fetchImg(url)
      .then(() => log(`Triggered thumbnail generation on ${url}`))
      .catch(() => log(`Failed to trigger thumbnail generation on ${url}`));
  }

  player.on(LudoEvents.ITEM_CHANGED, () => {
    unload();
  });

  player.on(LudoEvents.LOADED, () => {
    const thumbnails = player.get('thumbnails');
    const shouldShowThumbnails = thumbnails === true || /vod/i.test(thumbnails);

    if (shouldShowThumbnails && player.mediaType() === LudoMediaTypes.VOD) {
      load();
      return;
    }

    unload();
  });
};
