import { nrkMediaPictureInPicture, nrkMediaPictureInPictureActive } from '@nrk/core-icons';
import { classToggle } from '../../dom';
import { IS_PIP_SUPPORTED, IS_PIP_SUPPORTED_WICG_STANDARD, LudoEvents, LudoOptions } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';
import CustomLudoEvents from '../../../cast/CustomLudoEvents';

export default (ui: LudoUIType, { isPIPSupported = IS_PIP_SUPPORTED } = {}) => {

  if (!isPIPSupported) {
    return () => {};
  }

  const button = ui.element.querySelector<HTMLButtonElement>('button[name="picture-in-picture"]')!;
  button.innerHTML = nrkMediaPictureInPicture;
  button.setAttribute('aria-label', 'Bilde i bilde, Av');
  button.removeAttribute('hidden');

  function renderState(isActive: boolean) {
    button.innerHTML = isActive ? nrkMediaPictureInPictureActive : nrkMediaPictureInPicture;
    button.setAttribute('aria-label', `Bilde i bilde, ${isActive ? 'På' : 'Av'}`);
    classToggle(button, 'ludo-bar__button--pip-on', isActive);
    classToggle(button, 'ludo-bar__button--pip-off', !isActive);
  }

  return (player: ExtendedLudo) => {
    player.on(CustomLudoEvents.CHROMECASTON, () => (button.disabled = true));
    player.on(CustomLudoEvents.CHROMECASTOFF, () => (button.disabled = false));
    player.on(LudoEvents.PIPON, () => renderState(true));
    player.on(LudoEvents.PIPOFF, () => renderState(false));
    button.addEventListener('click', (event) => {
      event.stopPropagation();

      const videoElement = player.get(LudoOptions.VIDEO_ELEMENT);
      const inlinePresentationMode = videoElement.webkitPresentationMode === 'inline'
        || (IS_PIP_SUPPORTED_WICG_STANDARD(document) && !document.pictureInPictureElement);

      player.emit(inlinePresentationMode ? LudoEvents.PIPON : LudoEvents.PIPOFF);
    });
  };
};
