import { LudoBufferTypes, LudoEvents, storage } from '@nrk/ludo-core';
import debounce from 'lodash/debounce';
import PersistentStoreKeys from '../../ludo/PersistentStoreKeys';
import { ExtendedLudo } from '../../ludo/interfaces';

const MUTE_VOLUME = 0.01;

const saveVolume = debounce((volume: number) => {
  storage.persistent.setItem(PersistentStoreKeys.VOLUME, `${volume}`);
}, 1000, { trailing: true, leading: false });

export default (player: ExtendedLudo) => {
  let hasPlaybackStarted = false;

  function playLive() {
    if (hasPlaybackStarted) {
      player.seekTo(player.duration());
    }
    if (player.isPaused()) {
      // tslint:disable-next-line:no-floating-promises
      player.play({ userInteraction: true });
    }
  }

  function playpause() {
    const current = player.current();
    const hasBuffer = current && current.bufferType !== LudoBufferTypes.NONE;

    if (hasBuffer) {
      // tslint:disable-next-line:no-floating-promises
      player.playpause({ userInteraction: true });
    } else if (player.isPaused()) {
      playLive();
    } else {
      player.pause();
    }
  }

  function setVolume(newVolume: number) {
    if (newVolume < MUTE_VOLUME && !player.isMuted()) {
      // tslint:disable-next-line:no-floating-promises
      player.mute();
      player.setVolume(0);
      return;
    }

    if (newVolume >= MUTE_VOLUME && player.isMuted()) {
      // tslint:disable-next-line:no-floating-promises
      player.unmute();
    }

    if (newVolume < 0 || newVolume > 1) {
      return;
    }

    // Save volume if not ChromeCast
    if (player.adapterName() !== 'LudoCastPlayerAdapter') {
      saveVolume(newVolume);
    }

    player.setVolume(newVolume);
  }

  function adjustVolume(change: number) {
    const volume = player.isMuted() ? 0 : player.volume();

    let newVolume = volume + change;
    if (newVolume > 1) {
      newVolume = 1;
    } else if (newVolume < 0) {
      newVolume = 0;
    }

    setVolume(newVolume);
  }

  player.on(LudoEvents.PREPARED, () => {
    hasPlaybackStarted = false;

    player.once(LudoEvents.PLAYING, () => {
      hasPlaybackStarted = true;
    });
  });

  return {
    playLive,
    playpause,
    setVolume,
    adjustVolume
  };
};
