import { classToggle } from '../../dom';
import { nrkMediaPlay, nrkMediaPause, nrkMediaStop } from '@nrk/core-icons';
import { LudoBufferTypes, LudoEvents } from '@nrk/ludo-core';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType, button: HTMLButtonElement, className = '') => {
  return (player: ExtendedLudo) => {
    function toggle(isPlaying = true) {
      const current = player.current();
      const hasBuffer = current && current.bufferType !== LudoBufferTypes.NONE;

      button.setAttribute('aria-label', isPlaying ? 'Pause' : 'Spill av');
      button.innerHTML = isPlaying ? (hasBuffer ? nrkMediaPause : nrkMediaStop) : nrkMediaPlay;

      classToggle(button, `${className}--play`, !isPlaying);
      classToggle(button, `${className}--pause`, isPlaying);
    }

    toggle(false);

    player.on(LudoEvents.PLAYING, () => toggle(true));
    player.on(LudoEvents.PAUSE, () => toggle(false));
    player.on(LudoEvents.ENDED, () => toggle(false));
  };
};
