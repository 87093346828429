import { IS_MOBILE, SUPPORTS_TOUCH } from '@nrk/ludo-core';
import EventEmitter from 'eventemitter3';
import html from './template.html';
import importedResponsive from './responsive';
import { PlayerDimensions } from './playerDimensions';
import importedLoadStyleSheet from '../assets/loadStyleSheet';
import uiEvents from './events';

let loadStyleSheet: typeof importedLoadStyleSheet;
let responsive: typeof importedResponsive;

export const StyleOptions = {
  audio: ['ludo', 'ludo--audio', 'ludo--inverted'],
  radio: ['ludo', 'ludo--audio', 'ludo--radio']
};
export const OuterStyleOptions = {
  audio: ['ludo', 'ludo--audio', 'ludo--inverted'],
  radio: ['ludo', 'ludo--audio', 'ludo--radio']
};
export const AspectRatioOptions = {
  '16:9': '16x9',
  '2.35:1': '235x1',
  '2:1': '2x1',
  '4:3': '4x3',
  '1:1': '1x1',
  '2:3': '2x3',
  '9:16': '9x16'
};

interface InjectedFunctionsContainer {
  loadStyleSheet?: typeof importedLoadStyleSheet;
  responsive?: typeof importedResponsive;
}

interface LudoUIElements {
  targetElement: HTMLElement;
  stageElement: HTMLElement;
}

interface InsertOptions {
  parent?: string | HTMLElement;
  position?: ':after' | ':before' | ':first';
  positionTarget?: HTMLElement;
}

export interface UIState extends LudoUIOptions {
  breakpoint?: string;
  'fullscreen-enabled'?: boolean;
  'pinned-control-overlay'?: boolean;
  'subtitles-displayed'?: boolean;
  'scrubber-ignore-mouse-move'?: boolean;
  'player-dimensions'?: PlayerDimensions;
  targetElement: HTMLElement;
}

type OptionalKeys<T> = { [K in keyof T]-?: ({} extends { [P in K]: T[P] } ? K : never) }[keyof T];
type OptionalUIState = Pick<UIState, OptionalKeys<UIState>>;

export class LudoUI extends EventEmitter {
  element: HTMLElement;
  private state: UIState;
  showControlsOnFocusPrevented = false;
  private preventShowControlsOnFocusTimeoutID?: number;

  constructor(elements: LudoUIElements, options: LudoUIOptions = {}) {
    super();

    this.element = elements.stageElement;
    const { targetElement } = elements;
    const { style, aspectRatio } = options;
    const modifier = aspectRatio && AspectRatioOptions[aspectRatio];

    if (style) {
      StyleOptions[style].forEach((name) => this.element.classList.add(name));
      OuterStyleOptions[style].forEach((name) => targetElement.classList.add(name));
    }
    if (modifier) {
      this.element.classList.add(`ludo--${modifier}`);
    }

    loadStyleSheet();

    this.state = {
      targetElement,
      ...options
    };

    // Insert template
    this.element.insertAdjacentHTML('beforeend', html);
    targetElement.classList.add(`ludo--${IS_MOBILE ? 'mobile' : 'desktop'}`);
    targetElement.classList.add(`ludo--${SUPPORTS_TOUCH ? 'touch' : 'no-touch'}`);

    responsive(this);
  }

  insert(el: HTMLElement, options: InsertOptions = {}) {
    const parent = (() => {
      if (typeof options.parent === 'string') {
        return this.element.querySelector(options.parent);
      }
      if (typeof typeof options.parent === 'object') {
        return options.parent;
      }
      return this.element;
    })();
    if (!parent) {
      throw Error('Missing parent element.');
    }

    const { position, positionTarget } = options;

    if (position === ':after'
      && positionTarget
      && positionTarget.parentElement === parent
      && positionTarget.nextSibling) {
      parent.insertBefore(el, positionTarget.nextSibling);
    } else if (position === ':before'
      && positionTarget
      && positionTarget.parentElement === parent) {
      parent.insertBefore(el, positionTarget);
    } else if (position === ':first' && parent.firstChild) {
      parent.insertBefore(el, parent.firstChild);
    } else {
      parent.appendChild(el);
    }
  }

  get<T extends keyof UIState>(option: T, defaultValue?: any): UIState[T] {
    const value = this.state[option];
    if (typeof value === 'undefined') {
      return defaultValue;
    }
    return value;
  }

  set<T extends keyof OptionalUIState>(option: T): void;
  set<T extends keyof UIState>(option: T, value: UIState[T]): void;
  set<T extends keyof UIState>(option: T, value?: UIState[T]): void {
    const oldValue = this.state[option];
    // The exclamation point below is safe because of the signature overload
    // which ensures that only optional keys can be used without a value.
    this.state[option] = value!;
    if (oldValue !== value) {
      this.emit(uiEvents.OPTIONCHANGED, option, value, oldValue);
    }
  }

  enableControls() { this.emit(uiEvents.ENABLECONTROLS); }
  disableControls() { this.emit(uiEvents.DISABLECONTROLS); }
  showControls() { this.emit(uiEvents.SHOWCONTROLS); }
  hideControls() { this.emit(uiEvents.HIDECONTROLS); }
  chromecastConsole() { this.emit('chromecast-console'); }
  chromecastDebug() { this.emit('chromecast-debug'); }

  preventShowControlsOnFocus(preventForMs: number = 200) {
    this.showControlsOnFocusPrevented = true;
    if (this.preventShowControlsOnFocusTimeoutID) {
      window.clearTimeout(this.preventShowControlsOnFocusTimeoutID);
      delete this.preventShowControlsOnFocusTimeoutID;
    }
    this.preventShowControlsOnFocusTimeoutID = window.setTimeout(() => {
      this.showControlsOnFocusPrevented = false;
    }, preventForMs);
  }
}

export interface LudoUIOptions {
  'pinned-control-overlay'?: boolean;
  version?: string;
  style?: keyof typeof StyleOptions;
  aspectRatio?: keyof typeof AspectRatioOptions;
}

export default (
  elements: LudoUIElements,
  options: LudoUIOptions,
  injectedFuncs: InjectedFunctionsContainer = {}
) => {
  ({
    loadStyleSheet = importedLoadStyleSheet,
    responsive = importedResponsive
  } = injectedFuncs);

  return new LudoUI(elements, options);
};

export type LudoUIType = LudoUI;
