import { classToggle } from '../../../dom';
import UI_EVENTS from '../../../ui/events';
import UI_OPTIONS from '../../../ui/options';
import SCRUBBER_EVENTS from '../scrubberEvents';
import { ScrubberContext } from '../index';

const LOAD_STATE = {
  idle: 'idle',
  loading: 'loading',
  loaded: 'loaded'
};

export default ({ ui, thumbnailElement, scrubberEvents, progressBarElement }: ScrubberContext) => {

  const imageElement = thumbnailElement.appendChild(document.createElement('img'));
  imageElement.setAttribute('aria-hidden', 'true');
  imageElement.className = 'ludo-thumbnail__image';

  let loadState = LOAD_STATE.idle;
  let isMoving = false;
  let breakpoint = ui.get(UI_OPTIONS.BREAKPOINT);
  let isImageHidden = false;
  let imageWidth: number;
  let imageHeight: number;
  let doShow: boolean;
  let previousVisibility = {
    isVisible: false,
    hasImage: false
  };

  ui.on(UI_EVENTS.BREAKPOINT, (key) => (breakpoint = key));

  function updateVisibility() {
    const hasImage = loadState === LOAD_STATE.loaded && isMoving && !isImageHidden;
    const isVisible = doShow && hasImage;

    classToggle(imageElement, 'ludo-thumbnail__image--off', !hasImage);
    classToggle(thumbnailElement, 'ludo-thumbnail--on', !!isVisible);

    if (isVisible !== previousVisibility.isVisible || hasImage !== previousVisibility.hasImage) {
      scrubberEvents.emit(SCRUBBER_EVENTS.THUMBNAIL_VISIBILITY_CHANGED, { isVisible, hasImage });
    }
    previousVisibility = { isVisible, hasImage };
  }

  scrubberEvents.on(SCRUBBER_EVENTS.MOVEEND, () => {
    isMoving = false;
  });

  scrubberEvents.on(SCRUBBER_EVENTS.THUMBNAIL_LOADING, () => {
    loadState = LOAD_STATE.loading;
    updateVisibility();
  });

  scrubberEvents.on(SCRUBBER_EVENTS.THUMBNAIL_LOADED, (options) => {
    const {
      src,
      width = 320,
      height = 180,
      rows = 1,
      cols = 1
    } = options;

    imageWidth = width;
    imageHeight = height;

    const isStretched = rows === 1 && cols === 1;
    Object.assign(imageElement.style, {
      width: isStretched ? `${cols * width}px` : 'auto',
      height: isStretched ? `${rows * height}px` : 'auto'
    });
    imageElement.setAttribute('src', src);
    loadState = LOAD_STATE.loaded;
    updateVisibility();
  });

  scrubberEvents.on(SCRUBBER_EVENTS.THUMBNAIL_REMOVE, () => {
    imageElement.style.backgroundImage = '';
    loadState = LOAD_STATE.idle;
    updateVisibility();
  });

  scrubberEvents.on(SCRUBBER_EVENTS.THUMBNAIL_HIDE_IMAGE, () => {
    isImageHidden = true;
    updateVisibility();
  });

  scrubberEvents.on(SCRUBBER_EVENTS.THUMBNAIL_POSITION_CHANGED, (details) => {
    const { row = 0, column = 0, left = 0 } = details;
    const zoomFactor = breakpoint === 'medium' ? 0.5 : 1;
    const halfWidth = (imageWidth / 2) * zoomFactor;
    const progressBarWidth = progressBarElement.offsetWidth || 0;

    let leftPos = left;
    leftPos = Math.min(leftPos, progressBarWidth - halfWidth);
    leftPos = Math.max(leftPos, halfWidth);

    imageElement.style.left = `${column * -imageWidth}px`;
    imageElement.style.top = `${row * -imageHeight}px`;
    thumbnailElement.style.left = `${leftPos - halfWidth}px`;

    isMoving = true;
    if (loadState === LOAD_STATE.loaded) {
      isImageHidden = false;
    }

    updateVisibility();
  });

  scrubberEvents.on(SCRUBBER_EVENTS.LABEL_CHANGED, (text) => {
    doShow = typeof text !== 'undefined';
    updateVisibility();
  });

};
