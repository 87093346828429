import { IS_PIP_SUPPORTED, IS_PIP_SUPPORTED_WEBKIT, IS_PIP_SUPPORTED_WICG_STANDARD, LudoEvents } from '@nrk/ludo-core';
import LudoUIOptions from '../ui/ui/options';
import { ExtendedLudo } from './interfaces';
import { LudoUIType } from '../ui/ui/LudoUI';

declare global {
  interface HTMLMediaElement {
    webkitSupportsPresentationMode?: boolean;
    webkitSetPresentationMode(mode: string): void;
    requestPictureInPicture(): void;
    webkitPresentationMode: string;
  }
}

export interface PictureInPictureArgument {
  player: ExtendedLudo;
  videoElement: HTMLMediaElement;
  document: Document;
  ui: LudoUIType;
  isPIPSupported?: boolean;
  isPipEnabled?: boolean;
}

export default ({ player, videoElement, ui, document, isPIPSupported = IS_PIP_SUPPORTED, isPipEnabled = false } = {} as PictureInPictureArgument) => {
  if (!isPIPSupported) {
    return;
  }
  let enabled = isPipEnabled; // internal state

  const closePIPEventHandler = () => {
    if (enabled) {
      player.emit(LudoEvents.PIPOFF);
    }
  };

  videoElement.addEventListener('webkitendfullscreen', closePIPEventHandler);
  videoElement.addEventListener('leavepictureinpicture', closePIPEventHandler);

  player.on(LudoEvents.PIPON, () => {
    if (IS_PIP_SUPPORTED_WEBKIT(document)) {
      videoElement.webkitSetPresentationMode('picture-in-picture');
    } else if (IS_PIP_SUPPORTED_WICG_STANDARD(document)) {
      videoElement.requestPictureInPicture();
    }
    ui.set(LudoUIOptions.PINNED_CONTROL_OVERLAY, true);
    ui.set(LudoUIOptions.SUBTITLES_DISPLAYED, false);

    enabled = true;
  });

  player.on(LudoEvents.PIPOFF, () => {
    // Set presentation mode, if it has not already been triggered by e.g. close button
    if (videoElement.webkitPresentationMode === 'picture-in-picture') {
      videoElement.webkitSetPresentationMode('inline');
    } else if (!!document.pictureInPictureElement) {
      Promise.resolve(document.exitPictureInPicture()).catch((err) => {
        // Ignore.
      });
    }
    ui.set(LudoUIOptions.PINNED_CONTROL_OVERLAY, false);
    ui.set(LudoUIOptions.SUBTITLES_DISPLAYED, true);

    enabled = false;
  });
};
