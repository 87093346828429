type EPGProgram = import('../../../nrk/LiveEpg/types').EPGProgram;

interface Blank {
  type: 'emptyProgram';
  programId: string;
  title: string;
  actualStartUTC: number;
  actualEndUTC: number;
  duration: number;
}

interface LayoutModel {
  startPosition: number;
  endPosition: number;
  leftPc: number;
  widthPc: number;
  playedWidthPc: number;
  leftPx: number;
  widthPx: number;
  isCurrentProgram: boolean;
  visibleDuration: number;
  adjustedWidthPx: number;
  adjustedWidthPc: number;
  adjustedPlayedWidthPc: number;
  playsIntoFuture: boolean;
  startsInFuture: boolean;
  beginningOutsideBuffer: boolean;
  isLastVisibleProgram: boolean;
}

export type EPGProgramOrBlank = EPGProgram | Blank;
export type EPGScrubberItem = LayoutModel & EPGProgramOrBlank;

const PROGRAM_GAP_WIDTH = 6;

function getEmptyProgram(duration: number): Blank {
  return {
    programId: 'emptyProgram',
    title: 'Ingen sending',
    actualStartUTC: Date.now() - duration * 1000,
    actualEndUTC: Date.now(),
    duration,
    type: 'emptyProgram'
  };
}

export const appendEmptyPrograms = (cachedEpg: EPGProgram[], duration: number): EPGProgramOrBlank[] => {
  const epgList: EPGProgramOrBlank[] = [];
  let fixedEpg: EPGProgramOrBlank[];

  if (cachedEpg.length > 1) {
    fixedEpg = cachedEpg.slice();

    const firstProgram = fixedEpg[0];
    const lastProgram = fixedEpg[fixedEpg.length - 1];

    fixedEpg = [
      {
        ...getEmptyProgram(duration),
        actualStartUTC: Date.now() - duration * 1000 * 2, // Moving start position way out of scrubber
        actualEndUTC: firstProgram.actualStartUTC
      },
      ...cachedEpg,
      {
        ...getEmptyProgram(duration),
        actualStartUTC: lastProgram.actualEndUTC
      }
    ];
  } else {
    fixedEpg = [getEmptyProgram(duration)];
  }

  fixedEpg
    .sort((a, b) => a.actualStartUTC - b.actualStartUTC)
    .forEach((program, index) => {
      epgList.push(program);

      const nextProgram = fixedEpg[index + 1];
      if (nextProgram) {
        program.actualEndUTC = nextProgram.actualStartUTC;
      }
    });

  return epgList;
};

export const mapToLayoutModel = (epg: EPGProgramOrBlank[], currentTime: number, duration: number, progressBarWidth: number): EPGScrubberItem[] => {
  return epg
    .map((program) => {
      const { startPosition, endPosition } = program as EPGScrubberItem;

      const visiblePlayed = currentTime >= startPosition ?
        Math.min(endPosition, currentTime) - startPosition : 0;

      const visibleDuration = endPosition - startPosition;
      const leftPc = startPosition / duration * 100;
      const widthPc = visibleDuration / duration * 100;
      const playedWidthPc = visiblePlayed / duration * 100;
      const leftPx = leftPc * progressBarWidth / 100;
      const widthPx = widthPc * progressBarWidth / 100;
      const isCurrentProgram = currentTime >= startPosition && currentTime <= endPosition;

      return {
        ...program,
        visibleDuration,
        leftPc,
        leftPx,
        widthPc,
        widthPx,
        isCurrentProgram,
        playedWidthPc
      };
    })
    .map((program, index, orderedEpg) => {
      const { leftPx, leftPc, widthPx, widthPc, playedWidthPc } = program;
      let adjustedWidthPc = widthPc;
      let adjustedWidthPx = widthPx;
      let isLastVisibleProgram = false;

      const nextProgram = orderedEpg[index + 1];
      if (nextProgram) {
        const nextLeftPx = nextProgram.leftPx;
        const gap = nextLeftPx - leftPx + widthPx;
        const gapWidth = PROGRAM_GAP_WIDTH;

        if (program.leftPc < 100 && nextProgram.leftPc > 100) {
          isLastVisibleProgram = true;
        }

        if (gap > gapWidth) {
          adjustedWidthPx = Math.max(widthPx - gapWidth, 1);
          adjustedWidthPc = adjustedWidthPx * 100 / progressBarWidth;
        }
      } else {
        isLastVisibleProgram = true;
      }

      const playsIntoFuture = (leftPc < 100) && (leftPc + adjustedWidthPc > 100);
      const startsInFuture = leftPc > 100;
      const beginningOutsideBuffer = leftPc < 0;
      const adjustedPlayedWidthPc = Math.min(playedWidthPc, adjustedWidthPc);

      return {
        ...program,
        adjustedWidthPx,
        adjustedWidthPc,
        adjustedPlayedWidthPc,
        playsIntoFuture,
        startsInFuture,
        beginningOutsideBuffer,
        isLastVisibleProgram
      } as EPGScrubberItem;
    })
    .filter((model) => model.visibleDuration > 0);
};
