import { buildElement as buildElementImport } from './builder';
import EventEmitter from 'eventemitter3';
import { LudoUIType } from '../../ui/LudoUI';

interface OverlayOptions {
  actions?: HTMLButtonElement[];
}

export default (ui: LudoUIType, options: OverlayOptions = {}, container = {}) => {
  require('./overlay.styl');

  const {
    buildElement,
    eventEmitter
  } = Object.assign({}, {
    buildElement: buildElementImport,
    eventEmitter: new EventEmitter()
  }, container);

  const overlay = buildElement(ui, eventEmitter);

  if (options.actions && options.actions.length) {
    options.actions.forEach((a) => overlay.addButton(a));
  }

  const toggle = (state?: boolean) => {
    let newVisibleState: boolean;
    if (typeof state === 'boolean') {
      newVisibleState = state;
    } else {
      newVisibleState = !overlay.isVisible();
    }
    if (newVisibleState) {
      overlay.show();
    } else {
      overlay.hide();
    }
    return newVisibleState;
  };

  eventEmitter.on('closed', () => {
    ui.enableControls();
    overlay.unbindKeyEvents();
  });

  eventEmitter.on('opened', () => {
    ui.disableControls();
    overlay.bindKeyEvents();
  });

  return {
    show: overlay.show,
    hide: overlay.hide,
    toggle,
    isVisible: overlay.isVisible,
    content: overlay.content,
    on: eventEmitter.on.bind(eventEmitter),
    off: eventEmitter.off.bind(eventEmitter)
  };
};
