import { nrkFullscreen, nrkFullscreenActive } from '@nrk/core-icons';
import { LudoEvents, LudoOptions } from '@nrk/ludo-core';
import options from '../../ui/options';
import events from '../../ui/events';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export default (ui: LudoUIType) => {
  const button = ui.element.querySelector<HTMLElement>('button[name="fullscreen"]')!;

  return (player: ExtendedLudo) => {
    function renderState() {
      const isEnabled = ui.get(options.FULLSCREEN_ENABLED);
      const isFullscreen = player.get(LudoOptions.FULLSCREEN);

      button[isEnabled ? 'removeAttribute' : 'setAttribute']('hidden', '');
      button.setAttribute('aria-label', isFullscreen ? 'Avslutt fullskjerm' : 'Fullskjerm');
      button.innerHTML = isFullscreen ? nrkFullscreenActive : nrkFullscreen;
    }

    // Fullscreen support depends on the video element, so wait for it to be
    // prepared, and keep an eye on changes.
    player.on(LudoEvents.PREPARED, () => ui.set(options.FULLSCREEN_ENABLED, player.supportsFullscreen()));
    player.on(LudoEvents.FULLSCREENON, renderState);
    player.on(LudoEvents.FULLSCREENOFF, renderState);
    ui.on('click.fullscreen', () => player.fullscreen());
    ui.on(events.OPTIONCHANGED, (option) => option === options.FULLSCREEN_ENABLED && renderState());
  };
};
