import { LudoUIType } from '../../ui/LudoUI';
import { PlayerDimensions } from '../../ui/playerDimensions';
import { Poster } from '../../../nrk/mediaItem/MediaItem';

const PLAYABLE_CLASSNAME = 'ludo-poster--playable';

interface DetailsElements {
  title?: HTMLElement;
  subtitle?: HTMLElement;
  duration?: HTMLElement;
  'age-number'?: HTMLElement;
  'age-text'?: HTMLElement;
  'accessible-play-button__title'?: HTMLElement;
}
type DisplayDetailsOptions = Partial<Record<keyof DetailsElements, string>>;

export default (ui: LudoUIType, options: DisplayDetailsOptions = {}) => {
  const posterElement = ui.element.querySelector<HTMLElement>('.ludo-poster')!;
  const posterButton = posterElement.querySelector<HTMLButtonElement>('.ludo-poster-button')!;
  const image = posterElement.querySelector('.ludo-poster__image') as HTMLImageElement;
  const playButtonContainer = posterElement.querySelector('.ludo-poster__svg') as HTMLButtonElement;
  const playProgressCircle = posterElement.querySelector('.ludo-poster__play-progress') as SVGCircleElement;
  const liveIndicator = posterElement.querySelector('.ludo-poster__liveindicator') as HTMLElement;
  const titleElement = posterElement.querySelector('.ludo-poster__title') as HTMLElement;

  function getElement(key: string) {
    return posterElement.querySelector<HTMLElement>(`.ludo-poster__${key}`)!;
  }

  const elementKeys: (keyof DetailsElements)[] = [
    'title',
    'subtitle',
    'duration',
    'age-number',
    'age-text',
    'accessible-play-button__title'
  ];

  const detailsElements: DetailsElements = elementKeys.reduce((o, key) => {
    const el = getElement(key);
    if (el) {
      o[key] = el;
    }
    return o;
  }, {} as DetailsElements);

  const hide = () => posterElement.setAttribute('hidden', '');
  const show = () => posterElement.removeAttribute('hidden');

  const displayPlayButton = () => {
    posterButton.removeAttribute('disabled');
    posterElement.classList.add(PLAYABLE_CLASSNAME);
  };

  const hidePlayButton = () => {
    posterButton.setAttributeNode(document.createAttribute('disabled'));
    posterElement.classList.remove(PLAYABLE_CLASSNAME);
  };

  const setPlayProgress = (progress: number) => {
    const strokeDashArray = playProgressCircle.getAttribute('stroke-dasharray');
    const total = parseInt(`${strokeDashArray}`, 10) || 0;
    const multiplier = (100 - progress) / 100;
    const offset = parseInt('' + (total * multiplier), 10);

    playProgressCircle.setAttribute('stroke-dashoffset', `${offset}`);
  };

  const clearPlayProgress = () => setPlayProgress(0);
  const setImage = (src: string) => image.setAttribute('src', src);
  const hasImage = () => !!image.src;

  const setSourceSet = (sources: Poster[]) => {
    if (image.srcset || !sources || !sources.length) {
      return;
    }
    image.srcset = sources.map((source) => `${source.src} ${source.width}w`).join(',\n');
  };

  const onClick = (cb: (...args: any[]) => any) => {
    posterElement.addEventListener('click', cb);
  };

  const displayDetails = (details: DisplayDetailsOptions = {}) => {
    Object.keys(detailsElements)
      .map((k) => k as keyof DetailsElements)
      .forEach((k) => {
      const el = detailsElements[k];
      if (!el) {
        return;
      }

      el.textContent = details[k] || '';

      // Visibility for some (but not all) elements can be dictated throug
      // options. Defaults to true if not set.
      const optedVisible = typeof options[k] === 'undefined' || options[k];
      const display = (optedVisible && el.textContent && el.textContent.length) ? 'block' : 'none';

      el.style.display = display;
    });
  };

  const addShade = () => {
    const shadeElement = posterElement.querySelector('.ludo-poster__shade') as HTMLElement;
    shadeElement.style.display = 'block';
  };

  const updateSizes = (dimensions?: PlayerDimensions) => {
    if (!dimensions) {
      return;
    }

    // 0.95em of the base font size, but don't go below 13px.
    posterElement.style.fontSize = dimensions.getRemFontSize({ scale: 0.95, minPx: 13 });

    // 1.6em of the base font size, but don't go below 18px.
    titleElement.style.fontSize = dimensions.getRemFontSize({ scale: 1.6, minPx: 18 });

    if (playButtonContainer) {
      // Using the shortest side as base for calculating play button size,
      // because it makes it relative to the center square of any aspect ratio.
      const playButtonSize = 10 * dimensions.pmin + 30;
      playButtonContainer.style.width = `${playButtonSize}px`;
    }
  };

  function showLiveIndicator() {
    liveIndicator.removeAttribute('hidden');
  }

  function setAccessibleLabel(label: string) {
    posterButton.setAttribute('aria-label', label);
  }

  return {
    hide,
    show,
    addShade,
    displayPlayButton,
    hidePlayButton,
    setPlayProgress,
    clearPlayProgress,
    setImage,
    setSourceSet,
    hasImage,
    onClick,
    displayDetails,
    updateSizes,
    showLiveIndicator,
    setAccessibleLabel
  };
};
