import { getKey, SubtitlePreference, SubtitlePreferences, SubtitleRef } from './general';

export function findPreferredSubtitle<T extends SubtitleRef>(preferences: SubtitlePreferences, availableSubtitles: T[]) {
  const allKeys = availableSubtitles.map((sub) => getKey(sub));

  const relevantPreferences = Object.keys(preferences) // Filter relevant preferences
    .map((key): [string, SubtitlePreference] => [key, preferences[key]])
    .filter(([key, value]) => allKeys.indexOf(key) !== -1 && value);

  const prioritizedPreferences = relevantPreferences
    .filter(([, sub]) => sub.enabled)
    .map(([key, sub]): [string, number] => {
      let precedesCount = 0;
      if (sub.precedes) {
        precedesCount = sub.precedes
          .filter((p) => allKeys.indexOf(p) !== -1)
          .filter((value, index, self) => self.indexOf(value) === index)
          .length;
      }
      return [key, precedesCount];
    })
    .sort((a, b) => b[1] - a[1]);

  const foundKey = prioritizedPreferences.length ? prioritizedPreferences[0][0] : undefined;
  if (foundKey) {
    return availableSubtitles.filter((sub) => getKey(sub) === foundKey)[0];
  }
  return relevantPreferences.length ? false : undefined;
}
