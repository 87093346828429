import indexPoints from './components/indexPoints';
import slidingTimes from './components/slidingTimes';
import thumbnail from './components/thumbnail';
import playedTime from './components/playedTime';
import playhead from './components/playhead';
import rangeHandler from './components/rangeHandler';
import buffer from './components/buffer';
import epg from './components/epg';
import EventEmitter from 'eventemitter3';
import attachMoveEvent from './attachMoveEvents';
import attachTimeEvents from './attachTimeEvents';
import attachPixelProgressEvent from './attachPixelProgressEvent';
import handleTimeCollision from './handleTimeCollision';
import dvrThumbnailHandler from './thumbnail/dvrThumbnailHandler';
import vodThumbnailHandler from './thumbnail/vodThumbnailHandler';
import dvrHandler from './dvrHandler';
import vodHandler from './vodHandler';
import zoneHandler from './zoneHandler';
import { ZoneModel } from './ZoneModel';
import SCRUBBER_EVENTS from './scrubberEvents';
import { LudoBufferTypes, LudoEvents, LudoMediaTypes } from '@nrk/ludo-core';
import { classToggle } from '../../dom';
import { ExtendedLudo } from '../../../ludo/interfaces';
import { LudoUIType } from '../../ui/LudoUI';

export interface ScrubberContext {
  rangeElement: HTMLInputElement;
  playheadElement: HTMLElement;
  playedElement: HTMLElement;
  progressBarElement: HTMLElement;
  thumbnailElement: HTMLElement;
  leftTimeElement: HTMLElement;
  rightTimeElement: HTMLElement;
  currentTimeElement: HTMLElement;
  pointerTimeElement: HTMLElement;
  clickZoneElement: HTMLElement;
  player: ExtendedLudo;
  scrubberElement: HTMLElement;
  ui: LudoUIType;
  scrubberEvents: EventEmitter;
  controller: HTMLElement;
  zoneModel: ZoneModel;
}

const CLASS_CLICKZONE = 'ludo-scrubber__clickzone--drag';

export default (ui: LudoUIType) => {

  require('./scrubber.styl');

  const controller = ui.element.querySelector<HTMLElement>('.ludo-controller')!;
  const scrubberElement = ui.element.querySelector<HTMLElement>('.ludo-scrubber')!;
  const progressBarElement = scrubberElement.querySelector<HTMLElement>('.ludo-progressbar')!;
  const clickZoneElement = scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__clickzone')!;

  const scrubberEvents = new EventEmitter();

  scrubberEvents.on(SCRUBBER_EVENTS.DRAGSTART, () => clickZoneElement.classList.add(CLASS_CLICKZONE));
  scrubberEvents.on(SCRUBBER_EVENTS.DRAGEND, () => clickZoneElement.classList.remove(CLASS_CLICKZONE));

  const zoneModel = new ZoneModel();

  return (player: ExtendedLudo) => {

    const context: ScrubberContext = {
      rangeElement: scrubberElement.querySelector<HTMLInputElement>('.ludo-scrubber-range')!,
      playheadElement: scrubberElement.querySelector<HTMLElement>('.ludo-playhead')!,
      playedElement: scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__played')!,
      thumbnailElement: scrubberElement.querySelector<HTMLElement>('.ludo-thumbnail')!,
      leftTimeElement: scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__time--start')!,
      rightTimeElement: scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__time--end')!,
      currentTimeElement: scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__time--current')!,
      pointerTimeElement: scrubberElement.querySelector<HTMLElement>('.ludo-scrubber__time--pointer')!,
      clickZoneElement,
      progressBarElement,
      scrubberElement,
      player,
      ui,
      scrubberEvents,
      controller,
      zoneModel
    };

    [
      indexPoints,
      thumbnail,
      playedTime,
      playhead,
      rangeHandler,
      buffer,
      epg,
      attachMoveEvent,
      attachTimeEvents,
      attachPixelProgressEvent,
      dvrThumbnailHandler,
      vodThumbnailHandler,
      handleTimeCollision,
      slidingTimes,
      dvrHandler,
      vodHandler,
      zoneHandler
    ].forEach((comp: any) => comp(context));

    player.on(LudoEvents.LOADED, () => {
      const isDVR = player.mediaType() === LudoMediaTypes.DVR;
      const current = player.current()!;
      const hasBuffer = current.bufferType !== LudoBufferTypes.NONE;

      scrubberElement.style.display = hasBuffer ? '' : 'none';

      classToggle(progressBarElement, 'ludo-progressbar--dvr', isDVR);
      classToggle(scrubberElement, 'ludo-scrubber--dvr', isDVR);
      classToggle(scrubberElement, 'ludo-scrubber--live', current.isLive);
    });
  };
};
