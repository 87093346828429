/**
 * Loader for /playback/metadata[/{type}]?/{id}.
 *
 * Note: If type is not specified, the loader is redirected to a URL which
 * contains a type. Upon successful response, the loader updates its path to
 * point to the redirected one.
 */

import { PathLoader } from '../PathLoader';
import { PlaybackManifestLoader } from './PlaybackManifestLoader';
import { MediaTypes } from '@nrk/ludo-core';

type APIClient = import('../types').APIClient;
type PlaybackMetadata = import('./response/IPlaybackMetadata').IPlaybackMetadata;

interface Links {
  manifests: {
    [key: string]: PlaybackManifestLoader;
  };
  next?: PlaybackMetadataLoader;
}

export class PlaybackMetadataLoader extends PathLoader<PlaybackMetadata, Links> {
  /**
   * id can be:
   * - a full path to /playback/metadata/...
   * - a single identifier.
   * - an array of sub-identifiers that will be used together in the URL in the
   *   provided order.
   */
  constructor(client: APIClient, id: string | string[], type?: MediaTypes) {
    if (typeof id === 'string' && id.charAt(0) === '/') {
      super(client, id);
    } else {
      const prefix = '/playback/metadata';
      const idArray = Array.isArray(id) ? id : [id];
      const path = [prefix, type, ...idArray].filter((s) => s).join('/');
      super(client, path);
    }
  }

  protected processLinks(data: PlaybackMetadata): Links {
    const links: Links = { manifests: {} };

    if (data._links) {
      if (data._links.self && data._links.self.href) {
        this.path = data._links.self.href;
      }

      if (data._links.manifests) {
        data._links.manifests.forEach((link) => {
          if (link.name && link.href) {
            links.manifests[link.name] = new PlaybackManifestLoader(this.client, link.href);
          }
        });
      }

      if (data._links.next) {
        links.next = new PlaybackMetadataLoader(this.client, data._links.next.href);
      }
    }

    return links;
  }
}
