import debounce from 'lodash/debounce';
// Inspired by https://github.com/sdecima/javascript-detect-element-resize

export function ResizeSensor(element: HTMLElement, callback: () => any) {
  const expand = document.createElement('div');
  expand.style.cssText = 'position:absolute;top:0;left:0;right:0;bottom:0;overflow:hidden;visibility:hidden';
  expand.innerHTML = '<div style="position:absolute;top:0;left:0;width:10000000px;height:10000000px"></div>';

  const shrink = document.createElement('div');
  shrink.style.cssText = 'position:absolute;top:0;left:0;right:0;bottom:0;overflow:hidden;visibility:hidden';
  shrink.innerHTML = '<div style="position:absolute;top:0;left:0;width:200%;height:200%"></div>';

  element.appendChild(expand);
  element.appendChild(shrink);

  function setScroll() {
      expand.scrollLeft =
      shrink.scrollLeft =
      expand.scrollTop =
      shrink.scrollTop = 10000000;
  }
  setScroll();

  const size = element.getBoundingClientRect();
  let currentWidth = size.width;
  let currentHeight = size.height;

  const onScroll = debounce(() => {
    const size = element.getBoundingClientRect();
    const newWidth = size.width;
    const newHeight = size.height;

    if (newWidth !== currentWidth || newHeight !== currentHeight) {
      currentWidth = newWidth;
      currentHeight = newHeight;
      callback();
    }
    setScroll();
  }, 50);

  element.addEventListener('scroll', onScroll, true); // Use capture to detect scroll in children
}
