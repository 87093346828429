import { MediaItem } from '../MediaItem';

function getFormattedTime(date: Date) {
  const parts = [
    date.getHours(),
    date.getMinutes(),
    date.getDate(),
    date.getMonth() + 1
  ].map((n) => {
    return n > 9 ? `${n}` : `0${n}`;
  });

  return `kl. ${parts[0]}:${parts[1]}, den ${parts[2]}.${parts[3]}.${date.getFullYear()}`;
}

function getMediaRightsExpiredMessage(mediaItem: MediaItem): string {
  if (mediaItem.isLive) {
    return 'Direktesendingen er over.';
  }
  return 'Ikke tilgjengelig lenger.';
}

function getMediaIsGeoBlockedMessage(mediaItem: MediaItem): string {
  return 'Programmet kan ikke vises i utlandet.';
}

function getMediaRightsNotReadyMessage(mediaItem: MediaItem): string {
  if (!mediaItem.isLive) {
    return 'Programmet er ikke tilgjengelig ennå.';
  }
  const { availableFrom } = mediaItem;
  if (!availableFrom) {
    return 'Direktesendingen har ikke startet ennå.';
  }
  return `Direktesendingen starter ${getFormattedTime(availableFrom)}`;
}

function getChannelUnavailableMessage(mediaItem: MediaItem): string {
  return `Det er ingen pågående sending på ${mediaItem.title} nå.`;
}

export default {
  ProgramRightsHasExpired: getMediaRightsExpiredMessage,
  ClipRightsHasExpired: getMediaRightsExpiredMessage,
  ProgramRightsAreNotReady: getMediaRightsNotReadyMessage,
  ProgramRightsHasNotStarted: getMediaRightsNotReadyMessage,
  ProgramIsGeoblocked: getMediaIsGeoBlockedMessage,
  ClipIsGeoblocked: getMediaIsGeoBlockedMessage,
  ChannelIsGeoblocked: getMediaIsGeoBlockedMessage,
  ChannelUnavailable: getChannelUnavailableMessage
};
