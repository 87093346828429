import { LudoEvents } from '@nrk/ludo-core';
import { SkipSection, SkipSectionStates } from './SkipSection';
import { ExtendedLudo } from '../interfaces';

export const SkipSectionChangedEvent = 'skip-section-changed';

export type SkipSectionType = 'intro' | 'credits';
export interface SkipSectionChangedData {
  section: SkipSectionType;
  state: SkipSectionStates;
}

export function detectSkipSections(player: ExtendedLudo) {
  let introSection: SkipSection | undefined;
  let creditsSection: SkipSection | undefined;
  let allSections: SkipSection[] = [];

  function reset() {
    allSections.forEach((section) => {
      section.destroy();
    });
    allSections = [];
  }

  player.on(LudoEvents.PLAYBACKSTARTED, () => {
    reset();

    const current = player.current();

    if (!current || !current.interactionPoints) {
      return;
    }

    const { intro, nextUp } = current.interactionPoints;

    allSections = [];

    if (intro && (intro.endTime - intro.startTime) > 1) {
      introSection = new SkipSection({
        // skip intro at least 3 seconds into video
        start: Math.max(3, Math.floor(intro.startTime)),
        end: intro.endTime,
        timeout: (intro.endTime - intro.startTime) * 1000,
        maxCount: 10
      });
      introSection.listen((state) => {
        player.ui.emit(SkipSectionChangedEvent, { section: 'intro', state });
      });
      allSections.push(introSection);
    }

    if (current.hasNextItem && nextUp && nextUp.startTime < current.duration) {
      creditsSection = new SkipSection({
        start: nextUp.startTime,
        end: Math.min(nextUp.endTime, current.duration),
        timeout: 10000,
        maxCount: 1
      });
      creditsSection.listen((state) => {
        player.ui.emit(SkipSectionChangedEvent, { section: 'credits', state });
      });
      allSections.push(creditsSection);
    }
  });

  player.on(LudoEvents.TIMEUPDATE, () => {
    const currentTime = player.currentTime();
    allSections.forEach((section) => {
      section.currentTime(currentTime);
    });
  });

  player.on(LudoEvents.ITEM_CHANGED, () => {
    reset();
  });
}
