import logger from '../logger';
import CastContext = cast.framework.CastContext;
import CastSession = cast.framework.CastSession;

export default ({
  log = logger('cast:media')
} = {}) => {

  function getEstimatedTime() {
    const media = getCastSessionMedia();
    return media ? media.getEstimatedTime() : 0;
  }

  function mediaAction(action: (media: chrome.cast.media.Media | any) => any) {
    const media = getCastSessionMedia();
    if (!media || !action) {
      return;
    }
    action(media);
  }

  // @ts-ignore
  return {
    getMedia: getCastSessionMedia,
    getEstimatedTime,
    play: () => mediaAction((media) => media.play()),
    pause: () => mediaAction((media) => media.pause()),
    stop: () => mediaAction((media) => media.stop()),
    setVolume: (newVolume: number) => mediaAction((media) => {
      const request = new window.chrome.cast.media.VolumeRequest({ level: newVolume, muted: false });
      media.setVolume(request);
    }),
    seekTo: (time: number) => mediaAction((media) => {
      const request = new window.chrome.cast.media.SeekRequest();
      request.currentTime = time;
      media.seek(request);
    }),
    reflectMediaStatus: () => mediaAction((media) => media.getStatus())
  };
};

export function getCastSessionMedia(): chrome.cast.media.Media | null {
  return [
    (window: Window) => window.cast,
    (cast: typeof window.cast) => cast.framework.CastContext.getInstance(),
    (instance: CastContext) => instance.getCurrentSession(),
    (session: CastSession) => session.getSessionObj(),
    (sessionObj: chrome.cast.Session) => sessionObj.media[0]
  ].reduce((prev, func: (a: any) => any) => {
    if (!prev) {
      return null;
    }
    return func(prev);
  }, window) as any;
}
