import { SubtitlePreferences, getKey, SubtitleRef } from './general';

export function applyDisabledToSubtitles(preferences: SubtitlePreferences, availableSubtitles: SubtitleRef[]) {
  preferences = { ...preferences };
  const allKeys = availableSubtitles.map((sub) => getKey(sub));
  allKeys.forEach((key) => {
    const preference = preferences[key] || {};
    preferences[key] = {
      ...preference,
      enabled: false
    };
  });
  return preferences;
}
