import EventEmitter = require('eventemitter3');

enum ConnectedState {
  CONNECTED,
  DISCONNECTED
}

export class EventBinder {
  private connectedState: ConnectedState = ConnectedState.CONNECTED;
  private emitter: EventEmitter;
  private boundEvents: [string, (...args: any[]) => any][];

  constructor(emitter: EventEmitter) {
    this.emitter = emitter;
    this.boundEvents = [];
  }

  on(event: string, listener: (...args: any[]) => any) {
    this.boundEvents.push([event, listener]);
    if (this.connectedState === ConnectedState.DISCONNECTED) {
      return;
    }

    this.emitter.on(event, listener);
  }

  off(event: string, listener: (...args: any[]) => any) {
    this.boundEvents = this.boundEvents.filter(([e, l]) => e !== event || l !== listener);
    this.emitter.off(event, listener);
  }

  removeAllListeners() {
    this.boundEvents.forEach(([event, listener]) => {
      this.emitter.off(event, listener);
    });
    this.boundEvents = [];
  }

  disconnect() {
    if (this.connectedState === ConnectedState.DISCONNECTED) {
      return;
    }
    this.connectedState = ConnectedState.DISCONNECTED;
    this.boundEvents.forEach(([event, listener]) => {
      this.off(event, listener);
    });
  }

  connect() {
    if (this.connectedState === ConnectedState.CONNECTED) {
      return;
    }
    this.connectedState = ConnectedState.CONNECTED;
    this.boundEvents.forEach(([event, listener]) => {
      this.on(event, listener);
    });
  }
}
