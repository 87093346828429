// https://github.com/micnews/idle-timer/blob/master/index.js

export default function onIdle(callback: () => void, timeout: number) {
  let timer: number;

  document.addEventListener('mousemove', delayIdle);
  document.addEventListener('scroll', delayIdle);
  document.addEventListener('keypress', delayIdle);
  document.addEventListener('keyup', delayIdle);
  document.addEventListener('focus', delayIdle);
  document.addEventListener('touchstart', delayIdle);
  document.addEventListener('touchmove', delayIdle);
  document.addEventListener('click', delayIdle);

  delayIdle();

  function delayIdle() {
    window.clearTimeout(timer);
    timer = window.setTimeout(callback, timeout);
  }

  return delayIdle;
}
