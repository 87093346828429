/**
 * Logging module. Usage:
 *
 *   import logging from 'logging';
 *
 *   log = logging('my-module');
 *   log('Log this!');
 *   log.error('Log this too!');
 *   log.getLog();
 *   log.events.on('entry', (entry) => {...});
 *
 * The default is to log to a list in the background. For logging to the
 * browser console, call:
 *
 *   log.enableBrowserConsole();
 */

import browserSupportsLogStyles from 'browser-supports-log-styles';
import EventEmitter from 'eventemitter3';

type LogLevel = keyof Logging.API;
type LevelFunc = Logging.LevelFunc;
type CustomLogger = Logger & Logging.CustomAPI<EventEmitter>;

const logLevels: LogLevel[] = ['log', 'debug', 'info', 'warn', 'error'];
const maxEntries = 1000;
const { console } = window;

interface GlobalLoggingContext {
  events: EventEmitter;
  entries: any[];
  browserConsoleEnabled?: boolean;
  hue: number;
  prefixes: {
    [key: string]: [string, string?]
  };
}

declare global {
  interface Window {
    __ludoLogging?: GlobalLoggingContext;
  }
}

const global = (() => {
  if (!window.__ludoLogging) {
    window.__ludoLogging = {
      events: new EventEmitter(),
      entries: [],
      hue: 0,
      prefixes: {}
    };
  }
  return window.__ludoLogging;
})();

const fixedWidthPrefix = (prefix: string) => {
  const width = 15;
  const p = prefix.substr(0, width);
  return `${p}${Array(width + 3 - p.length).join(' ')}|`;
};

const nextHue = () => {
  global.hue = ((global.hue || 0) + Math.E / 7) % 1;
  return global.hue * 360;
};

const consolePrefixes = (name: string) => {
  if (!global.prefixes[name]) {
    const prefix = fixedWidthPrefix(name);

    if (browserSupportsLogStyles()) {
      const hue = nextHue();
      global.prefixes[name] = [`%c${prefix}`, `color:hsl(${hue},99%,40%);font-weight:bold`];
    } else {
      global.prefixes[name] = [prefix];
    }
  }
  return global.prefixes[name];
};

interface LogEntry {
  level: 'log' | 'error' | 'warn';
  name: string;
  messages: any[];
}

const consoleLog = (entry: LogEntry) => {
  console[entry.level](...consolePrefixes(entry.name).concat(entry.messages));
};

const enableBrowserConsole = () => {
  if (global.browserConsoleEnabled || typeof console === 'undefined') {
    return;
  }
  global.browserConsoleEnabled = true;
  global.entries.forEach(consoleLog);
  global.events.on('entry', consoleLog);
};

export default (name: string): CustomLogger => {
  const levelFunc = (level: LogLevel): LevelFunc => (...messages) => {
    const entry = {
      timestamp: new Date(),
      stack: new Error().stack,
      name,
      level,
      messages
    };
    global.entries.push(entry);

    while (global.entries.length > maxEntries) {
      global.entries.shift();
    }

    global.events.emit('entry', entry);
  };

  const api = levelFunc('log') as CustomLogger;

  logLevels.forEach((level) => {
    api[level] = levelFunc(level);
  });

  api.events = global.events;
  api.getLog = () => global.entries;
  api.enableBrowserConsole = enableBrowserConsole;

  return api;
};
